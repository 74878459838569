import { TABLE_COL_WIDTH } from '../constants';

export const SUBSCRIPTIONS_DICTIONARY = {
  PAGE_LABELS: {
    NO_SUBSCRIPTIONS: 'No webhooks added',
    SETUP_SUBSCRIPTIONS:
      'Webhooks allow you to get real-time data into your apps. Start by adding a webhook.',
    DOCS_CTA: 'Developer Portal',
  },
  LIST: {
    FILTER_LABELS: {
      APP: 'App',
      EVENT: 'Event type',
      STATE: 'State',
    },
    PLACEHOLDERS: {
      SEARCH: 'Search by name or endpoint',
    },
    COLUMNS: [
      { title: 'Name', width: TABLE_COL_WIDTH.MD, sortable: true },
      { title: 'Endpoint', width: TABLE_COL_WIDTH.LG, sortable: true },
      { title: 'Events', width: TABLE_COL_WIDTH.LG },
      { title: 'State', width: TABLE_COL_WIDTH.SM },
      { title: ' ', width: 75 },
    ],
    COLUMN_FIELD_MAP: {
      Name: 'name',
      Endpoint: 'targetUrl',
      Events: 'events',
    },
    CTA: {
      TEST: 'Test',
      EDIT: 'Edit',
      DELETE: 'Delete',
    },
  },
  METRICS: {
    SUCCESSFUL: 'Successful Events',
    FAILED: 'Failed Events'
  },
  FORM_LABELS: {
    CANCEL_MODAL: {
      HEADER: 'Are you sure you want to cancel?',
      SUBTITLE: 'All your edits will be discarded and will not take place.',
      CANCEL_BTN: 'Yes, cancel',
      KEEP_EDITING: 'No, keep editing',
      AFTER_CANCEL: 'No edits saved',
    },
    DELETE_MODAL: {
      HEADER: 'Are you sure you want to delete this webhook subscription?',
      SUBTITLE:
        'Once deleted, you will no longer receive events to this endpoint.',
      CANCEL_BTN: 'Yes, delete',
      CANCEL: 'Cancel',
    },
    AFTER_SAVE: {
      CREATED: (subscriptionName: string) =>
        `Webhook ${subscriptionName} added successfully`,
      UPDATED: (subscriptionName: string) =>
        `Webhook ${subscriptionName} updated successfully`,
    },
    AFTER_DELETE: {
      DELETED: (subscriptionName: string) =>
        `Webhook ${subscriptionName} deleted successfully`,
    },
    DETAILS: {
      TITLE: 'Details',
      NAME: 'Webhook name',
      ENDPOINT: 'Webhook endpoint',
      ENDPOINT_ALIAS: 'Endpoint',
      DESCRIPTION: 'Webhook description',
      PLACEHOLDERS: {
        NAME: 'Name your subscription',
        ENDPOINT: 'https://www.exampledomain.com/example',
      },
    },
    AUTH: {
      TITLE: 'Authorization',
      SELECT: 'Authorization credentials',
      PLACEHOLDER: {
        OPTION: 'Choose your authorization credential type',
        API_KEY_HEADER: 'Enter the value for the request header key',
        API_KEY: 'Enter the api key value',
      },
      TYPE: {
        API_KEY: 'API key',
      },
      API_KEY_HEADER: 'API key header',
      API_KEY: 'API key credentials',
    },
    EVENTS: {
      TITLE: 'Events',
      SUBTITLE: 'List of events to subscribe to by app',
      SEARCH_PLACEHOLDER: 'Search events',
      SELECT_ALL: 'Select all events in ',
    },
  },
  FORM_SETTINGS: {
    NAME_MAX_LENGTH: 30,
  },
  PREVIEW_SCHEMA: {
    SUBTITLE: 'Description of event',
    TOOLTIP: 'Preview event schema',
  },
  TEST: {
    TITLE: 'Test',
    DESCRIPTION:
      'Testing events ensures the proper function and integration of your subscriptions',
    DETAILS: 'Details',
    EVENTS: 'Event to test',
    RUN_BTN: 'Run Test',
    DETAIL_HEADER_BTN: 'Test webhook',
    RESPONSE: 'Event response',
    EVENTS_PLACEHOLDER: 'Please select an event to test',
  },
  BTN_LABELS: {
    ADD: 'Add Webhook',
  },
  STATUS_LABELS: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
  TOOLTIPS: {
    HEADER: {
      FORM_DISABLED:
        'Please fill all required fields and select at least one event before saving.',
      TEST_SUBSCRIPTION: 'Test your webhook URLs with example data.',
    },
    EVENTS: {
      SELECT_ALL:
        'Your endpoint will listen for all events that currently exist within this app',
    },
  },
  ERROR_MESSAGES: {
    NO_EVENTS_FOUND: 'No events were found', // Technically shouldn't happen
    INVALID_SUBSCRIPTION: 'Please provide a valid subscription',
  },
  ERROR_TYPES: {
    DUPLICATE_SUBSCRIPTION_NAME: 'DUPLICATE_SUBSCRIPTION_NAME',
  },
  DEFAULTS: {
    DELIVERY_TYPE: 'WEBHOOK',
    API_KEY_HEADER: 'x-api-key',
  },
};

export const ADD_SUBSCRIPTION_BTN_LABEL = 'No webhooks added';

export const PRODUCT_MAPPING = {
  pim: {
    name: 'Products',
    icon: '',
    order: 1,
  },
  catalog: {
    name: 'Catalog',
    icon: '',
    order: 6,
  },
  cnc: {
    name: 'Cart & Checkout',
    icon: '',
    order: 5,
  },
  cart_checkout: {
    name: 'Cart & Checkout',
    icon: '',
    order: 5,
  },
  oms: {
    name: 'Orders',
    icon: '',
    order: 3, 
  },
  offers: {
    name: 'Offers',
    icon: '',
    order: 2,
  },
  loyalty: {
    name: 'Loyalty',
    icon: '',
    order: 6,
  },
  inventory: {
    name: 'Inventory',
    icon: '',
    order: 4,
  },
};
