import { Dropdown, DropdownOptionProps } from 'ds4-beta';
import { useEffect, useState } from 'react';
import { useGetSubscriptions } from '../../subscriptions/queries';
import { isNil } from 'lodash';
import { transformSubscriptionsToDropdownOptions } from '../../common/utils/dropdown-options';
import { SUBSCRIPTION_NAMES_LIMIT } from '../../constants';

const SubscriptionDropdown = ({
  label,
  value,
  onChange,
  dataTestid,
  required = true,
}: {
  label: string;
  value?: DropdownOptionProps;
  onChange: (option: DropdownOptionProps) => void;
  dataTestid: string;
  required?: boolean;
}) => {
  const [subscriptionDropdownOptions, setSubscriptionDropdownOptions] =
    useState<DropdownOptionProps[]>([]);

  const { data: subscriptions, isFetching: isFetchingSubscriptions } =
    useGetSubscriptions({
      limit: SUBSCRIPTION_NAMES_LIMIT,
    });

  useEffect(() => {
    if (!isFetchingSubscriptions && !isNil(subscriptions)) {
      setSubscriptionDropdownOptions(pastSubscriptionDropdownOptions =>
        transformSubscriptionsToDropdownOptions(
          subscriptions,
          pastSubscriptionDropdownOptions
        )
      );
    }
  }, [isFetchingSubscriptions]);

  return (
    <Dropdown
      width='100%'
      label={label}
      {...(value && {
        value: value,
      })}
      onChange={onChange}
      options={subscriptionDropdownOptions}
      required={required}
      dataTestid={dataTestid}
    />
  );
};

export default SubscriptionDropdown;
