import { Box, SpacingUnit } from 'ds4-beta';

type VerticalSpaceProps = {
  size?: number;
};

const VerticalSpace = ({ size }: VerticalSpaceProps) => {
  return <Box margin={{ top: (size as SpacingUnit) ?? 4 }} />;
};

export default VerticalSpace;
