/** Dictionary of terms and labels used across the app */
import { capitalize } from 'lodash';
import { SUBSCRIPTIONS_DICTIONARY } from './subscriptions/dictionary';
import { DELIVERY_LOGS_DICTIONARY } from './delivery-logs/dictionary';

export const DICTIONARY = {
  WEBHOOKS_HEADER: {
    TITLE_HEADER: 'Webhooks',
    MORE_INFO_HEADER: 'For more information, visit our',
    DEVELOPER_PAGE_CTA: 'Developer Portal',
    ADD_WEBHOOK_CTA: 'Add Webhook',
  },
  TAB_OPTIONS: {
    SUBSCRIPTIONS: 'Webhooks',
    LOGS: 'Logs',
  },
  LOGS_HEADER: {
    TITLE_HEADER: 'Logs',
    getMoreInfoHeader: (days: number) =>
      `Logs will only be available for ${days.toString()} days`,
    REPLAY_EVENTS_CTA: 'Replay events',
  },
  GENERAL: {
    BUTTONS: {
      SAVE: 'Save',
      CLOSE: 'Close',
      CANCEL: 'Cancel',
      MORE_ACTIONS: 'More actions',
      DELETE: 'Delete webhook',
      COPY: 'Copy',
      RESET_ALL: 'Reset all',
      RESET_FILTERS: 'Reset filters',
    },
    SEARCH_RESULTS: {
      SUGGESTIONS: 'Suggestions',
      NOT_FOUND: 'Not found',
      NO_RESULTS_HEADER: 'No results found',
      NO_RESULTS_DESCRIPTION: 'Try changing your search criteria',
    },
    NOT_ACCESSIBLE: {
      HEADER_TEXT: 'You do not have access to webhooks',
      DESCRIPTION:
        'Reach out to your system administrator to request access to Developer Tools: Webhooks',
    },
  },
  ERROR_MESSAGES: {
    REQUIRED: field => `${capitalize(field)} is required`,
    MIN_LENGTH: len => `Field should have at least ${len} characters`,
    MAX_LENGTH: len => `Field should be less then ${len} characters`,
    PATTERN: (field, pattern) =>
      `${capitalize(field)} must be a valid ${pattern}`,
  },
  PATTERNS: {
    URL: 'URL',
  },
  TABLE_ACTIONS: {
    CLEAR_SELECTION: 'Clear selection',
  },
  DATE_FILTER_LABELS: {
    TODAY: 'Today',
    getLastXDaysString: (x: number) => `Last ${x.toString()} days`,
    CUSTOM_DATE: 'Custom date',
  },
  FILTER_NAMES: {
    DATE: 'Event date',
    EVENT: 'Event type',
    STATUS_CODE: 'Status code',
    SUBSCRIPTION_NAME: 'Subscription name',
  },
  // Product name from API must match our map (to add further mapping)
  PRODUCT_ICON_MAP: {
    pim: 'Product',
    catalog: 'ElectricCord',
    offers: 'Pricing',
    oms: 'Orders',
    // Keeping both cart possible names, until the event domain part is standardized
    cnc: 'ShoppingCart',
    cart_checkout: 'ShoppingCart',
    loyalty: 'Loyalty',
    inventory: 'Inventory',
  },
  ...{ SUBSCRIPTIONS: SUBSCRIPTIONS_DICTIONARY },
  ...{ DELIVERY_LOGS: DELIVERY_LOGS_DICTIONARY },
};

export const TAB_INDEXES = {
  SUBSCRIPTIONS: 0,
  LOGS: 1,
};
