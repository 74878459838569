import { DICTIONARY } from '../../../dictionary';
import {
  FormSectionSubtitle,
  FormSectionTitle,
  SectionLoading,
  VerticalSpace,
} from '../../../common/atoms';
import { EventsSearchbar, EventsTabs, PreviewSchema } from '../../molecules';
import { useGetEventTypes } from '../../../common/queries';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { EventMetadataResponse } from '../../../common/types';

const SubscriptionFormEvents = () => {
  const { data: events, isFetching } = useGetEventTypes();
  const { control, trigger } = useFormContext();

  const [previewEvent, setPreviewEvent] = useState<EventMetadataResponse>(null);
  const [searchTerm, setSearchTerm] = useState<string>(null);

  const handleEventSearch = searchTerm => {
    setSearchTerm(searchTerm);
  };

  return (
    <>
      <FormSectionTitle
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.EVENTS.TITLE}
      />
      <VerticalSpace size={1} />
      <FormSectionSubtitle
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.EVENTS.SUBTITLE}
      />
      <VerticalSpace />
      <EventsSearchbar onSearch={handleEventSearch} />
      <VerticalSpace />
      {isFetching && <SectionLoading />}
      {!isFetching && !events && (
        <>{DICTIONARY.SUBSCRIPTIONS.ERROR_MESSAGES.NO_EVENTS_FOUND}</>
      )}
      {!isFetching && events && (
        <Controller
          control={control}
          name='events'
          rules={{ required: true, minLength: 1 }}
          render={({ field: { value, onChange: onFieldChange } }) => {
            return (
              <>
                <EventsTabs
                  selectedEvents={value}
                  eventsMetadata={events}
                  searchTerm={searchTerm}
                  onEventSelect={selectedEvents => {
                    trigger();
                    return onFieldChange(selectedEvents);
                  }}
                  onEventPreview={previewEvent => setPreviewEvent(previewEvent)}
                />
                <PreviewSchema
                  previewEvent={previewEvent}
                  onClose={() => setPreviewEvent(null)}
                />
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default SubscriptionFormEvents;
