import styled, { css } from 'styled-components';
import { theme } from 'ds4-beta';

const flexAlignCenter = css`
  display: flex;
  align-items: center;
`;

export const TableActionContainer = styled.div`
  ${flexAlignCenter}
  justify-content: space-between;
`;

export const FlexAlignContainer = styled.div`
  ${flexAlignCenter}
`;
export const SelectionCenterContainer = styled.div`
  ${flexAlignCenter}
  padding-right: ${theme.space[1]};
`;

export const CaptionContainer = styled.div`
  ${theme.typography.body2.regular}
  color: ${theme.color.grey[600]};
`;
