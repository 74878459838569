import { createRef, useEffect, useRef, useState } from 'react';
import { map } from 'lodash';
import {
  Box,
  Card,
  Checkbox,
  Icon,
  InteractiveIcon,
  Tab,
  TabItem,
  Tooltip,
  theme,
} from 'ds4-beta';
import { rbac } from '@teamfabric/copilot-utilities';
import { EventTreeNode } from './tabs.type';
import {
  parseEventsTree,
  selectEventsFromSelectedTreeNode,
} from './event-tree.utils';
import { Row, VerticalSpace } from '../../../common/atoms';
import { REGISTRY } from '../../../registry';
import { DICTIONARY } from '../../../dictionary';
import { StyledEventCheckBoxes, StyledHighlightedCheckbox } from './tabs.style';
import { CONFIG } from '../../config';
import { EVENT_ADMIN_ROLE_PERMISSIONS } from '../../../constants';

const EventsTabs = ({
  selectedEvents = [],
  eventsMetadata,
  onEventSelect,
  onEventPreview,
  searchTerm = null,
}) => {
  const { hasAccess: hasAdminAccess } = rbac.checkPermission({
    allowedPermissions: EVENT_ADMIN_ROLE_PERMISSIONS,
  });

  const [eventsTree, setEventsTree] = useState<EventTreeNode[]>([]);

  const defaultPreviewIconColor = theme.color.grey[300];
  const [previewIconColors, setPreviewIconColors] = useState(() => {
    const events = {};
    map(eventsMetadata, 'type').forEach(eventType => {
      events[eventType] = defaultPreviewIconColor;
    });

    return events;
  });

  const scrollRefs = useRef({});
  const scrollToHighlightedEvent = id => {
    // Needs to be under a setTimeout so that DOM has time to load and mount the elemnt with the ref
    setTimeout(() => {
      scrollRefs.current[id].current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  useEffect(() => {
    setEventsTree(parseEventsTree(eventsMetadata, selectedEvents, searchTerm));
  }, [selectedEvents, searchTerm]);

  const handleSelect = (node: EventTreeNode) => {
    onEventSelect(selectEventsFromSelectedTreeNode(node, selectedEvents));
  };

  const renderProductTab = (productNode: EventTreeNode, index: number) => {
    const { id, label, selected, isPartial, isHighlighted, children } =
      productNode;
    return (
      <TabItem
        icon={<Icon iconName={DICTIONARY.PRODUCT_ICON_MAP[id]} />}
        key={`tab-item-${id}`}
        label={label}
        dataTestid={REGISTRY.SUBSCRIPTIONS.FORM.EVENTS.TABS}
        active={isHighlighted}
      >
        <VerticalSpace size={5} />
        <Box
          flex={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
          }}
          gap={1}
        >
          <Checkbox
            name={id}
            label={`${DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.EVENTS.SELECT_ALL} ${label}`}
            isPartial={isPartial}
            checked={selected}
            value={id}
            onChange={() => handleSelect(productNode)}
            dataTestid={REGISTRY.SUBSCRIPTIONS.FORM.EVENTS.SELECT_ALL}
          />
          <Tooltip
            text={DICTIONARY.SUBSCRIPTIONS.TOOLTIPS.EVENTS.SELECT_ALL}
            placement='rightStart'
          >
            <Icon iconName='Info' />
          </Tooltip>
        </Box>
        <VerticalSpace />
        {children.map((serviceNode: EventTreeNode) => (
          <Box key={`${id}-${serviceNode.id}`}>
            {renderServiceCard(serviceNode, index)}
            <VerticalSpace size={2} />
          </Box>
        ))}
      </TabItem>
    );
  };

  const renderServiceCard = (serviceNode: EventTreeNode, index: number) => {
    const {
      id,
      label,
      selected,
      isPartial,
      isHighlighted,
      childrenCount,
      children,
    } = serviceNode;
    return (
      <Card
        open={isHighlighted || CONFIG.EVENTS.DEFAULT_COLLAPSED_TABS}
        header={
          <Checkbox
            label={`${label} (${childrenCount})`}
            value={id}
            name={id}
            isPartial={isPartial}
            checked={selected}
            onChange={() => handleSelect(serviceNode)}
            dataTestid={REGISTRY.SUBSCRIPTIONS.FORM.EVENTS.GROUPS}
          />
        }
        body={children.map((eventNode: EventTreeNode) => (
          <StyledEventCheckBoxes key={`${id}-${eventNode.id}`}>
            {renderEventTypeCheckbox(eventNode, index)}
          </StyledEventCheckBoxes>
        ))}
      />
    );
  };

  const renderEventTypeCheckbox = (eventNode: EventTreeNode, index: number) => {
    const { id, label, description, selected, isHighlighted, metadata } =
      eventNode;

    scrollRefs.current[id] = scrollRefs.current[id] ?? createRef();

    if (isHighlighted) {
      scrollToHighlightedEvent(id);
    }

    return (
      <Row flexProps={{ justifyContent: 'space-between' }}>
        <Row>
          <StyledHighlightedCheckbox
            isHighlighted={isHighlighted}
            ref={scrollRefs.current[id]}
          >
            <Checkbox
              key={id}
              label={label}
              value={id}
              name={id}
              checked={selected}
              onChange={() => handleSelect(eventNode)}
              dataTestid={REGISTRY.SUBSCRIPTIONS.FORM.EVENTS.EVENT_TYPES}
            />
          </StyledHighlightedCheckbox>
          <span>&nbsp;-&nbsp;{description}</span>
        </Row>
        <Box flex={{ justifyContent: 'end' }}>
          {hasAdminAccess && (
            <Tooltip
              text={DICTIONARY.SUBSCRIPTIONS.PREVIEW_SCHEMA.TOOLTIP}
              showArrow
              placement='left'
            >
              <InteractiveIcon
                iconName='View'
                color={previewIconColors[label]}
                onClick={() => onEventPreview(metadata)}
                onMouseEnter={() =>
                  setPreviewIconColors(colors => ({
                    ...colors,
                    [label]: theme.color.grey[1000],
                  }))
                }
                onMouseLeave={() =>
                  setPreviewIconColors(colors => ({
                    ...colors,
                    [label]: defaultPreviewIconColor,
                  }))
                }
              />
            </Tooltip>
          )}
        </Box>
      </Row>
    );
  };

  const getCustomActiveTab = (): number => {
    let customActiveIndex = 0;
    for (let i = 0; i < eventsTree.length; i++) {
      if (eventsTree[i].isHighlighted) {
        customActiveIndex = i;
      }
    }

    return customActiveIndex;
  };

  return (
    <>
      {eventsTree && eventsTree.length > 0 && (
        <Tab customActiveIndex={getCustomActiveTab()}>
          {eventsTree.map((productNode: EventTreeNode, index: number) =>
            renderProductTab(productNode, index)
          )}
        </Tab>
      )}
    </>
  );
};

export default EventsTabs;
