import { theme } from 'ds4-beta';
import styled from 'styled-components';

// Adding 4px vertical as popover container has 8px vertical space already
export const StyledFilterSidebar = styled.div`
    padding: ${theme.space[1]} ${theme.space[3]};
    div {
        border: none;
        padding: 0;
    }
`;