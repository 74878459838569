import { DICTIONARY } from '../../../dictionary';
import {
  FormInput,
  FormSectionTitle,
  VerticalSpace,
} from '../../../common/atoms';
import { REGISTRY } from '../../../registry';

const SubscriptionFormAuth = ({ isUpdating }: { isUpdating?: boolean }) => {
  return (
    <>
      <FormSectionTitle
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AUTH.TITLE}
      />
      <VerticalSpace />
      <FormInput
        name='authorizationDetails.config.apiKeyHeaderName'
        alias='header'
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AUTH.API_KEY_HEADER}
        required={!isUpdating}
        inputProps={{
          dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.AUTH.API_KEY_HEADER,
          placeholder:
            DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AUTH.PLACEHOLDER
              .API_KEY_HEADER,
        }}
      />
      <VerticalSpace />
      {/* Need to think about the validation for apiKey */}
      <FormInput
        name='authorizationDetails.config.apiKeyValue'
        alias='apiKey'
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AUTH.API_KEY}
        required={!isUpdating}
        inputProps={{
          dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.AUTH.API_KEY,
          placeholder:
            DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AUTH.PLACEHOLDER.API_KEY,
        }}
      />
    </>
  );
};

export default SubscriptionFormAuth;
