import { getSortOrderString } from '../../lib/utils';
import { isEmpty, isNil } from 'lodash';
import { DateFilterValue } from 'ds4-beta';
import { ExtendedDateType, LogsFilterEnum, LogsFilterOptions } from '../types';
import { EventMetadataResponse } from '../../common/types';

export const getSortFilterString = sortOptions => {
  let aggregatedFilterString = '';

  aggregatedFilterString += getSortOrderString(
    'triggeredAt',
    sortOptions.EVENT_DATE
  );
  aggregatedFilterString += getSortOrderString(
    'subscriptionName',
    sortOptions.SUBSCRIPTION_NAME
  );
  aggregatedFilterString += getSortOrderString(
    'endpoint',
    sortOptions.ENDPOINT
  );
  aggregatedFilterString += getSortOrderString('type', sortOptions.LOG_TYPE);
  aggregatedFilterString += getSortOrderString(
    'eventType',
    sortOptions.EVENT_TYPE
  );
  aggregatedFilterString += getSortOrderString(
    'status',
    sortOptions.STATUS_CODE
  );
  return aggregatedFilterString;
};

const getFilterObjectFromStringArray = (
  filterKey: LogsFilterEnum,
  filterSelections: LogsFilterOptions
) => {
  const result = {};
  if (isEmpty(filterSelections[filterKey])) {
    return result;
  }
  const joinedFilterOptions = (filterSelections[filterKey] as string[]).join(
    ','
  );
  let queryParamKey = '';
  switch (filterKey) {
    case LogsFilterEnum.STATUS_CODE:
      queryParamKey = 'status';
      break;
    case LogsFilterEnum.SUBSCRIPTION_NAME:
      queryParamKey = 'subscriptionName';
      break;
    default:
      break;
  }
  result[queryParamKey] = joinedFilterOptions;
  return result;
};

const getFilterObjectFromDate = (
  filterKey: LogsFilterEnum,
  filterSelections: LogsFilterOptions
): {
  endTriggeredAt?: string;
  startTriggeredAt?: string;
} => {
  const result = {};
  const selectedOptions: DateFilterValue = filterSelections[
    filterKey
  ] as DateFilterValue;
  if (
    isNil(selectedOptions?.dateRange) ||
    isNil(selectedOptions.dateRange?.from) ||
    isNil(selectedOptions.dateRange?.to)
  ) {
    return result;
  }
  result['startTriggeredAt'] = selectedOptions.dateRange.from.toISOString();
  result['endTriggeredAt'] = selectedOptions.dateRange.to.toISOString();

  return result;
};

const getFilterObjectFromEventArray = (
  filterKey: LogsFilterEnum,
  filterSelections: LogsFilterOptions
) => {
  if (isEmpty(filterSelections[filterKey])) {
    return {};
  }
  return {
    eventType: (filterSelections[filterKey] as EventMetadataResponse[])
      .map(option => option.type)
      .join(','),
  };
};

export const getCustomFilterParamObject = (filterSelections, logRetentionInDays) => {
  let result = {};

  // If date filter was not provided, include date threshold based on retation days for consistency with metrics
  if (filterSelections?.DATE?.value === "") {
    filterSelections.DATE = getDateFilterFromRetentionDays(logRetentionInDays);
  }

  const filterKeys = Object.keys(filterSelections) as LogsFilterEnum[];
  let filterObjectFromStringArray,
    filterObjectFromDate,
    filterObjectFromEventArray;

  for (let filterKey of filterKeys) {
    switch (filterKey) {
      case LogsFilterEnum.STATUS_CODE:
      case LogsFilterEnum.SUBSCRIPTION_NAME:
        filterObjectFromStringArray = getFilterObjectFromStringArray(
          filterKey,
          filterSelections
        );
        result = Object.assign(result, filterObjectFromStringArray);
        break;
      case LogsFilterEnum.DATE:
        filterObjectFromDate = getFilterObjectFromDate(
          filterKey,
          filterSelections
        );
        result = Object.assign(result, filterObjectFromDate);
        break;
      case LogsFilterEnum.EVENT_TYPE:
        filterObjectFromEventArray = getFilterObjectFromEventArray(
          filterKey,
          filterSelections
        );
        result = Object.assign(result, filterObjectFromEventArray);
        break;
      default:
        break;
    }
  }
  return result;
};

export const getDefaultFilterParamObject = (
  x: number
): {
  endTriggeredAt?: string;
  startTriggeredAt?: string;
} => {
  return getFilterObjectFromDate(LogsFilterEnum.DATE, {
    DATE: getDateFilterFromRetentionDays(x),
  });
};

export const getDateFilterFromRetentionDays = (x: number) => {
  // Calculate the x-th and tomorrow's 0 hour thresholds (full days when querying for retention days)
  const currentDate = new Date();
  const endDate = new Date();
  endDate.setDate(currentDate.getDate() + 1);
  endDate.setHours(0, 0, 0, 0);

  const xDaysAgo = new Date(Date.now() - x * 24 * 60 * 60 * 1000);
  xDaysAgo.setHours(0, 0, 0, 0);
  return {
    value: ExtendedDateType.DefaultDate,
    dateRange: {
      from: xDaysAgo,
      to: endDate,
    },
  };
};

export const mergeDateTime = (
  selectedDate: Date,
  selectedTime: Date
): string => {
  // `selectedDate` has the month/day/year selected by the user, but the improper time
  // `selectedTime` has the hour/min selected by the user, but the improper date
  // This util takes the date from `selectedDate` and combines it with the time from `selectedTime`

  // Set Date, Month, Year
  const result = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    selectedTime.getHours(),
    selectedTime.getMinutes(),
    0,
    0
  );

  // will return ISO string format, e.g. "2023-07-11T06:33:57Z"
  return result.toISOString();
};
