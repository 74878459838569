import { Modal } from 'ds4-beta';
import { DELIVERY_LOGS_DICTIONARY } from '../../dictionary';

const { RESEND_EVENTS_MODAL } = DELIVERY_LOGS_DICTIONARY;

const ResendEventsModal = ({
  numOfEvents,
  onModalClose,
  onConfirm,
}: {
  numOfEvents: number;
  onModalClose: () => void;
  onConfirm: () => void;
}) => (
  <Modal
    isVisible={numOfEvents > 0}
    size='small'
    headerText={
      numOfEvents === 1
        ? RESEND_EVENTS_MODAL.SINGLE_EVENT_TITLE
        : RESEND_EVENTS_MODAL.getMultipleEventsTitle(numOfEvents)
    }
    description={RESEND_EVENTS_MODAL.DESCRIPTION}
    footerButtons={[
      {
        text: RESEND_EVENTS_MODAL.CANCEL_BTN,
        variant: 'secondary',
        onClick: onModalClose,
      },
      {
        text: RESEND_EVENTS_MODAL.CONFIRM_BTN,
        variant: 'primary',
        onClick: onConfirm,
      },
    ]}
    onBackdropClick={onModalClose}
    onClose={onModalClose}
  />
);

export default ResendEventsModal;
