import { PageType } from '../../common/types';
import {
  EventsMetrics,
  SubscriptionsTable,
  SubscriptionsListFilters,
} from '../organisms';
import { HomePageLayout } from '../../common/layouts';
import { useGetSubscriptions } from '../queries';
import { VerticalSpace } from '../../common/atoms';
import { useState } from 'react';
import { PAGE_URLS } from '../../routes';
import { DICTIONARY } from '../../dictionary';
import { SORT_TYPE } from '../../constants';
import { SortOptions } from '../types';
import { useConfirmDeleteSubscription } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../registry';
import { useFlag } from '@unleash/proxy-client-react';
import { isEmpty, isNil } from 'lodash';
import FiltersLoadingState from '../molecules/filters/loading-state';
import useManageFilterSelections from '../hooks/useManageFilterSelections';
import useGetFilterData from '../../delivery-logs/hooks/useGetFilterData';
import { useGetEventTypes } from '../../common/queries';

const SubscriptionsHomePage = (_: PageType) => {
  const [activePage, setActivePage] = useState(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: null,
    direction: null,
  });
  const [searchTerm, setSearchTerm] = useState('');

  const { data: eventsData, isFetching: isFetchingEventsData } =
    useGetEventTypes();

  const [eventNestedFilterOptions] = useGetFilterData({
    eventsData,
    isFetchingEventsData,
    subscriptionsData: [],
    isFetchingSubscriptionsData: false,
  });

  const {
    filterSelections,
    filterCheckboxOptions,
    tagSelections,
    handleClearFilters,
    handleFilterOptionSelect,
  } = useManageFilterSelections(eventNestedFilterOptions, eventsData);

  const navigate = useNavigate();
  const showLogsMetrics = useFlag(FEATURE_FLAGS.SHOW_LOGS_METRICS);

  const {
    data: subscriptions,
    count,
    refetch,
    isLoading,
    isFetching,
  } = useGetSubscriptions({
    page: activePage,
    filters: filterSelections,
    sortOptions,
    searchTerm,
  });

  const { showConfirmDeleteModal, renderConfirmDeleteModal } =
    useConfirmDeleteSubscription({ onConfirm: refetch });

  const [caption, setCaption] = useState('');

  const handleDeleteSubscription = subscription => {
    showConfirmDeleteModal(subscription);
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  const handleSort = (column: string, sortDirection: SORT_TYPE) => {
    setSortOptions(() => ({
      key: DICTIONARY.SUBSCRIPTIONS.LIST.COLUMN_FIELD_MAP[column],
      direction: sortDirection,
    }));
  };

  const handleTestSubscription = id => {
    navigate(PAGE_URLS.SUBSCRIPTIONS.TEST(id));
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <HomePageLayout
      shouldShowPrimaryCTA={!isLoading && !isEmpty(subscriptions)}
    >
      {isLoading && <FiltersLoadingState />}
      {!isLoading && !isNil(subscriptions) && (
        <>
          {showLogsMetrics && <EventsMetrics />}
          <VerticalSpace size={1} />
          <SubscriptionsListFilters
            handleClearFilters={handleClearFilters}
            filterSelections={filterSelections}
            filterCheckboxOptions={filterCheckboxOptions}
            handleFilterOptionSelect={handleFilterOptionSelect}
            onSearch={handleSearch}
            tagSelections={tagSelections}
            tableCaption={caption}
          />
          <VerticalSpace />
        </>
      )}
      <SubscriptionsTable
        filters={filterSelections}
        count={count}
        subscriptions={subscriptions}
        activePage={activePage}
        isFetchingData={isFetching}
        setCaption={setCaption}
        onDelete={handleDeleteSubscription}
        onTest={handleTestSubscription}
        onPageChange={handlePageChange}
        onAscendingSort={columnName =>
          handleSort(columnName, SORT_TYPE.ASCENDING)
        }
        onDescendingSort={columnName =>
          handleSort(columnName, SORT_TYPE.DESCENDING)
        }
      />
      {renderConfirmDeleteModal()}
    </HomePageLayout>
  );
};

export default SubscriptionsHomePage;
