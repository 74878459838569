import {
  EMPTY_TABLE_FILTER_STATES,
  LOG_FILTER_TYPES,
  LOGS_SIMPLE_CHECKBOX_FILTERS,
  EMPTY_TABLE_DATE_FILTER_STATE,
} from '../constants';
import { useEffect, useState } from 'react';
import {
  CheckboxFilterOnChangeFn,
  EventMetadataResponse,
} from '../../common/types';
import { DICTIONARY } from '../../dictionary';
import { DateFilterValue, TagProps } from 'ds4-beta';
import {
  ExtendedDateType,
  LogsFilterCheckboxOptions,
  LogsFilterEnum,
  LogsFilterOptions,
} from '../types';
import { transformStartEndTime } from '../../common/molecules/date-filter/utils';
import { isEqual, isEmpty, isNil, isNull } from 'lodash';
import { parseEventsTree } from '../../subscriptions/molecules/events/event-tree.utils';
import {
  getDatePrimaryLabel,
  getTagSelectionsForEventTypes,
  removeTagFromEventTypeFilterSelections,
  makeReadableFilterName,
} from './utils';

const { FILTER_NAMES } = DICTIONARY;

const useManageFilterSelections = (
  eventNestedFilterOptions,
  statusFilterOptions,
  subscriptionFilterOptions,
  eventTypes
) => {
  // ====================== IMPORTANT ======================
  // `filterSelections` is the PARENT STATE VARIABLE
  // filterCheckboxOptions, tagSelections, and dateValue all use `filterSelections` as the source of truth
  const [filterSelections, setFilterSelections] = useState<LogsFilterOptions>(
    EMPTY_TABLE_FILTER_STATES
  );
  // To manage filters checkbox options
  const [filterCheckboxOptions, setFilterCheckboxOptions] =
    useState<LogsFilterCheckboxOptions>({
      [LogsFilterEnum.EVENT_TYPE]: [],
      [LogsFilterEnum.STATUS_CODE]: [],
      [LogsFilterEnum.SUBSCRIPTION_NAME]: [],
    });

  // To manage date filters
  const [dateValue, setDateValue] = useState<DateFilterValue>(null);

  // To manage tag selections
  const [tagSelections, setTagSelections] = useState<TagProps[]>([]);

  // To manage a tag that is marked for removal
  const [tagToRemove, setTagToRemove] = useState<string | null>(null);

  const handleDateChange = (dateOption: DateFilterValue) => {
    setFilterSelections(prevFilterSelections => ({
      ...prevFilterSelections,
      DATE: {
        value: dateOption.value,
        dateRange: transformStartEndTime(dateOption),
      },
    }));
  };

  // START: Set up the default
  useEffect(() => {
    if (isNil(eventNestedFilterOptions) || isEmpty(eventNestedFilterOptions))
      return;
    setFilterCheckboxOptions(prevState => ({
      ...prevState,
      EVENT_TYPE: eventNestedFilterOptions,
    }));
  }, [eventNestedFilterOptions]);

  useEffect(() => {
    if (isNil(statusFilterOptions) || isEmpty(statusFilterOptions)) return;
    setFilterCheckboxOptions(prevState => ({
      ...prevState,
      STATUS_CODE: statusFilterOptions,
    }));
  }, [statusFilterOptions]);

  useEffect(() => {
    if (isNil(subscriptionFilterOptions) || isEmpty(subscriptionFilterOptions))
      return;
    setFilterCheckboxOptions(prevState => ({
      ...prevState,
      SUBSCRIPTION_NAME: subscriptionFilterOptions,
    }));
  }, [subscriptionFilterOptions]);
  // END: Set up the default

  useEffect(() => {
    const newFilterCheckboxOptions = { ...filterCheckboxOptions };

    newFilterCheckboxOptions.EVENT_TYPE = parseEventsTree(
      eventTypes,
      filterSelections.EVENT_TYPE
    );

    // Update filter simple checkboxes based on filter selections
    LOGS_SIMPLE_CHECKBOX_FILTERS.forEach(filterName => {
      newFilterCheckboxOptions[filterName].forEach(checkboxOption => {
        checkboxOption.selected = filterSelections[filterName].includes(
          checkboxOption.label
        );
      });
    });

    setFilterCheckboxOptions(newFilterCheckboxOptions);

    // Update date value
    setDateValue(filterSelections.DATE);

    // Update tag selections
    if (isEqual(filterSelections, EMPTY_TABLE_FILTER_STATES)) {
      setTagSelections([]);
      return;
    }

    let newTagSelections = [];
    LOG_FILTER_TYPES.forEach(filterType => {
      if (LOGS_SIMPLE_CHECKBOX_FILTERS.includes(filterType)) {
        filterSelections[filterType].forEach(option => {
          const tagId = `${filterType}--${option}`;
          newTagSelections.push({
            id: tagId,
            isRemovable: true,
            primaryLabel: `${makeReadableFilterName(filterType)}: ${option}`,
            onRemove: () => {
              setTagToRemove(tagId);
            },
          });
        });
      } else if (filterType === LogsFilterEnum.EVENT_TYPE) {
        const tagSelectionsForEventTypes = getTagSelectionsForEventTypes(
          filterSelections.EVENT_TYPE,
          newFilterCheckboxOptions.EVENT_TYPE,
          setTagToRemove
        );
        newTagSelections = newTagSelections.concat(tagSelectionsForEventTypes);
      } else if (filterType === LogsFilterEnum.DATE) {
        const selectedDateOption = filterSelections[filterType];
        if (
          !isNil(selectedDateOption.dateRange) &&
          !isNil(selectedDateOption.dateRange.from) &&
          !isNil(selectedDateOption.dateRange.to) &&
          selectedDateOption?.value !== ExtendedDateType.DefaultDate // Only include tag if a date filter has been selected
        ) {
          const tagId = `${filterType}--${selectedDateOption.dateRange.from.toDateString()}`;
          newTagSelections.push({
            id: tagId,
            isRemovable: true,
            primaryLabel: getDatePrimaryLabel(selectedDateOption),
            onRemove: () => {
              setTagToRemove(tagId);
            },
          });
        }
      }
    });
    setTagSelections(newTagSelections);
  }, [filterSelections]);

  useEffect(() => {
    if (isNull(tagToRemove)) return;
    const [filterName, option] = tagToRemove.split('--');

    ////// Update Filter Options //////
    const newFilterSelections = { ...filterSelections };
    switch (filterName) {
      case LogsFilterEnum.STATUS_CODE:
      case LogsFilterEnum.SUBSCRIPTION_NAME:
        newFilterSelections[filterName] = newFilterSelections[
          filterName
        ].filter(_option => _option !== option);
        break;
      case LogsFilterEnum.DATE:
        newFilterSelections[filterName] = EMPTY_TABLE_DATE_FILTER_STATE;
        break;
      case LogsFilterEnum.EVENT_TYPE:
        newFilterSelections[filterName] =
          removeTagFromEventTypeFilterSelections(
            tagToRemove,
            newFilterSelections[filterName]
          );
        break;
      default:
        break;
    }

    setFilterSelections(newFilterSelections);

    // Reset tag to remove
    setTagToRemove(null);
  }, [tagToRemove]);

  const handleClearFilters = () => {
    setFilterSelections(EMPTY_TABLE_FILTER_STATES);
  };

  const handleFilterOptionSelect = ({
    filterName,
    selectedOptions,
  }: CheckboxFilterOnChangeFn) => {
    const newFilterOptions = { ...filterSelections };
    switch (filterName) {
      case FILTER_NAMES.EVENT:
        newFilterOptions.EVENT_TYPE =
          selectedOptions as EventMetadataResponse[];
        break;
      case FILTER_NAMES.STATUS_CODE:
        newFilterOptions.STATUS_CODE = selectedOptions as string[];
        break;
      case FILTER_NAMES.SUBSCRIPTION_NAME:
        newFilterOptions.SUBSCRIPTION_NAME = selectedOptions as string[];
        break;
      default:
        break;
    }

    setFilterSelections(newFilterOptions);
  };

  return {
    filterSelections,
    filterCheckboxOptions,
    dateValue,
    tagSelections,
    handleClearFilters,
    handleFilterOptionSelect,
    handleDateChange,
  };
};

export default useManageFilterSelections;
