import { useEffect, useState } from 'react';
import { EmptyPageProps } from 'ds4-beta';
import {
  EMPTY_TABLE_SORT_STATES,
  EMPTY_TABLE_FILTER_STATES,
} from '../constants';
import { DICTIONARY } from '../../dictionary';
import { isEqual, isNull } from 'lodash';
import { AXIOS_ERRORS, SELF_SERVICE_BACKEND_ERRORS } from '../../constants';
import { AxiosError } from 'axios';

const {
  DELIVERY_LOGS: { NETWORK_ERROR_TEXT, NO_LOGS_EXIST_TEXT },
  GENERAL: {
    SEARCH_RESULTS: { NO_RESULTS_HEADER, NO_RESULTS_DESCRIPTION },
  },
} = DICTIONARY;

const CREATE_WEBHOOK_CTA_CONFIG = {
  headerText: NO_LOGS_EXIST_TEXT.HEADER,
  description: NO_LOGS_EXIST_TEXT.DESCRIPTION,
};

const useSetEmptyLogsTableProps = ({
  isFetchingEventLogs,
  eventLogsData,
  searchTerm,
  sortOptions,
  filterSelections,
  isFetchingEventLogsError,
  setTotalRecords,
  eventLogsQuery,
  eventLogsFetchError,
}) => {
  const [emptyTableProps, setEmptyTableProps] = useState<EmptyPageProps>(null);

  const handleErrors = error => {
    try {
      if ((error as AxiosError).code === AXIOS_ERRORS.ERR_NETWORK) {
        setEmptyTableProps({
          headerText: NETWORK_ERROR_TEXT.HEADER,
          description: NETWORK_ERROR_TEXT.DESCRIPTION,
        });
      }
    } catch (_) {}

    try {
      const errorType = ((error as AxiosError).response.data as any).type;
      if (errorType === SELF_SERVICE_BACKEND_ERRORS.INVALID_TENANT_PROVIDED) {
        setEmptyTableProps({ ...CREATE_WEBHOOK_CTA_CONFIG });
      }
    } catch (_) {}
  };

  useEffect(() => {
    // If there was an error fetching logs
    if (isFetchingEventLogsError) {
      handleErrors(eventLogsFetchError);
      setTotalRecords(0);
      return;
    }

    // If we are in the process of fetching
    if (isFetchingEventLogs) {
      setEmptyTableProps(null);
      setTotalRecords(0);
      return;
    }

    // If we do have some logs
    if (!isNull(eventLogsData) && eventLogsData?.length > 0) {
      setEmptyTableProps(null);
      setTotalRecords(eventLogsQuery.count);
      return;
    }

    // If we have 0 logs and there are no filters on, that means no logs exist at all
    if (
      searchTerm === '' &&
      isEqual(sortOptions, EMPTY_TABLE_SORT_STATES) &&
      isEqual(filterSelections, EMPTY_TABLE_FILTER_STATES)
    ) {
      setEmptyTableProps({ ...CREATE_WEBHOOK_CTA_CONFIG });
    } else {
      // otherwise, it means that no logs were found with those search criteria
      setEmptyTableProps({
        headerText: NO_RESULTS_HEADER,
        description: NO_RESULTS_DESCRIPTION,
      });
    }

    setTotalRecords(0);
  }, [isFetchingEventLogsError, isFetchingEventLogs]);

  return { emptyTableProps };
};

export default useSetEmptyLogsTableProps;
