import { Box, Search, SearchVariant } from 'ds4-beta';
import { DICTIONARY } from '../../../dictionary';
import { useGetEventTypes } from '../../../common/queries';
import { ReactNode, useEffect, useState } from 'react';
import {
  StyledSearchResults,
  StyledSearchOption,
  StyledSuggestion,
  StyledNotFound,
} from './searchbar.style';
import { map, filter } from 'lodash';
import { REGISTRY } from '../../../registry';

const EventsSearchbar = ({ onSearch }) => {
  const { data, isFetching } = useGetEventTypes();

  const [eventTypes, setEventTypes] = useState([]);
  const [searchResults, setSearchResults] = useState(['pim:product:created']);

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setEventTypes(map(data, 'type'));
  }, [data]);

  useEffect(() => {
    if (eventTypes.length == 0) {
      return;
    }

    setSearchResults(eventTypes);
  }, [eventTypes]);

  const handleSearch = (term: string) => {
    setSearchResults(
      filter(eventTypes, (eventType: string) => eventType.includes(term))
    );
  };

  const handleClear = () => {
    setSearchResults(eventTypes);
    onSearch(null);
  };

  const renderSearchResults = () => {
    return (
      <StyledSearchResults>
        <StyledSuggestion>
          {DICTIONARY.GENERAL.SEARCH_RESULTS.SUGGESTIONS}
        </StyledSuggestion>
        {searchResults &&
          searchResults.map(result => (
            <StyledSearchOption
              key={result}
              onClick={() => onSearch(result)}
              data-testid={`${REGISTRY.SUBSCRIPTIONS.FORM.EVENTS.SEARCH_OPTION_PREFFIX}-${result}`}
            >
              {result}
            </StyledSearchOption>
          ))}
        {searchResults.length === 0 && (
          <StyledNotFound>
            {DICTIONARY.GENERAL.SEARCH_RESULTS.NOT_FOUND}
          </StyledNotFound>
        )}
      </StyledSearchResults>
    );
  };

  return (
    <Box
      flex={{
        justifyContent: 'left',
      }}
    >
      <Search
        placeholder={
          DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.EVENTS.SEARCH_PLACEHOLDER
        }
        variant={SearchVariant.PAGE}
        maxHeight='300px'
        showResult={true}
        onChange={handleSearch}
        onSearch={handleSearch}
        onClear={handleClear}
        results={renderSearchResults()}
        dataTestid={REGISTRY.SUBSCRIPTIONS.FORM.EVENTS.EVENTS_SEARCH}
      />
    </Box>
  );
};

export default EventsSearchbar;
