import { client } from '../lib/request';
import { handleProductAlias, ignoreSearchEvents } from './utils/transform-events';

const getEvents = async () => {
  const { data } = await client.get('/events');
  return handleProductAlias(ignoreSearchEvents(data?.data));
};

const getEventByType = async type => {
  if (!type) return null;

  const { data } = await client.get(`/events/${type}`);
  return data;
};

export const EventMetadataAPI = { getEvents, getEventByType };
