import { theme } from 'ds4-beta';
import styled from 'styled-components';

type StyledElevatedCardType = { elevation: number };

export const StyledElevatedCard = styled.div<StyledElevatedCardType>`
  ${({ elevation }) => theme.elevation[elevation]}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 102px;
  border-radius: ${theme.space[1]};
`;
