import { Box, DataProps, InteractiveIcon, Tag, Tooltip } from 'ds4-beta';
import {
  EventLogResponseData,
  LogDetailsResponseData,
  ResendEventsRequest,
} from '../../types';
import { DELIVERY_LOGS_REGISTRY } from '../../registry';
import { StatusBadge, LogDateTime } from '../../molecules';
import { TableEntryOverflow } from '../../../common/molecules';
import { TableCTAIcon } from '../../atoms';
import { SHOW_ON_ROW_HOVER_CLASS_NAME } from '../../../constants';
import { isEmpty } from 'lodash';
import { DICTIONARY } from '../../../dictionary';

const {
  TOOLTIP_LABELS,
  DEFAULT_LOG_TYPE_VALUE,
  TEST_LOG_TYPE_VALUE,
  OMS_LOG_DELIVERY_STATUS,
  COLUMN_NAMES: {
    STATUS_CODE,
    EVENT_DATE,
    SUBSCRIPTION_NAME,
    EVENT_TYPE,
    ENDPOINT,
    LOG_TYPE,
    SUBSCRIPTION_ID,
    EVENT_ID,
  },
} = DICTIONARY.DELIVERY_LOGS;

export const getTableData = (
  data: EventLogResponseData[],
  handleOpenDrawer: (id: string) => void,
  hasAdminAccess: boolean,
  resendEventsHandler,
  validSubscriptionsMap
): DataProps[] => {
  if (isEmpty(data)) return [];
  const result: DataProps[] = [];

  data.forEach(dataPoint => {
    const allowResend =
      hasAdminAccess &&
      dataPoint?.type !== TEST_LOG_TYPE_VALUE &&
      validSubscriptionsMap[dataPoint.subscriptionId];

    let disabledResendReason;
    if (!allowResend) {
      disabledResendReason =
        dataPoint?.type === TEST_LOG_TYPE_VALUE
          ? DICTIONARY.DELIVERY_LOGS.WARNINGS.RESEND_TEST_EVENT
          : DICTIONARY.DELIVERY_LOGS.WARNINGS.RESEND_DELETED_SUBSCRIPTION_LOG;
    }

    result.push({
      id: dataPoint.id,
      selected: false,
      data: {
        [STATUS_CODE]: {
          value: dataPoint.status,
          onRender: () => (
            <StatusBadge status={(dataPoint?.status ?? '').toString()} />
          ),
        },
        [EVENT_DATE]: {
          value: dataPoint.triggeredAt,
          onRender: () => {
            return <LogDateTime date={dataPoint.triggeredAt} />;
          },
        },
        [SUBSCRIPTION_NAME]: {
          value: dataPoint.subscriptionName,
          onRender: () => (
            <TableEntryOverflow text={dataPoint.subscriptionName} />
          ),
        },
        [ENDPOINT]: {
          value: dataPoint.endpoint,
          onRender: () => <TableEntryOverflow text={dataPoint.endpoint} />,
        },
        [LOG_TYPE]: dataPoint.type ?? DEFAULT_LOG_TYPE_VALUE,
        [EVENT_TYPE]: {
          value: dataPoint.eventType,
          onRender: () => (
            <Tag id={dataPoint.id} primaryLabel={dataPoint.eventType} />
          ),
        },
        ' ': {
          value: 'cta',
          onRender: () => (
            <div className={SHOW_ON_ROW_HOVER_CLASS_NAME}>
              <Box
                flex={{
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                {allowResend && (
                  <TableCTAIcon
                    dataTestid={
                      DELIVERY_LOGS_REGISTRY.TABLE_CTA_DATA_TEST_IDS.RESEND
                    }
                    tooltipText={TOOLTIP_LABELS.RESEND}
                    iconName='Refresh'
                    onClickHandler={() => {
                      resendEventsHandler([
                        {
                          subscriptionId: dataPoint.subscriptionId,
                          eventIds: [dataPoint.eventId],
                        },
                      ]);
                    }}
                  />
                )}
                {!allowResend && (
                  <Tooltip text={disabledResendReason}>
                    <InteractiveIcon iconName='Info' />
                  </Tooltip>
                )}
                <TableCTAIcon
                  dataTestid={
                    DELIVERY_LOGS_REGISTRY.TABLE_CTA_DATA_TEST_IDS.SHOW_DETAILS
                  }
                  tooltipText={TOOLTIP_LABELS.OPEN_DRAWER}
                  iconName='Logs'
                  size={20}
                  onClickHandler={() => {
                    handleOpenDrawer(dataPoint.id);
                  }}
                />
              </Box>
            </div>
          ),
        },
        // Adding this information so that it is retrievable during the Bulk Resend operation,
        // but since this table column does not exist, this will not render on the screen.
        // Great for keeping data that doesn't necessarily need to be displayed.
        [SUBSCRIPTION_ID]: dataPoint.subscriptionId,
        [EVENT_ID]: dataPoint.eventId,
      },
      children: (dataPoint?.logs ?? [])
        .sort((a, b) => {
          if (Date.parse(a.createdAt) > Date.parse(b.createdAt)) return 1;
          return -1;
        })
        // Ignore PENDING status logs
        .filter((log: LogDetailsResponseData) =>
          log.deliveryStatus !== OMS_LOG_DELIVERY_STATUS.PENDING ? log : null
        )
        .map((log, index) => ({
          id: log.id,
          disabled: true,
          parentId: dataPoint.id,
          data: {
            [STATUS_CODE]: {
              value: log.status,
              onRender: () => <StatusBadge status={log?.status?.toString()} />,
            },
            [EVENT_DATE]: {
              value: log.createdAt,
              onRender: () => {
                return <LogDateTime date={log.createdAt} />;
              },
            },
            [SUBSCRIPTION_NAME]: `Attempt ${index + 1}`,
          },
        })),
    });
  });

  return result;
};

export const getAllSelectedTableItems = tableData => {
  /**
   * This util function will ensure that all events are selected
   * The result object will look like so:
   *  {
   *    'some-subscription-id-1': ['event-id-1', 'event-id-2', etc.],
   *    'some-subscription-id-2': ['event-id-3', etc.],
   *    'some-subscription-id-3': ['event-id-4', 'event-id-5', etc.],
   *  }
   */
  const newItemsSelected = {};
  tableData.forEach(tableRow => {
    const subscriptionId = tableRow.data[SUBSCRIPTION_ID];
    const eventId = tableRow.data[EVENT_ID];
    if (newItemsSelected.hasOwnProperty(subscriptionId)) {
      newItemsSelected[subscriptionId].push(eventId);
    } else {
      newItemsSelected[subscriptionId] = [eventId];
    }
  });

  return newItemsSelected;
};

const fetchParentSubscriptionId = (tableData, selectedChildId) => {
  let parentSubscriptionId;

  for (let i = 0; i < tableData.length; i++) {
    const data = tableData[i];
    if (!data.hasOwnProperty('children')) continue;

    for (let j = 0; j < data.children.length; j++) {
      const child = data.children[j];
      if (child.id === selectedChildId) {
        parentSubscriptionId = data.data[SUBSCRIPTION_ID];
        break;
      }
    }
  }
  return parentSubscriptionId;
};

export const getSelectedTableItems = (
  prevItemsSelected,
  selectedData,
  state,
  tableData
) => {
  /**
   * This util function will ensure the itemsSelected object is up to date
   * The prevItemsSelected object will look like so:
   *  {
   *    'some-subscription-id-1': ['event-id-1', 'event-id-2', etc.],
   *    'some-subscription-id-2': ['event-id-3', etc.],
   *    'some-subscription-id-3': ['event-id-4', 'event-id-5', etc.],
   *  }
   */
  let subscriptionId, eventId;

  if (selectedData.data.hasOwnProperty(SUBSCRIPTION_ID)) {
    // Then a parent checkbox has been selected
    subscriptionId = selectedData.data[SUBSCRIPTION_ID];
    eventId = selectedData.data[EVENT_ID];
  } else {
    // Then a child checkbox has been selected
    subscriptionId = fetchParentSubscriptionId(tableData, selectedData.id);
    eventId = selectedData.id;
  }

  const newItemsSelected = { ...prevItemsSelected };
  if (newItemsSelected.hasOwnProperty(subscriptionId)) {
    if (state) {
      // if we are adding
      newItemsSelected[subscriptionId].push(eventId);
    } else {
      // if we are removing
      const indexOfEl = newItemsSelected[subscriptionId].indexOf(eventId);
      if (indexOfEl >= 0) newItemsSelected[subscriptionId].splice(indexOfEl, 1);

      // if that was the last event for that subscription ID, remove the subscription ID key
      if (newItemsSelected[subscriptionId].length === 0) {
        delete newItemsSelected[subscriptionId];
      }
    }
  } else if (state) {
    newItemsSelected[subscriptionId] = [eventId];
  }
  return newItemsSelected;
};

export const getSelectedLogs = (currSelectedLogs, newlySelectedLog, state) => {
  let selectedLogs = [...currSelectedLogs];
  if (state) {
    // Push log if it was not already selected
    if (!selectedLogs.find(log => log.id === newlySelectedLog.id)) {
      selectedLogs.push(newlySelectedLog);
    }
  } else {
    selectedLogs = currSelectedLogs.filter(
      log => log.id !== newlySelectedLog.id
    );
  }

  return selectedLogs;
};

export const prepareResendEventsRequest = (input: {
  [key: string]: string[];
}): ResendEventsRequest[] => {
  /**
   * Converts the input
   *  {
   *    'some-subscription-id-1': ['event-id-1', 'event-id-2', etc.],
   *    'some-subscription-id-2': ['event-id-3', etc.],
   *    'some-subscription-id-3': ['event-id-4', 'event-id-5', etc.],
   *  }
   *  CONVERTS TO
   *  [
   *    {
   *       'subscriptionId': 'some-subscription-id-1',
   *       'eventIds': ['event-id-1', 'event-id-2', etc.],
   *    },
   *    {
   *       'subscriptionId': 'some-subscription-id-2',
   *       'eventIds': ['event-id-3', etc.],
   *    },
   *    {
   *       'subscriptionId':'some-subscription-id-3',
   *       'eventIds': ['event-id-4', 'event-id-5', etc.],
   *    },
   *  ]
   */
  const result = [];
  const inputKeys = Object.keys(input);
  for (let inputKey of inputKeys) {
    result.push({
      subscriptionId: inputKey,
      eventIds: input[inputKey],
    });
  }
  return result;
};
