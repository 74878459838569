import { Badge, Link, Tag, Tooltip } from 'ds4-beta';
import { join, map, slice, truncate } from 'lodash';
import { DICTIONARY } from '../../../dictionary';
import { EventMetadataResponse } from '../../../common/types';
import { Row } from '../../../common/atoms';
import {
  ICONS,
  MAX_SUBSCRIPTION_NAME_LENGTH,
  SHOW_ON_ROW_HOVER_CLASS_NAME,
} from '../../../constants';
import { TableCTAIcon } from '../../../delivery-logs/atoms';
import { REGISTRY } from '../../../registry';
import { TableEntryOverflow } from '../../../common/molecules';

// Column order: name, id, endpoint, events state and actions
export const parseTableData = (
  subscription,
  hasAdminAccess,
  onTest = id => {},
  onEdit = id => {},
  onDelete = id => {}
) => {
  // There is no pause functionality yet - therefore hard coding status for now (all existing subscriptions are active, otherwise they have been deleted)
  const status = 'Active';
  const { id, name, targetUrl, events } = subscription;
  return {
    id: id,
    data: {
      [DICTIONARY.SUBSCRIPTIONS.LIST.COLUMNS[0].title]: {
        value: name,
        onRender: () => renderNameCell(name, id, onEdit),
      },
      [DICTIONARY.SUBSCRIPTIONS.LIST.COLUMNS[1].title]: {
        value: targetUrl,
        onRender: () => renderEndpointCell(targetUrl),
      },
      [DICTIONARY.SUBSCRIPTIONS.LIST.COLUMNS[2].title]: {
        value: events,
        onRender: () => renderEventsCell(events),
      },
      [DICTIONARY.SUBSCRIPTIONS.LIST.COLUMNS[3].title]: {
        value: status,
        onRender: () => renderStatusCell(status),
      },
      [DICTIONARY.SUBSCRIPTIONS.LIST.COLUMNS[4].title]: {
        value: 'cta',
        onRender: () =>
          hasAdminAccess ? (
            renderCTACell(subscription, onTest, onEdit, onDelete)
          ) : (
            <></>
          ),
      },
    },
  };
};

const renderEndpointCell = (endpoint: string) => {
  return <TableEntryOverflow text={endpoint} />;
};

const renderNameCell = (
  name: string,
  id: string,
  onEdit: (id: string) => void
) => {
  const truncatedName = truncate(name, {
    length: MAX_SUBSCRIPTION_NAME_LENGTH,
  });
  return (
    <TableEntryOverflow text={name}>
      <Link label={truncatedName} mode='inline' onClick={() => onEdit(id)} />
    </TableEntryOverflow>
  );
};

const renderEventsCell = (events: EventMetadataResponse[]) => {
  return (
    <Row gap={1}>
      <Tag id={events[0].type} primaryLabel={events[0].type} />
      {events.length > 1 && (
        <Tooltip
          text={join(slice(map(events, 'type'), 1), ', ')}
          showArrow={true}
        >
          <Tag id='-1' primaryLabel={`+${events.length - 1}`} />
        </Tooltip>
      )}
    </Row>
  );
};

const renderStatusCell = (status: string) => {
  return <Badge label={status} status='success' />;
};

const renderCTACell = (subscription, onTest, onEdit, onDelete) => {
  return (
    <div className={SHOW_ON_ROW_HOVER_CLASS_NAME}>
      <Row flex={{ justifyContent: 'flex-end' }}>
        <TableCTAIcon
          iconName={ICONS.TEST}
          onClickHandler={() => onTest(subscription.id)}
          tooltipText={DICTIONARY.SUBSCRIPTIONS.LIST.CTA.TEST}
          dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.CTA.TEST}
        />
        <TableCTAIcon
          iconName={ICONS.PENCIL}
          onClickHandler={() => onEdit(subscription.id)}
          tooltipText={DICTIONARY.SUBSCRIPTIONS.LIST.CTA.EDIT}
          dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.CTA.EDIT}
        />
        <TableCTAIcon
          iconName={ICONS.TRASH}
          onClickHandler={() => onDelete(subscription)}
          tooltipText={DICTIONARY.SUBSCRIPTIONS.LIST.CTA.DELETE}
          dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.CTA.DELETE}
        />
      </Row>
    </div>
  );
};
