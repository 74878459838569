import { PAGINATION_DETAILS } from '../../constants';
import { client } from '../../lib/request';
import { EventLogsQueryParams, ResendEventsRequest } from '../types';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { EMPTY_TABLE_FILTER_STATES } from '../constants';
import {
  getDefaultFilterParamObject,
  getCustomFilterParamObject,
  getSortFilterString,
  mergeDateTime,
} from './utils';
import { DateTimePickerProps } from '../../common/types';

const getEventLogs = async ({
  offset,
  sortOptions,
  searchTerm,
  filterSelections,
  logRetentionInDays,
}: EventLogsQueryParams) => {
  const sortFilterString = getSortFilterString(sortOptions);
  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: PAGINATION_DETAILS.PER_PAGE.toString(),
    ...(sortFilterString !== '' && {
      sort: getSortFilterString(sortOptions),
    }),
    ...(searchTerm !== '' && {
      searchTerm: searchTerm,
    }),
    ...(isEqual(filterSelections, EMPTY_TABLE_FILTER_STATES)
      ? getDefaultFilterParamObject(logRetentionInDays)
      : getCustomFilterParamObject(filterSelections, logRetentionInDays)),
  });

  const response = await client.get(`/event-logs?${params.toString()}`);
  return response?.data;
};

const resendEvents = async (input: ResendEventsRequest[]) => {
  // 1. Consolidate any duplicative input
  const result = {};
  for (let subscription of input) {
    if (subscription.subscriptionId in result) {
      result[subscription.subscriptionId].concat(subscription.eventIds);
    } else {
      result[subscription.subscriptionId] = subscription.eventIds;
    }
  }
  const subscriptions = Object.keys(result);

  // 2. Create promises for each subscription
  const promiseArray = [];
  for (let subscriptionName of subscriptions) {
    const eventIdsArray = result[subscriptionName];
    promiseArray.push(
      client.post(
        `/event-subscriptions/${subscriptionName}/actions/resend/batch`,
        {
          data: eventIdsArray,
        }
      )
    );
  }

  const bulkActionResult = await Promise.allSettled(promiseArray);
  bulkActionResult.forEach(dataPoint => {
    if (dataPoint.status === 'rejected') {
      const parsedError = (dataPoint.reason as AxiosError).response.data;
      throw new Error(parsedError as string);
    }
  });
  return bulkActionResult;
};

const replayEvents = async ({
  subscriptionId,
  dateSelection,
  timeSelection,
}: {
  subscriptionId: string;
  dateSelection: DateTimePickerProps;
  timeSelection: DateTimePickerProps;
}) => {
  // Request Body should look like so:
  // {
  //   "startTime": "2023-07-07T06:33:57Z",
  //   "endTime": "2023-07-11T06:33:57Z"
  // }
  const requestBody = {
    startTime: mergeDateTime(dateSelection.startDate, timeSelection.startTime),
    endTime: mergeDateTime(dateSelection.endDate, timeSelection.endTime),
  };
  const response = await client.post(
    `/event-subscriptions/${subscriptionId}/actions/replay`,
    requestBody
  );
  return response;
};

export const EventLogsAPI = {
  getEventLogs,
  resendEvents,
  replayEvents,
};
