import { Modal } from 'ds4-beta';
import { useEffect, useState } from 'react';
import { REGISTRY } from '../../registry';

const ConfirmActionModal = ({
  header,
  description,
  cancelBtnLabel,
  confirmBtnLabel,
  dataTestId,
  icon = null,
  isVisible = false,
  onConfirm,
  onCancel = () => {},
}) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(null);

  useEffect(() => {
    setIsConfirming(isVisible);
  }, [isVisible]);

  useEffect(() => {
    // Intentionally checking (null is first render, and should not invoke the callback)
    if (isConfirming === false) {
      onCancel();
    }
  }, [isConfirming]);

  return (
    <Modal
      size='small'
      isVisible={isConfirming}
      headerText={header}
      description={description}
      onBackdropClick={() => setIsConfirming(false)}
      onClose={() => setIsConfirming(false)}
      dataTestid={dataTestId}
      footerButtons={[
        {
          variant: 'secondary',
          onClick: () => setIsConfirming(false),
          text: cancelBtnLabel,
          dataTestid: REGISTRY.COMMON.BUTTONS.CANCEL,
        },
        {
          variant: 'destructive',
          onClick: onConfirm,
          text: confirmBtnLabel,
          dataTestid: REGISTRY.COMMON.BUTTONS.CONFIRM,
          ...(icon ? { icon } : {}),
        },
      ]}
    />
  );
};

export default ConfirmActionModal;
