import { Search, SearchVariant } from 'ds4-beta';
import {
  DELIVERY_LOGS_SEARCH_PLACEHOLDER,
  SEARCH_DEBOUNCE_TIME,
} from '../../constants';
import { DELIVERY_LOGS_REGISTRY } from '../registry';
import { debounce } from 'lodash';

const {
  SEARCH: { SEARCH_ELEMENT_TEST_ID },
} = DELIVERY_LOGS_REGISTRY;

const Searchbar = ({
  handleSearch,
}: {
  handleSearch: (inputStr: string) => void;
}) => {
  const onClear = () => {
    handleSearch('');
  };

  const onSearchClick = searchTerm => {
    handleSearch(searchTerm);
  };

  const onChange = debounce(searchTerm => {
    handleSearch(searchTerm);
  }, SEARCH_DEBOUNCE_TIME);

  return (
    <Search
      onClear={onClear}
      onSearch={onSearchClick}
      onChange={onChange}
      placeholder={DELIVERY_LOGS_SEARCH_PLACEHOLDER}
      variant={SearchVariant.PAGE}
      showResult={false}
      dataTestid={SEARCH_ELEMENT_TEST_ID}
    />
  );
};

export default Searchbar;
