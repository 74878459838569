import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useToast } from 'ds4-beta';
import { FormProvider, useForm } from 'react-hook-form';
import {
  SubscriptionFormHeader,
  SubscriptionFormDetails,
  SubscriptionFormAuth,
  SubscriptionFormEvents,
} from '../organisms';
import { FormSection, SectionLoading } from '../../common/atoms';
import { SaveSubscriptionRequest, SubscriptionDetailsPageType } from '../types';
import { useSaveSubscription } from '../queries';
import { REGISTRY } from '../../registry';
import { DICTIONARY } from '../../dictionary';
import { PAGE_URLS } from '../../routes';

const SubscriptionDetailsPage = ({
  subscription = null,
  isFetching = false,
  refetchSubscriptionHandler = () => {},
}: SubscriptionDetailsPageType) => {
  const navigate = useNavigate();
  const showToast = useToast();

  const {
    save,
    data: savedSubscription,
    saveError,
    isSaving,
    isSaveError,
    isSaveSuccess,
  } = useSaveSubscription();

  const methods = useForm<SaveSubscriptionRequest>({
    mode: 'onChange',
    defaultValues: {
      deliveryType: DICTIONARY.SUBSCRIPTIONS.DEFAULTS.DELIVERY_TYPE,
      authorizationDetails: {
        config: {
          apiKeyHeaderName: DICTIONARY.SUBSCRIPTIONS.DEFAULTS.API_KEY_HEADER,
        },
        type: REGISTRY.SUBSCRIPTIONS.FORM.AUTH.TYPE.API_KEY,
      },
      events: [],
      ...subscription,
    },
  });

  const { reset, setError, handleSubmit } = methods;

  useEffect(() => {
    if (!subscription) return;

    // Required to reload react-hook-form and fill in the input fields
    reset(subscription);
  }, [subscription]);

  useEffect(() => {
    if (!isSaveSuccess || !savedSubscription) return;

    if (savedSubscription.id === subscription?.id) {
      // if we have saved a subscription when on the edit page,
      // simply refetch
      refetchSubscriptionHandler();
    } else {
      // otherwise, we've created a new subscription
      // so we have to navigate to the edit page
      navigate(PAGE_URLS.SUBSCRIPTIONS.EDIT(savedSubscription.id));
    }

    const toastMessage = subscription
      ? DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AFTER_SAVE.UPDATED(
          savedSubscription.name
        )
      : DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AFTER_SAVE.CREATED(
          savedSubscription.name
        );

    showToast({
      id: REGISTRY.SUBSCRIPTIONS.FORM.AFTER_SAVE.SUCCESS,
      label: toastMessage,
    });
  }, [isSaveSuccess, savedSubscription]);

  useEffect(() => {
    if (!isSaveError || !saveError) return;

    if (
      saveError.type ===
      DICTIONARY.SUBSCRIPTIONS.ERROR_TYPES.DUPLICATE_SUBSCRIPTION_NAME
    ) {
      setError('name', saveError);
    } else {
      showToast({
        id: REGISTRY.SUBSCRIPTIONS.FORM.AFTER_SAVE.ERROR,
        label: saveError.message,
        variant: 'error',
      });
    }
  }, [isSaveError, saveError]);

  const handleSaveSubscription = async data => {
    save(data);
  };

  const handleTestSubscription = () => {
    navigate(PAGE_URLS.SUBSCRIPTIONS.TEST(subscription.id));
  };

  return (
    <>
      {/* Need a better page loading effect handling */}
      {isFetching && <SectionLoading />}
      {!isFetching && (
        <FormProvider {...methods}>
          <Box padding={[6]}>
            <SubscriptionFormHeader
              subscription={subscription}
              isSaving={isSaving}
              isUpdating={!!subscription}
              onTest={handleTestSubscription}
              onSave={handleSubmit(handleSaveSubscription)}
            />
            <FormSection>
              <SubscriptionFormDetails />
            </FormSection>
            <FormSection>
              <SubscriptionFormAuth isUpdating={!!subscription} />
            </FormSection>
            <FormSection>
              <SubscriptionFormEvents />
            </FormSection>
          </Box>
        </FormProvider>
      )}
    </>
  );
};

export default SubscriptionDetailsPage;
