import { theme } from 'ds4-beta';
import styled from 'styled-components';

export const StyledSearchResults = styled.div`
  overflow-y: auto;
`;

export const StyledSuggestion = styled.div`
  ${theme.typography.fieldLabel}
  color: ${theme.color.grey[600]};
  padding: ${theme.space[3]} 0 ${theme.space[2]} ${theme.space[3]};
`;

export const StyledSearchOption = styled.div`
  ${theme.typography.body1.regular}
  padding: ${theme.space[2]} ${theme.space[3]};
  cursor: pointer;

  &:hover {
    transition: linear 0.3s;
    background-color: ${theme.color.blue[50]};
  }
`;

export const StyledNotFound = styled.div`
  ${theme.typography.body1.regular}
  padding: ${theme.space[2]} ${theme.space[3]};
`;
