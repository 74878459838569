/** Registry of ids, names and testids */

import { SUBSCRIPTIONS_REGISTRY } from './subscriptions/registry';
import { DELIVERY_LOGS_REGISTRY } from './delivery-logs/registry';

export const REGISTRY = {
  COMMON: {
    HEADER: {
      TITLE: 'header-title',
      MORE_INFO: 'header-more-info',
      CTA: 'header-cta',
    },
    NAVIGATION: {
      TAB: 'navigation-tab',
      SUBSCRIPTION: 'navigation-subscription-tab-item',
      LOGS: 'navigation-logs-tab-item',
    },
    BUTTONS: {
      SAVE: 'save-btn',
      CANCEL: 'cancel-btn',
      MORE_ACTIONS: 'more-actions-btn',
      DELETE: 'delete-btn',
      CONFIRM: 'confirm-btn',
    },
    SNIPPET: {
      STATUS: 'code-snippet-status-badge',
      COPY_BTN: 'code-snippet-status-copy-btn',
    },
  },
  SUBSCRIPTIONS: SUBSCRIPTIONS_REGISTRY,
  DELIVERY_LOGS: DELIVERY_LOGS_REGISTRY,
  EVENTS: {
    QUERY_KEY: 'events',
  },
};

export const FEATURE_FLAGS = {
  PROJECT: 'copilot-mfa-event-subscription',
  SHOW_WEBHOOKS_CTA: 'show-webhooks-cta',
  SHOW_DEV_TOOLS: 'dev-tools',
  SHOW_SUBSCRIPTION_STATE: 'subscription-state',
  SHOW_LOGS_METRICS: 'logs-metrics',
  APPEND_VERSION_ON_GET_EVENT: 'append-version-get-event',
};
