import { ReactNode, isValidElement } from 'react';
import { Tooltip, theme } from 'ds4-beta';
import { TableEntryOverflowContainer } from './table-entry-overflow.style';

const TableEntryOverflow = ({
  text,
  children,
}: {
  text: string;
  children?: ReactNode;
}) => (
  <TableEntryOverflowContainer>
    <Tooltip text={text} placement='top' showArrow>
      {children && isValidElement(children) ? (
        children
      ) : (
        <span style={theme.typography.body1.regular}>{text}</span>
      )}
    </Tooltip>
  </TableEntryOverflowContainer>
);

export default TableEntryOverflow;
