import SubscriptionDetailsPage from './details';
import { BASE_BREADCRUMBS, PAGE_URLS } from '../../routes';

const NewSubscriptionPage = () => {
  // setTimeout can be removed once COP-388 is fixed
  setTimeout(() => {
    window.updateBreadcrumb([
      ...BASE_BREADCRUMBS,
      {
        label: 'Add Webhook',
        url: PAGE_URLS.SUBSCRIPTIONS.NEW,
        disabled: true,
      },
    ]);
  }, 0);

  return <SubscriptionDetailsPage />;
};

export default NewSubscriptionPage;
