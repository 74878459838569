import { Badge, InteractiveIcon, Tooltip, theme, useToast } from 'ds4-beta';
import DOMPurify from 'dompurify';
import { SnippetContainer, StyledStatusBar } from './snippet.style';
import { copyToClipboard } from '../../../lib/utils';
import { REGISTRY } from '../../../registry';
import { DICTIONARY } from '../../../dictionary';

const CodeSnippet = ({
  content,
  status = null,
  stretch = false,
  dataTestid,
}: {
  content: string;
  stretch?: boolean;
  status?: {
    isError: boolean;
    code: number;
  };
  dataTestid?: string;
}) => {
  const showToast = useToast();

  const safeHTML = DOMPurify.sanitize(content);

  const handleCopy = () => {
    copyToClipboard(safeHTML);

    showToast({ id: 'copy-toast', label: 'Content copied to clipboard' });
  };

  return (
    <div data-testid={dataTestid}>
      <StyledStatusBar>
        {status ? (
          <Badge
            label={status.code.toString()}
            asset='bullet'
            variant='secondary'
            status={status.isError ? 'error' : 'success'}
            dataTestid={REGISTRY.COMMON.SNIPPET.STATUS}
          />
        ) : (
          <div></div>
        )}
        <Tooltip
          showArrow
          placement='top'
          text={DICTIONARY.GENERAL.BUTTONS.COPY}
        >
          <InteractiveIcon
            iconName='Copy'
            variant='light'
            color={theme.color.grey[0]}
            dataTestid={REGISTRY.COMMON.SNIPPET.COPY_BTN}
            id={REGISTRY.COMMON.SNIPPET.COPY_BTN}
            onClick={handleCopy}
          />
        </Tooltip>
      </StyledStatusBar>
      <SnippetContainer
        status={!!status}
        stretch={stretch}
        dangerouslySetInnerHTML={{
          __html: `<pre>${safeHTML}</pre`,
        }}
      />
    </div>
  );
};

export default CodeSnippet;
