import { isHttpsUri } from 'valid-url';
import { find, isNil } from 'lodash';
import { DEVELOPER_PORTAL_URL, SORT_TYPE } from '../constants';
import { EventMetadataResponse } from '../common/types';

// Type util
export type PickAndOptional<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

export const validateUrl = (url: string) => !!isHttpsUri(url);

export const getSortOrderString = (
  filterAttrName: string,
  sortOrder: SORT_TYPE
) => {
  if (sortOrder === SORT_TYPE.ASCENDING) {
    return `+${filterAttrName}`;
  } else if (sortOrder === SORT_TYPE.DESCENDING) {
    return `-${filterAttrName}`;
  }
  return '';
};

export const getSubscriptionEvent = (
  events: EventMetadataResponse[],
  eventType: string
) => {
  return events?.length === 0
    ? null
    : find(events, event => event.type === eventType) ?? null;
};

export const getDateFilterBoundaryDates = (days: number) => {
  return {
    today: new Date(),
    xDaysAgoDate: new Date(new Date().getTime() - days * 24 * 60 * 60 * 1000),
  };
};

export const copyToClipboard = content => {
  navigator.clipboard.writeText(content);
};

export const handleDevPortalClick = () => {
  window.open(DEVELOPER_PORTAL_URL, '_blank');
};

export const jsonFormat = (content: string) => {
  try {
    return JSON.stringify(JSON.parse(content), null, 2);
  } catch (err) {
    console.log('Error while parsing json', err);
    return content;
  }
}

export const formatInteger = (value: number) => isNil(value) || isNaN(value) ? 0 : value?.toLocaleString(undefined, { maximumFractionDigits: 0 });
