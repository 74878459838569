import { Box, GridCol, GridRow, Table } from 'ds4-beta';
import { rbac } from '@teamfabric/copilot-utilities';
import { DICTIONARY } from '../../../dictionary';
import { REGISTRY } from '../../../registry';
import { useEffect, useState } from 'react';
import { parseTableData } from './table.utils';
import {
  EVENT_ADMIN_ROLE_PERMISSIONS,
  PAGINATION_DETAILS,
} from '../../../constants';
import { SubscriptionListTableType } from '../../types';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '../../../routes';
import useEmptySubscriptionsTableProps from '../../hooks/useEmptySubscriptionsTableProps';
import { isEmpty, isNil } from 'lodash';

const SubscriptionsTable = ({
  filters,
  subscriptions,
  count,
  isFetchingData = false,
  onTest,
  onDelete,
  onAscendingSort,
  onDescendingSort,
  activePage = 0,
  onPageChange,
  setCaption,
}: SubscriptionListTableType) => {
  const [tableData, setTableData] = useState([]);

  const { hasAccess: hasAdminAccess } = rbac.checkPermission({
    allowedPermissions: EVENT_ADMIN_ROLE_PERMISSIONS,
  });

  const navigate = useNavigate();

  const handleEditClick = (id: string) => {
    navigate(PAGE_URLS.SUBSCRIPTIONS.EDIT(id));
  };

  const { emptyTableProps } = useEmptySubscriptionsTableProps({
    subscriptions,
    isFetchingData,
    filters,
  });

  useEffect(() => {
    if (isNil(subscriptions)) return;

    setTableData(
      subscriptions.map(subscription =>
        parseTableData(
          subscription,
          hasAdminAccess,
          onTest,
          handleEditClick,
          onDelete
        )
      )
    );
  }, [subscriptions]);

  return (
    <GridRow padding={false}>
      <GridCol lg={12} md={12} sm={12}>
        <Box dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.TABLE}>
          <Table
            loading={isFetchingData}
            columns={DICTIONARY.SUBSCRIPTIONS.LIST.COLUMNS}
            data={tableData}
            showPagination
            dataTestidPrefix={REGISTRY.SUBSCRIPTIONS.LIST.TABLE}
            activePage={activePage}
            onAscendingSort={onAscendingSort}
            onDescendingSort={onDescendingSort}
            emptyTableProps={emptyTableProps}
            {...(isEmpty(tableData) && {
              tableCaption: '',
            })}
            {...(!emptyTableProps && {
              customPaginationProps: {
                perPage: PAGINATION_DETAILS.PER_PAGE,
                totalRecords: count,
                activePageNumber: activePage,
                handlePagination: onPageChange,
                setCaption: setCaption,
              },
            })}
          />
        </Box>
      </GridCol>
    </GridRow>
  );
};

export default SubscriptionsTable;
