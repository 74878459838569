import { Box, SkeletonPageSearch, SkeletonBadge } from 'ds4-beta';
import { VerticalSpace } from '../../../common/atoms';
import { SUBSCRIPTIONS_REGISTRY } from '../../registry';

const FiltersLoadingState = () => {
  return (
    <Box
      padding={{
        top: 3,
        bottom: 3,
      }}
      dataTestid={SUBSCRIPTIONS_REGISTRY.LOADING_STATE.FILTERS}
    >
      <VerticalSpace size={1} />
      <SkeletonPageSearch />
      <VerticalSpace />
      <SkeletonBadge />
      <VerticalSpace />
    </Box>
  );
};

export default FiltersLoadingState;
