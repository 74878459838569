import { DICTIONARY } from '../../../dictionary';
import {
  FormInput,
  FormSectionTitle,
  UrlInput,
  VerticalSpace,
} from '../../../common/atoms';
import { REGISTRY } from '../../../registry';
import FormTextarea from '../../../common/atoms/form/textarea';
import { useFormContext } from 'react-hook-form';

const SubscriptionFormDetails = () => {
  const { setValue } = useFormContext();

  const handleEndpointPrefix = event => {
    if (!event.target.value || event.target.value === '') {
      setValue('targetUrl', 'https://');
    }
  };

  return (
    <>
      <FormSectionTitle
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.TITLE}
      />
      <VerticalSpace />
      <FormInput
        name='name'
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.NAME}
        required={true}
        maxLength={DICTIONARY.SUBSCRIPTIONS.FORM_SETTINGS.NAME_MAX_LENGTH}
        inputProps={{
          placeholder:
            DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.PLACEHOLDERS.NAME,
          dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.DETAILS.NAME,
        }}
      />
      <VerticalSpace />
      <UrlInput
        name='targetUrl'
        alias={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.ENDPOINT_ALIAS}
        required={true}
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.ENDPOINT}
        inputProps={{
          placeholder:
            DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.PLACEHOLDERS.ENDPOINT,
          onClick: handleEndpointPrefix,
          onFocus: handleEndpointPrefix,
          dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.DETAILS.ENDPOINT,
        }}
      />
      <VerticalSpace />
      <FormTextarea
        name='description'
        label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.DESCRIPTION}
        inputProps={{
          dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.DETAILS.DESCRIPTION,
        }}
      />
    </>
  );
};

export default SubscriptionFormDetails;
