import styled from 'styled-components';
import { theme, GridCol } from 'ds4-beta';

const StyledGridSection = styled.div`
  div.fieldLabel {
    ${theme.typography.fieldLabel};
    color: ${theme.color.grey[900]};
    padding-bottom: ${theme.space[1]};
  }

  div.fieldContent {
    ${theme.typography.body1.regular};
  }

  padding-bottom: ${theme.space[2]};
`;

export const StyledSectionTitle = styled.div`
  padding: ${theme.space[3]} 0;
  ${theme.typography.body1.medium};
`;

export const DrawerCell = ({
  fieldLabel,
  staticContent,
  customRender,
}: {
  fieldLabel: string;
  staticContent?: string;
  customRender?: JSX.Element;
}): JSX.Element => {
  return (
    <GridCol lg={6} md={6} sm={12}>
      <StyledGridSection>
        <div className='fieldLabel'>{fieldLabel}</div>
        {staticContent && <div className='fieldContent'>{staticContent}</div>}
        {customRender ?? null}
      </StyledGridSection>
    </GridCol>
  );
};
