import { last } from 'lodash';
import { CodeSnippet } from '../../common/molecules';
import { Box } from 'ds4-beta';

const TestResponseSnippet = ({ response, error }) => {
  const content = JSON.stringify(last(response?.logs) || error?.data, null, 2);
  const code = response?.status || error?.status;

  return (
    <>
      {content && (
        <Box margin={{ top: 4 }}>
          <CodeSnippet status={{ code, isError: !!error }} content={content} />
        </Box>
      )}
    </>
  );
};

export default TestResponseSnippet;
