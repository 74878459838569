import { Drawer } from 'ds4-beta';
import { EventMetadataResponse } from '../../../common/types';
import { DICTIONARY } from '../../../dictionary';
import { useEffect, useState } from 'react';
import { CodeSnippet } from '../../../common/molecules';
import { useGetEventByType } from '../../../common/queries';
import { REGISTRY } from '../../../registry';
import { jsonFormat } from '../../../lib/utils';

const PreviewSchema = ({
  previewEvent,
  onClose,
}: {
  previewEvent: EventMetadataResponse | null;
  onClose: () => void;
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const { data } = useGetEventByType(previewEvent);

  useEffect(() => {
    setIsDrawerVisible(!!previewEvent);
  }, [previewEvent]);

  return (
    <Drawer
      title={`Preview of ${previewEvent?.type}`}
      subTitle={previewEvent?.description}
      isVisible={isDrawerVisible}
      onBackdropClick={onClose}
      dataTestId={REGISTRY.SUBSCRIPTIONS.PREVIEW_SCHEMA.DRAWER}
      headerButtons={[
        {
          variant: 'primary',
          text: DICTIONARY.GENERAL.BUTTONS.CLOSE,
          onClick: onClose,
        },
      ]}
    >
      {data && <CodeSnippet stretch content={jsonFormat(data.payload)} />}
    </Drawer>
  );
};

export default PreviewSchema;
