import { EventMetadataResponse } from '../types';
import { SPECIAL_PRODUCTS } from '../../constants';

const handleProductAlias = (events: EventMetadataResponse[]) => {
  const transformedEvents = events.map(event => {
    if (event.productAlias != undefined) {
      return {
        ...event,
        product: event.productAlias,
      };
    }
    return event;
  });

  return transformedEvents;
};

// Search svc events should not be subscribed
const ignoreSearchEvents = (events: EventMetadataResponse[]) => events.filter(event => event.product !== SPECIAL_PRODUCTS.SEARCH)

export { handleProductAlias, ignoreSearchEvents };
