import { ButtonProps, PageHeader, TooltipProps } from 'ds4-beta';
import { rbac } from '@teamfabric/copilot-utilities';
import { DICTIONARY } from '../../../dictionary';
import {
  BTN_VARIANTS,
  EVENT_ADMIN_ROLE_PERMISSIONS,
  ICONS,
} from '../../../constants';
import { FEATURE_FLAGS, REGISTRY } from '../../../registry';
import { useFlag } from '@unleash/proxy-client-react';
import { SubscriptionFormHeaderType } from '../../types';
import {
  useConfirmCancelling,
  useConfirmDeleteSubscription,
} from '../../hooks';
import { useFormState } from 'react-hook-form';

const SubscriptionFormHeader = ({
  subscription = null,
  isSaving = false,
  isUpdating = false,
  onSave,
  onTest,
  onCancel,
  onDelete,
}: SubscriptionFormHeaderType) => {
  const { isDirty: isFormDirty, isValid: isFormValid } = useFormState();

  const { hasAccess: hasAdminAccess } = rbac.checkPermission({
    allowedPermissions: EVENT_ADMIN_ROLE_PERMISSIONS,
  });

  const isIntegrationSubscription = subscription?.type === REGISTRY.SUBSCRIPTIONS.FORM.SUBSCRIPTION.TYPE.INTEGRATION;

  const showSubscriptionStateToggle = useFlag(
    FEATURE_FLAGS.SHOW_SUBSCRIPTION_STATE
  );

  const { showConfirmDeleteModal, renderConfirmDeleteModal } =
    useConfirmDeleteSubscription({ onConfirm: onDelete });

  const { cancel, showConfirmCancelModal, renderConfirmCancelModal } =
    useConfirmCancelling({ onConfirm: onCancel });

  const saveBtnTooltip: { tooltipInfo: TooltipProps } | {} =
    isSaving || (isFormDirty && isFormValid)
      ? {}
      : {
          tooltipInfo: {
            showOnDisabled: true,
            text: DICTIONARY.SUBSCRIPTIONS.TOOLTIPS.HEADER.FORM_DISABLED,
            placement: 'bottomEnd',
            children: <></>,
          },
        };

  const saveBtnConfig: ButtonProps = {
    onClick: onSave,
    text: DICTIONARY.GENERAL.BUTTONS.SAVE,
    variant: BTN_VARIANTS.PRIMARY,
    isDisabled: isSaving || !(isFormDirty && isFormValid),
    dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.SAVE_BTN,
    isLoading: isSaving,
    ...saveBtnTooltip,
  };

  const cancelBtnConfig = variant => ({
    onClick: () => {
      // When cancelling in an untouched state just returns the callback, otherwise show modal
      if (!isFormDirty) {
        cancel();
        return;
      }

      showConfirmCancelModal();
    },
    text: DICTIONARY.GENERAL.BUTTONS.CANCEL,
    variant: variant,
    dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.CANCEL_BTN,
  });

  const testBtnConfig = {
    onClick: () => onTest(subscription.id),
    text: DICTIONARY.SUBSCRIPTIONS.TEST.DETAIL_HEADER_BTN,
    variant: BTN_VARIANTS.SECONDARY,
    dataTestid: REGISTRY.SUBSCRIPTIONS.TEST.DETAIL_HEADER_BTN,
    tooltipInfo: {
      text: DICTIONARY.SUBSCRIPTIONS.TOOLTIPS.HEADER.TEST_SUBSCRIPTION,
      showArrow: true,
      placement: 'bottomEnd',
      children: <></>,
    },
  };

  const deleteBtnConfig = {
    onClick: () => showConfirmDeleteModal(subscription),
    text: DICTIONARY.GENERAL.BUTTONS.DELETE,
    icon: ICONS.TRASH,
    variant: BTN_VARIANTS.TERTIARY,
    dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.DELETE_BTN,
  };

  let createSubscriptionActionBtns: ButtonProps[] = [
    cancelBtnConfig(BTN_VARIANTS.SECONDARY)
  ];

  let updateSubscriptionActionBtns: ButtonProps[] = [
    cancelBtnConfig(BTN_VARIANTS.TERTIARY),
    testBtnConfig,
  ];

  if (!isIntegrationSubscription) {
    createSubscriptionActionBtns = [...createSubscriptionActionBtns, saveBtnConfig];
    updateSubscriptionActionBtns = [...updateSubscriptionActionBtns, saveBtnConfig];
  }
  

  return (
    <>
      <PageHeader
        h1Text={subscription?.name || DICTIONARY.SUBSCRIPTIONS.BTN_LABELS.ADD}
        h2Text={
          subscription && `ID: ${subscription.id} - ${subscription.targetUrl}`
        }
        primaryButtons={
          hasAdminAccess &&
          (isUpdating
            ? updateSubscriptionActionBtns
            : createSubscriptionActionBtns)
        }
        tertiaryButtons={
          hasAdminAccess && (!isIntegrationSubscription && isUpdating ? [deleteBtnConfig] : [])
        }
        switchProps={
          hasAdminAccess &&
          showSubscriptionStateToggle && {
            label: '',
            checked: !!subscription?.status || true, // defaults to active
            stateLabels: {
              OFF: DICTIONARY.SUBSCRIPTIONS.STATUS_LABELS.INACTIVE,
              ON: DICTIONARY.SUBSCRIPTIONS.STATUS_LABELS.ACTIVE,
            },
            dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.SUBSCRIPTION_STATUS,
          }
        }
      />

      {renderConfirmCancelModal()}
      {renderConfirmDeleteModal()}
    </>
  );
};

export default SubscriptionFormHeader;
