import { findIndex, size, capitalize, differenceBy, sortBy } from 'lodash';
import { EventTreeNode } from './tabs.type';
import { PRODUCT_MAPPING } from '../../dictionary';
import { EventMetadataResponse } from '../../../common/types';

const IDX_NOT_FOUND = -1;

export const parseEventsTree = (
  eventsMetadata: EventMetadataResponse[],
  defaultSelected: EventMetadataResponse[],
  highlightedEvent?: string
): EventTreeNode[] => {
  let eventTree = [];

  if (eventsMetadata?.length === 0) {
    return [];
  }

  let leaf: EventTreeNode = null;

  eventsMetadata.forEach(metadata => {
    const { product, service, type, description } = metadata;

    let productIdx = findIndex(
      eventTree,
      (node: EventTreeNode) => node.id === product
    );

    if (productIdx === IDX_NOT_FOUND) {
      eventTree.push({
        id: product,
        label: PRODUCT_MAPPING[product]?.name ?? '',
        order: PRODUCT_MAPPING[product]?.order ?? 99,
        isPartial: false,
        isHighlighted: false,
        selected: false,
        childrenCount: 0,
        selectedChildrenCount: 0,
        highlightedCount: 0,
        children: [],
      });

      productIdx = size(eventTree) - 1;
    }

    let serviceIdx = findIndex(
      eventTree[productIdx].children,
      (node: EventTreeNode) => node.id === service
    );

    if (serviceIdx === IDX_NOT_FOUND) {
      eventTree[productIdx].children.push({
        id: service,
        label: capitalize(service),
        isPartial: false,
        isHighlighted: false,
        selected: false,
        childrenCount: 0,
        selectedChildrenCount: 0,
        highlightedCount: 0,
        children: [],
      });

      serviceIdx = size(eventTree[productIdx].children) - 1;
    }

    eventTree[productIdx].children[serviceIdx].children.push({
      id: `${product}.${service}.${type}`,
      label: type,
      metadata: metadata,
      description: description,
      isPartial: false,
      isHighlighted: type === highlightedEvent,
      selected:
        findIndex(defaultSelected, selected => selected.type === type) !== -1,
      children: [],
    });

    leaf =
      eventTree[productIdx].children[serviceIdx].children[
        eventTree[productIdx].children[serviceIdx].children.length - 1
      ];

    eventTree[productIdx].childrenCount++;
    eventTree[productIdx].children[serviceIdx].childrenCount++;

    // Update tree selection
    if (leaf.selected) {
      eventTree[productIdx].selectedChildrenCount++;
      eventTree[productIdx].children[serviceIdx].selectedChildrenCount++;
    }

    if (leaf.isHighlighted) {
      // Highlight node if leaf is highlighted (will be used for expanding the parent node)
      eventTree[productIdx].highlightedCount++;
      eventTree[productIdx].children[serviceIdx].highlightedCount++;
    }

    // update product selection
    eventTree[productIdx].selected =
      eventTree[productIdx].selectedChildrenCount > 0;
    eventTree[productIdx].isPartial =
      eventTree[productIdx].selectedChildrenCount <
      eventTree[productIdx].childrenCount;
    eventTree[productIdx].isHighlighted =
      eventTree[productIdx].highlightedCount > 0;

    // update service selection
    eventTree[productIdx].children[serviceIdx].selected =
      eventTree[productIdx].children[serviceIdx].selectedChildrenCount > 0;
    eventTree[productIdx].children[serviceIdx].isPartial =
      eventTree[productIdx].children[serviceIdx].selectedChildrenCount <
      eventTree[productIdx].children[serviceIdx].childrenCount;
    eventTree[productIdx].children[serviceIdx].isHighlighted =
      eventTree[productIdx].children[serviceIdx].highlightedCount > 0;
  });

  // Sort eventTree root level by UX product order
  return sortBy(eventTree, ['order']);
};

export const updateNode = (node: EventTreeNode, selected: boolean) => {
  node.selected = selected;
  if (!isLeaf(node)) {
    node.children.forEach(child => updateNode(child, selected));
  }

  return node;
};

export const parseSelectedEvents = (
  node: EventTreeNode
): EventMetadataResponse[] => {
  if (isLeaf(node)) {
    return node.selected ? [node.metadata] : [];
  }

  let selectedLeaves = [];
  for (const child of node.children) {
    selectedLeaves = [...selectedLeaves, ...parseSelectedEvents(child)];
  }

  return selectedLeaves;
};

export const getEvents = (node: EventTreeNode) => {
  if (isLeaf(node)) {
    return [node.metadata];
  }

  let leaves = [];
  for (const child of node.children) {
    leaves = [...leaves, ...getEvents(child)];
  }

  return leaves;
};

/**
 * Sample event tree structure:
 * PIM
 *    Product
 *      pim:product:created
 *      pim:product:updated
 *    Category
 *      pim:category:created
 *      pim:category:updated
 * Offers
 *    Price
 *      offers:price:created
 *      offers:price:updated
 *
 * Example use-case:
 * T0: [pim:product:created, pim:product:updated, pim:category:created, pim:category:updated] (currSelectedEvents)
 * T1: user unselects "Category" option
 *  updatedNode -> {id: 'category', label: 'Category', children: [pim:category:created, pim:category:updated]}
 *  newlySelectedEvents -> [pim:category:created, pim:category:updated]
 *  prevSelectedEvents -> [pim:product:created, pim:product:updated]
 *  selectedEvents -> []
 *  returns -> [pim:product:created, pim:product:updated]
 */

export const selectEventsFromSelectedTreeNode = (
  selectedNode: EventTreeNode,
  currSelectedEvents: EventMetadataResponse[]
): EventMetadataResponse[] => {
  // From the node that was selected in the tree, update its children (parent nodes, should change the state of its children)
  const updatedNode: EventTreeNode = updateNode(
    selectedNode,
    !selectedNode.selected
  );

  // Retrieve all events that had their state changed
  const newlySelectedEvents: EventMetadataResponse[] = getEvents(updatedNode);

  // The tree might contain currently selected events, not affected by the current selection. This operation provides the resulting array of untouched events
  const prevSelectedEvents = differenceBy(
    currSelectedEvents,
    newlySelectedEvents,
    'type'
  );

  // From the updated tree structure, retrieve all the selected events (EventMetadataResponse)
  const selectedEvents = parseSelectedEvents(updatedNode);

  // Return all currently selected events (touched and untouched)
  return [...prevSelectedEvents, ...selectedEvents];
};

export const findNodeById = (
  id: string,
  tree: EventTreeNode[]
): EventTreeNode => {
  let targetNode = null;
  for (const node of tree) {
    targetNode = findNode(id, node);

    if (targetNode) {
      break;
    }
  }

  return targetNode;
};

const findNode = (id: string, node: EventTreeNode) => {
  if (id === node.id) {
    return node;
  }

  let targetNode = null;
  for (const child of node.children) {
    targetNode = findNode(id, child);
    if (targetNode) {
      break;
    }
  }

  return targetNode;
};

const isLeaf = (node: EventTreeNode) => {
  return node.children.length === 0;
};
