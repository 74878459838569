import { useEffect, useState, ChangeEvent } from 'react';
import { EmptyPageProps, GridRow, GridCol, Table } from 'ds4-beta';
import { PAGINATION_DETAILS } from '../../../constants';
import {
  getAllSelectedTableItems,
  getSelectedLogs,
  getSelectedTableItems,
  getTableData,
  prepareResendEventsRequest,
} from './utils';
import { EventLogResponseData, ResendEventsRequest } from '../../types';
import { TableActionsSection } from '../../../common/molecules';
import { DELIVERY_LOGS_DICTIONARY } from '../../dictionary';
import { DELIVERY_LOGS_REGISTRY } from '../../registry';
import { isNull, keyBy } from 'lodash';
import { useAllowResend } from '../../hooks/useAllowResend';
import { ExtendedDataProps } from './types';
import { StyledTableChildRowOverride } from './styles';

const DeliveryLogsTable = ({
  eventLogsOffset,
  totalRecords,
  data,
  isFetching,
  activePage,
  onPageChange,
  hasAdminAccess,
  emptyTableProps,
  handleOpenDrawer,
  resendEventsHandler,
  onAscendingSort,
  onDescendingSort,
}: {
  eventLogsOffset: number;
  totalRecords: number;
  data: EventLogResponseData[];
  isFetching: boolean;
  activePage: number;
  onPageChange: (page: number) => void;
  hasAdminAccess: boolean;
  emptyTableProps: EmptyPageProps;
  handleOpenDrawer: (id: string) => void;
  resendEventsHandler: (input: ResendEventsRequest[]) => void;
  onAscendingSort: (columnName: string) => void;
  onDescendingSort: (columnName: string) => void;
}) => {
  const [tableData, setTableData] = useState([]);
  const [showTableActions, setShowTableActions] = useState(false);
  const [itemsSelected, setItemsSelected] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [numSelected, setNumSelected] = useState(0);
  const [validLogsMap, setValidLogsMap] = useState(null);
  const [bulkResendWarning, setBulkResendWarning] = useState(null);

  const { hasTestDeliveryLog, hasDeletedSubscription, validSubscriptionsMap } =
    useAllowResend(selectedLogs);

  useEffect(() => {
    setValidLogsMap(keyBy(data, 'id'));
  }, [data]);

  useEffect(() => {
    if (!data || !validSubscriptionsMap) {
      return;
    }

    const newTableData = getTableData(
      data,
      handleOpenDrawer,
      hasAdminAccess,
      resendEventsHandler,
      validSubscriptionsMap
    );
    setTableData(newTableData);
  }, [data, validSubscriptionsMap]);

  const resetAllSelections = () => {
    setItemsSelected({});
    setSelectedLogs([]);
    setNumSelected(0);
  };

  const onAllRowSelectHandler = (
    _e: ChangeEvent<HTMLInputElement>,
    state: boolean
  ) => {
    if (state) {
      setItemsSelected(getAllSelectedTableItems(tableData));
      setSelectedLogs(data);
      setNumSelected(tableData.length);
      return;
    }

    resetAllSelections();
  };

  const onRowSelectHandler = (
    _e: ChangeEvent<HTMLInputElement>,
    selectedData: ExtendedDataProps,
    state: boolean
  ) => {
    setItemsSelected(prevItemsSelected =>
      getSelectedTableItems(prevItemsSelected, selectedData, state, tableData)
    );
    setSelectedLogs(prevSelectedLogs =>
      getSelectedLogs(
        prevSelectedLogs,
        validLogsMap[selectedData.parentId ?? selectedData.id],
        state
      )
    );
    setNumSelected(prevNumSelected => prevNumSelected + (state ? 1 : -1));
  };

  const onRowSelectionClear = () => {
    setTableData(prevTableData => {
      const newTableData = [...prevTableData];
      newTableData.forEach((data: ExtendedDataProps) => {
        data.selected = false;
        if (data.hasOwnProperty('children') && !isNull(data.children)) {
          data.children.forEach(child => {
            child.selected = false;
          });
        }
      });
      return newTableData;
    });
    resetAllSelections();
  };

  const handleResendEventsRequest = () => {
    const resendEventsRequestBody = prepareResendEventsRequest(itemsSelected);
    resendEventsHandler(resendEventsRequestBody);
  };

  useEffect(() => {
    setShowTableActions(numSelected > 0);
  }, [numSelected]);

  useEffect(() => {
    setBulkResendWarning(
      hasTestDeliveryLog
        ? DELIVERY_LOGS_DICTIONARY.WARNINGS.BULK_RESEND_TEST_EVENT
        : hasDeletedSubscription
        ? DELIVERY_LOGS_DICTIONARY.WARNINGS.BULK_RESEND_DELETED_SUBSCRIPTION_LOG
        : null
    );
  }, [hasTestDeliveryLog, hasDeletedSubscription]);

  return (
    <GridRow padding={false}>
      <GridCol lg={12} md={12} sm={12}>
        <TableActionsSection
          onClear={onRowSelectionClear}
          showTableActions={showTableActions}
          numItemsSelected={numSelected}
          tableCaptionProps={{
            start: eventLogsOffset + 1,
            end: eventLogsOffset + (data?.length ?? 0),
            totalRecords: totalRecords,
          }}
          ctaButtons={[
            {
              icon: 'Refresh',
              text: DELIVERY_LOGS_DICTIONARY.RESEND_EVENTS,
              variant: 'tertiary',
              isDisabled: hasTestDeliveryLog || hasDeletedSubscription,
              tooltip: bulkResendWarning,
              onClick: handleResendEventsRequest,
              dataTestid:
                DELIVERY_LOGS_REGISTRY.TABLE_CTA_DATA_TEST_IDS.RESEND_BULK,
            },
          ]}
        />
        <StyledTableChildRowOverride>
          <Table
            loading={isFetching}
            columns={DELIVERY_LOGS_DICTIONARY.LIST.COLUMNS}
            data={tableData}
            showPagination
            emptyTableProps={emptyTableProps}
            canHaveChildren
            selectable={hasAdminAccess}
            onAscendingSort={onAscendingSort}
            onDescendingSort={onDescendingSort}
            onAllRowSelect={onAllRowSelectHandler}
            onRowSelect={onRowSelectHandler}
            {...(!emptyTableProps && {
              customPaginationProps: {
                perPage: PAGINATION_DETAILS.PER_PAGE,
                totalRecords: totalRecords,
                activePageNumber: activePage,
                handlePagination: onPageChange,
              },
            })}
          />
        </StyledTableChildRowOverride>
      </GridCol>
    </GridRow>
  );
};

export default DeliveryLogsTable;
