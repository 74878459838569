import { Box, Tag, TagProps } from 'ds4-beta';

const SelectedFilterTags = ({ tagList }: { tagList: TagProps[] }) => {
  return (
    <Box flex={{ justifyContent: 'flex-start', flexWrap: 'wrap' }} gap={1}>
      {tagList.map(tag => (
        <Tag
          key={tag.id}
          id={tag.id}
          primaryLabel={tag.primaryLabel}
          isRemovable
          onRemove={tag.onRemove}
        />
      ))}
    </Box>
  );
};

export default SelectedFilterTags;
