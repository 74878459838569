import { DropdownOptionProps } from 'ds4-beta';
import { GetSubscriptionResponse } from '../../subscriptions/types';
import { truncate } from 'lodash';

const ENDPOINT_URL_CUTOFF_LENGTH = 30;

const transformSubscriptionsToDropdownOptions = (
  subscriptionOptions: GetSubscriptionResponse[],
  pastSubscriptionDropdownOptions: DropdownOptionProps[]
): DropdownOptionProps[] => {
  const result: DropdownOptionProps[] = [...pastSubscriptionDropdownOptions];

  subscriptionOptions.forEach(subscription => {
    const suffix = truncate(subscription.targetUrl, {
      length: ENDPOINT_URL_CUTOFF_LENGTH,
      omission: '...',
    });
    const isTruncated = suffix.endsWith('...');
    result.push({
      id: subscription.id,
      label: subscription.name,
      suffix: suffix,
      ...(isTruncated && {
        tooltipInfo: subscription.targetUrl,
      }),
    });
  });

  return result;
};

export { transformSubscriptionsToDropdownOptions };
