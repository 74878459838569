import { Box } from 'ds4-beta';
import { PageType } from '../types';

const PageNotFound = (_: PageType) => {
  return (
    <Box padding={[6]}>
      <h1>Page not found</h1>
    </Box>
  );
};

export default PageNotFound;
