import { Box } from 'ds4-beta';
import { RowType } from '../types';

const Row = ({ children, flexProps, ...props }: RowType) => {
  return (
    <Box width='100%' flex={{ flexDirection: 'row', ...flexProps }} {...props}>
      {children}
    </Box>
  );
};

export default Row;
