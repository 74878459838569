import {
  FlagProvider,
  IConfig as IUnleashConfig,
} from '@unleash/proxy-client-react';

import { SESSION_STORAGE_KEYS, UNLEASH_APP_NAME } from '../constants';

const unleashStorageProvider = (appName: string) => {
  const prefix: Readonly<string> = 'unleash';
  return {
    save: async (name: string, data: any) => {
      const repo = JSON.stringify(data);
      const key = `${prefix}:${appName}:${name}`;
      try {
        window.localStorage.setItem(key, repo);
      } catch (e) {
        console.error(`LeftNavStorageProvider Save Error: ${e}`);
      }
    },
    get: async (name: string) => {
      try {
        const key = `${prefix}:${appName}:${name}`;
        const data = window.localStorage.getItem(key);
        return data ? JSON.parse(data) : undefined;
      } catch (e) {
        console.error(`EventSubscriptionsStorageProvider Get Error: ${e}`);
      }
    },
  };
};

export const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  disableRefresh: true,
  appName: UNLEASH_APP_NAME,
  storageProvider: unleashStorageProvider(UNLEASH_APP_NAME),
  context: {
    userId: window.sessionStorage.getItem(SESSION_STORAGE_KEYS.V2_TENANT_ID),
  },
} as IUnleashConfig;

export const FeatureFlagProvider = ({ children }) => {
  return <FlagProvider config={unleashConfig}>{children}</FlagProvider>;
};
