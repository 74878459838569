import { SESSION_STORAGE_KEYS } from '../constants';

const getTenantId = () => {
  return window.localStorage.isLoggedInWithIdV2
    ? window.sessionStorage.getItem(SESSION_STORAGE_KEYS.V2_TENANT_ID)
    : window.sessionStorage.getItem(SESSION_STORAGE_KEYS.V1_TENANT_ID);
};

const getAccessToken = () => {
  return window.sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCESS_TOKEN);
};

export const SessionUtils = {
  getTenantId,
  getAccessToken,
};
