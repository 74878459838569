export const SUBSCRIPTIONS_REGISTRY = {
  QUERY_KEY: 'subscriptions',
  EMPTY: {
    CONTENT: 'empty-content',
    DOCS_CTA: 'empty-content-docs-cta',
    ADD: 'empty-content-add-subscription-cta',
  },
  LIST: {
    METRICS: 'subscription-list-event-metrics',
    SEARCH: 'subscription-list-searchbar',
    FILTERS: {
      EVENT: 'subscription-list-filters-event',
      STATE: 'subscription-list-filters-state',
    },
    TABLE: 'subscription-list-table',
    CTA: {
      TEST: 'subscription-list-table-cta-test',
      EDIT: 'subscription-list-table-cta-edit',
      DELETE: 'subscription-list-table-cta-delete',
    },
  },
  FORM: {
    SUBSCRIPTION_STATUS: 'subscription-form-status-switch',
    SAVE_BTN: 'subscription-form-save-btn',
    CANCEL_BTN: 'subscription-form-cancel-edit-btn',
    DELETE_BTN: 'subscription-form-delete-btn',
    CANCEL_MODAL: 'subscription-form-cancel-modal',
    DELETE_MODAL: 'subscription-form-delete-modal',
    AFTER_CANCEL: 'subscription-form-after-cancel-toast',
    AFTER_SAVE: {
      SUCCESS: 'save-subscription-success-toast',
      ERROR: 'save-subscription-error-toast',
    },
    SUBSCRIPTION: {
      TYPE: {
        INTEGRATION: 'INTEGRATION'
      }
    },
    AFTER_DELETE: {
      DELETED: 'delete-subscription-success-toast',
      ERROR: 'delete-subscription-error-toast',
    },
    DETAILS: {
      NAME: 'subscription-form-details-name',
      ENDPOINT: 'subscription-form-details-endpoint',
      DESCRIPTION: 'subscription-form-details-description',
    },
    AUTH: {
      TYPE: {
        API_KEY: 'API_KEY',
      },
      API_KEY_HEADER: 'subscription-form-api-key-header',
      API_KEY: 'subscription-form-api-key-input',
    },
    EVENTS: {
      TABS: 'subscription-form-events-domain-tabs',
      SELECT_ALL: 'subscription-form-events-checkboxes-domain-select-all',
      GROUPS: 'subscription-form-events-checkboxes-services',
      EVENT_TYPES: 'subscription-form-events-checkboxes-event-types',
      EVENTS_SEARCH: 'subscription-form-events-searchbar',
      SEARCH_OPTION_PREFFIX: 'subscription-form-events',
    },
  },
  PREVIEW_SCHEMA: {
    DRAWER: 'preview-event-schema-drawer',
  },
  TEST: {
    HEADER: 'test-subscription-page-header',
    EVENTS: 'test-subscription-events-dropdown',
    RUN_BTN: 'test-subscription-run-test-button',
    DETAIL_HEADER_BTN: 'subscription-form-test-webhook-button',
  },
  QUERY_KEYS: {
    SUBSCRIPTIONS: 'subscriptions',
  },
  LOADING_STATE: {
    FILTERS: 'filters-loading-state',
  },
};
