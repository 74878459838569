import { Box, Link, Search, SearchVariant } from 'ds4-beta';
import { REGISTRY } from '../../../registry';
import { DICTIONARY } from '../../../dictionary';
import {
  GenericFilter as SubscriptionsFilter,
  SelectedFilterTags,
} from '../../../common/molecules';
import { Row } from '../../../common/atoms';
import { debounce, isEmpty, isEqual } from 'lodash';
import { SEARCH_DEBOUNCE_TIME } from '../../../constants';
import { DEFAULT_SUBSCRIPTION_FILTERS } from '../../constants';
import { useEffect, useState } from 'react';
import {
  selectEventsFromSelectedTreeNode,
  findNodeById,
} from '../../molecules/events/event-tree.utils';
import { TableCaption } from './table.style';

const SubscriptionsListFilters = ({
  filterSelections,
  filterCheckboxOptions,
  onSearch,
  handleFilterOptionSelect,
  tagSelections,
  handleClearFilters,
  tableCaption,
}) => {
  const [showResetFiltersCTA, setShowResetFiltersCTA] = useState(false);

  useEffect(() => {
    setShowResetFiltersCTA(
      !isEqual(filterSelections, DEFAULT_SUBSCRIPTION_FILTERS)
    );
  }, [filterSelections]);

  const handleSearch = debounce(term => {
    onSearch(term);
  }, SEARCH_DEBOUNCE_TIME);

  const handleClearSearch = () => {
    onSearch(null);
  };

  const handleEventFilterChange = (filterName: string, optionName: string) => {
    if (filterName === DICTIONARY.FILTER_NAMES.EVENT) {
      const selectedNestedFilterOptions = selectEventsFromSelectedTreeNode(
        findNodeById(optionName, filterCheckboxOptions.EVENT_TYPE),
        filterSelections.type
      );
      handleFilterOptionSelect({
        filterName,
        selectedOptions: selectedNestedFilterOptions,
      });
    }
  };

  return (
    <>
      <Box
        padding={{
          top: 3,
          bottom: 3,
        }}
      >
        <Search
          dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.SEARCH}
          placeholder={DICTIONARY.SUBSCRIPTIONS.LIST.PLACEHOLDERS.SEARCH}
          variant={SearchVariant.PAGE}
          showResult={false}
          onSearch={handleSearch}
          onChange={handleSearch}
          onClear={handleClearSearch}
        />
      </Box>
      <Row
        flex={{
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div>
          <Row gap={1}>
            <SubscriptionsFilter
              dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.FILTERS.EVENT}
              label={DICTIONARY.SUBSCRIPTIONS.LIST.FILTER_LABELS.EVENT}
              onFilterReset={() => {
                handleFilterOptionSelect({
                  filterName: DICTIONARY.FILTER_NAMES.EVENT,
                  selectedOptions: [],
                });
              }}
              checkboxOptions={filterCheckboxOptions.EVENT_TYPE}
              onFilterChange={(optionName: string) => {
                handleEventFilterChange(
                  DICTIONARY.FILTER_NAMES.EVENT,
                  optionName
                );
              }}
            />
            {showResetFiltersCTA && (
              <Link
                label={DICTIONARY.GENERAL.BUTTONS.RESET_FILTERS}
                onClick={handleClearFilters}
                mode='inline'
                variant='primary'
              />
            )}
          </Row>
          {!isEmpty(tagSelections) && (
            <Box
              padding={{
                top: 3,
              }}
            >
              <SelectedFilterTags tagList={tagSelections} />
            </Box>
          )}
        </div>
        {<TableCaption>{tableCaption}</TableCaption>}
      </Row>
    </>
  );
};

export default SubscriptionsListFilters;
