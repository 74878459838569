import { useEffect, useState } from 'react';
import { Link, theme } from 'ds4-beta';
import { ElevatedCard, Row, SectionLoading } from '../../../common/atoms';
import { REGISTRY } from '../../../registry';
import {
  useGetEventLogs as useGetSuccessEventLogs,
  useGetEventLogs as useGetAllEventLogs,
} from '../../../delivery-logs/queries';
import { SUCCESS_STATUS_CODES_OPTIONS } from '../../../constants';
import { getDateFilterFromRetentionDays } from '../../../delivery-logs/requests/utils';
import useGetLogRetentionInDays from '../../../common/hooks/useGetLogRetentionInDays';
import { DICTIONARY } from '../../../dictionary';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '../../../routes';
import { formatInteger } from '../../../lib/utils';

const EventsMetrics = () => {
  const logRetentionInDays = useGetLogRetentionInDays();
  const [dateFilter, setDateFilter] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setDateFilter(getDateFilterFromRetentionDays(logRetentionInDays));
  }, [logRetentionInDays]);

  const { query: successLogsQuery, isFetching: isFetchingSuccess } =
    useGetSuccessEventLogs({
      filterSelections: {
        STATUS_CODE: SUCCESS_STATUS_CODES_OPTIONS,
        DATE: dateFilter,
      },
    });
  const { query: allLogsQuery, isFetching: isFetchingAll } = useGetAllEventLogs(
    {}
  );

  const handleDetailsClick = (status: string) => {
    navigate(PAGE_URLS.LOGS.HOME(status));
  };
  return (
    <>
      {isFetchingSuccess || (isFetchingAll && <SectionLoading />)}
      {!isFetchingSuccess && !isFetchingAll && (
        <Row gap={4} dataTestid={REGISTRY.SUBSCRIPTIONS.LIST.METRICS}>
          <ElevatedCard>
            <div style={{ ...theme.typography.h3 }}>
              {formatInteger(successLogsQuery?.count)}
            </div>
            <div style={{ ...theme.typography.body1.medium }}>
              {DICTIONARY.SUBSCRIPTIONS.METRICS.SUCCESSFUL}
            </div>
            <Link
              size='small'
              mode='inline'
              label='Details'
              onClick={() =>
                handleDetailsClick(
                  DICTIONARY.DELIVERY_LOGS.LOGS_DELIVERY_STATUS.SUCCESS
                )
              }
            />
          </ElevatedCard>
          <ElevatedCard>
            <div style={{ ...theme.typography.h3 }}>
              {formatInteger(allLogsQuery?.count - successLogsQuery?.count)}
            </div>
            <div style={{ ...theme.typography.body1.medium }}>
              {DICTIONARY.SUBSCRIPTIONS.METRICS.FAILED}
            </div>
            <Link
              size='small'
              mode='inline'
              label='Details'
              onClick={() =>
                handleDetailsClick(
                  DICTIONARY.DELIVERY_LOGS.LOGS_DELIVERY_STATUS.FAILED
                )
              }
            />
          </ElevatedCard>
        </Row>
      )}
    </>
  );
};

export default EventsMetrics;
