import { StrictMode } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, CopilotUIProvider, theme, BreadcrumbProps } from 'ds4-beta';
import { WebhooksRoutes } from './app/routes';
import { FeatureFlagProvider } from './app/lib/feature-flag';
import { QueryProvider } from './app/lib/query';

declare global {
  interface Window {
    updateBreadcrumb: (data: BreadcrumbProps['data']) => void;
  }
}

const Root = () => {
  return (
    <StrictMode>
      <FeatureFlagProvider>
        <CopilotUIProvider>
          <QueryProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <WebhooksRoutes />
            </ThemeProvider>
          </QueryProvider>
        </CopilotUIProvider>
      </FeatureFlagProvider>
    </StrictMode>
  );
};

export default Root;
