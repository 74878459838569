import { DEFAULT_SUBSCRIPTION_FILTERS } from '../constants';
import { useEffect, useState } from 'react';
import {
  CheckboxFilterOnChangeFn,
  EventMetadataResponse,
} from '../../common/types';
import { DICTIONARY } from '../../dictionary';
import { TagProps } from 'ds4-beta';
import { SubscriptionFilterOptions } from '../types';
import { isEqual, isNull } from 'lodash';
import { parseEventsTree } from '../../subscriptions/molecules/events/event-tree.utils';
import {
  getTagSelectionsForEventTypes,
  removeTagFromEventTypeFilterSelections,
} from '../../delivery-logs/hooks/utils';

const { FILTER_NAMES } = DICTIONARY;

const useManageFilterSelections = (eventNestedFilterOptions, eventTypes) => {
  // ====================== IMPORTANT ======================
  // `filterSelections` is the PARENT STATE VARIABLE
  // filterCheckboxOptions and tagSelections use `filterSelections` as the source of truth
  const [filterSelections, setFilterSelections] =
    useState<SubscriptionFilterOptions>(DEFAULT_SUBSCRIPTION_FILTERS);

  // To manage filters checkbox options
  const [filterCheckboxOptions, setFilterCheckboxOptions] = useState({
    EVENT_TYPE: [],
  });

  // To manage tag selections
  const [tagSelections, setTagSelections] = useState<TagProps[]>([]);

  // To manage a tag that is marked for removal
  const [tagToRemove, setTagToRemove] = useState<string | null>(null);

  // START: Set up the default
  useEffect(() => {
    setFilterCheckboxOptions(prevState => ({
      ...prevState,
      EVENT_TYPE: eventNestedFilterOptions,
    }));
  }, [eventNestedFilterOptions]);
  // END: Set up the default

  useEffect(() => {
    const newFilterCheckboxOptions = { ...filterCheckboxOptions };
    newFilterCheckboxOptions.EVENT_TYPE = parseEventsTree(
      eventTypes,
      filterSelections.type
    );

    setFilterCheckboxOptions(newFilterCheckboxOptions);

    // Update tag selections
    if (isEqual(filterSelections, DEFAULT_SUBSCRIPTION_FILTERS)) {
      setTagSelections([]);
      return;
    }

    let newTagSelections = [];
    const tagSelectionsForEventTypes = getTagSelectionsForEventTypes(
      filterSelections.type,
      newFilterCheckboxOptions.EVENT_TYPE,
      setTagToRemove
    );
    newTagSelections = newTagSelections.concat(tagSelectionsForEventTypes);
    setTagSelections(newTagSelections);
  }, [filterSelections]);

  useEffect(() => {
    if (isNull(tagToRemove)) return;

    ////// Update Filter Options for Event Type //////
    const newFilterSelections = { ...filterSelections };
    newFilterSelections.type = removeTagFromEventTypeFilterSelections(
      tagToRemove,
      newFilterSelections?.type
    );

    setFilterSelections(newFilterSelections);

    // Reset tag to remove
    setTagToRemove(null);
  }, [tagToRemove]);

  const handleClearFilters = () => {
    setFilterSelections(DEFAULT_SUBSCRIPTION_FILTERS);
  };

  const handleFilterOptionSelect = ({
    filterName,
    selectedOptions,
  }: CheckboxFilterOnChangeFn) => {
    const newFilterOptions = { ...filterSelections };
    if (filterName === FILTER_NAMES.EVENT) {
      newFilterOptions.type = selectedOptions as EventMetadataResponse[];
    }
    setFilterSelections(newFilterOptions);
  };

  return {
    filterSelections,
    filterCheckboxOptions,
    tagSelections,
    handleClearFilters,
    handleFilterOptionSelect,
  };
};

export default useManageFilterSelections;
