import { DeliveryLogsFilter, DeliveryLogsDateFilter } from '..';
import { DELIVERY_LOGS_REGISTRY } from '../../registry';
import { DICTIONARY } from '../../../dictionary';
import { DateFilterValue, Link } from 'ds4-beta';
import { LogsFilterCheckboxOptions, LogsFilterOptions } from '../../types';
import { transformSelectedCheckboxOptions } from '../../../common/molecules/filter/utils';
import { EMPTY_TABLE_DATE_FILTER_STATE } from '../../constants';
import { EventMetadataResponse } from '../../../common/types';
import {
  selectEventsFromSelectedTreeNode,
  findNodeById,
} from '../../../subscriptions/molecules/events/event-tree.utils';

const { FILTER_NAMES } = DICTIONARY;
const { FILTER_DATA_TEST_IDS } = DELIVERY_LOGS_REGISTRY;

const DeliveryLogsFilterSection = ({
  dateValue,
  filterCheckboxOptions,
  handleFilterOptionSelect,
  handleDateChange,
  handleClearFilters,
  showResetFiltersCTA,
  filterSelections,
}: {
  dateValue: DateFilterValue;
  filterCheckboxOptions: LogsFilterCheckboxOptions;
  handleDateChange: (dateOption: DateFilterValue) => void;
  handleFilterOptionSelect: ({
    filterName,
    selectedOptions,
  }: {
    filterName: string;
    selectedOptions:
      | string[]
      | { from: Date; to: Date }
      | EventMetadataResponse[];
  }) => void;
  handleClearFilters: () => void;
  showResetFiltersCTA: boolean;
  filterSelections: LogsFilterOptions;
}) => {
  const getCheckboxOptionsFromFilterName = (filterName: string) => {
    let checkboxOptions = [];
    switch (filterName) {
      case FILTER_NAMES.EVENT:
        checkboxOptions = filterCheckboxOptions.EVENT_TYPE;
        break;
      case FILTER_NAMES.STATUS_CODE:
        checkboxOptions = filterCheckboxOptions.STATUS_CODE;
        break;
      case FILTER_NAMES.SUBSCRIPTION_NAME:
        checkboxOptions = filterCheckboxOptions.SUBSCRIPTION_NAME;
        break;
      default:
        break;
    }
    return checkboxOptions;
  };

  const handleFilterChange = (filterName: string, optionName: string) => {
    if (filterName === FILTER_NAMES.EVENT) {
      const selectedNestedFilterOptions = selectEventsFromSelectedTreeNode(
        findNodeById(optionName, filterCheckboxOptions.EVENT_TYPE),
        filterSelections.EVENT_TYPE
      );

      handleFilterOptionSelect({
        filterName,
        selectedOptions: selectedNestedFilterOptions,
      });

      return;
    }

    const checkboxOptions = getCheckboxOptionsFromFilterName(filterName);
    const { selectedFilterOptions } = transformSelectedCheckboxOptions(
      optionName,
      checkboxOptions
    );

    handleFilterOptionSelect({
      filterName,
      selectedOptions: selectedFilterOptions,
    });
  };

  const handleFilterReset = (filterName: string) => {
    handleFilterOptionSelect({
      filterName,
      selectedOptions: [],
    });
  };

  return (
    <>
      <DeliveryLogsDateFilter
        dataTestid={FILTER_DATA_TEST_IDS.DATE}
        label={FILTER_NAMES.DATE}
        onFilterChange={handleDateChange}
        onFilterReset={() => handleDateChange(EMPTY_TABLE_DATE_FILTER_STATE)}
        dateValue={dateValue}
      />
      <DeliveryLogsFilter
        dataTestid={FILTER_DATA_TEST_IDS.EVENT}
        label={FILTER_NAMES.EVENT}
        onFilterReset={() => {
          handleFilterReset(FILTER_NAMES.EVENT);
        }}
        checkboxOptions={[...filterCheckboxOptions.EVENT_TYPE]} // Can revisit this later
        onFilterChange={(optionName: string) => {
          handleFilterChange(FILTER_NAMES.EVENT, optionName);
        }}
      />
      <DeliveryLogsFilter
        dataTestid={FILTER_DATA_TEST_IDS.STATUS_CODE}
        label={FILTER_NAMES.STATUS_CODE}
        onFilterReset={() => {
          handleFilterReset(FILTER_NAMES.STATUS_CODE);
        }}
        checkboxOptions={[...filterCheckboxOptions.STATUS_CODE]}
        onFilterChange={(optionName: string) => {
          handleFilterChange(FILTER_NAMES.STATUS_CODE, optionName);
        }}
      />
      <DeliveryLogsFilter
        dataTestid={FILTER_DATA_TEST_IDS.SUBSCRIPTION_NAME}
        label={FILTER_NAMES.SUBSCRIPTION_NAME}
        onFilterReset={() => {
          handleFilterReset(FILTER_NAMES.SUBSCRIPTION_NAME);
        }}
        checkboxOptions={[...filterCheckboxOptions.SUBSCRIPTION_NAME]}
        onFilterChange={(optionName: string) => {
          handleFilterChange(FILTER_NAMES.SUBSCRIPTION_NAME, optionName);
        }}
      />
      {showResetFiltersCTA && (
        <Link
          label={DICTIONARY.GENERAL.BUTTONS.RESET_FILTERS}
          onClick={handleClearFilters}
          mode='inline'
          variant='primary'
        />
      )}
    </>
  );
};

export default DeliveryLogsFilterSection;
