import { Filter, FilterSidebar, FilterSidebarTreeChild } from 'ds4-beta';
import { DICTIONARY } from '../../../dictionary';
import { useEffect, useState } from 'react';
import { StyledFilterSidebar } from './styles';

const GenericFilter = ({
  dataTestid,
  label,
  onFilterReset,
  checkboxOptions,
  onFilterChange,
  numOfSelectedOptions,
}: {
  dataTestid: string;
  label: string;
  onFilterReset: () => void;
  checkboxOptions: FilterSidebarTreeChild[];
  onFilterChange: (optionName: string) => void;
  numOfSelectedOptions?: number;
}) => {
  const [selectedOptionsTotal, setSelectedOptionalTotal] = useState<number>(
    numOfSelectedOptions ?? 0
  );

  useEffect(() => {
    // If `numOfSelectedOptions` is defined, just use that
    if (numOfSelectedOptions) {
      return;
    }

    // If no `numOfSelectedOptions` is defined, then calculate it
    setSelectedOptionalTotal(
      checkboxOptions.reduce(
        (acc, currValue) => (currValue.selected ? acc + 1 : acc),
        0
      )
    );
  }, [checkboxOptions]);

  const renderFilterOptions = selectedOptionsTotal => (
    <StyledFilterSidebar>
      <FilterSidebar
        isFlat
        maxHeight='300px'
        header=''
        onChange={onFilterChange}
        checkboxTree={checkboxOptions}
        {...(selectedOptionsTotal > 0 && {
          footer: {
            label: '',
            linkText: DICTIONARY.GENERAL.BUTTONS.RESET_ALL,
            onLinkClick: onFilterReset,
            disableLink: selectedOptionsTotal === 0,
          },
        })}
      />
    </StyledFilterSidebar>
  );
  return (
    <Filter
      {...(selectedOptionsTotal > 0 && {
        filterCount: selectedOptionsTotal.toString(),
      })}
      dataTestid={dataTestid}
      variant='primary'
      label={label}
      renderResults={renderFilterOptions(selectedOptionsTotal)}
    />
  );
};

export default GenericFilter;
