import { SkeletonBody1, SkeletonH1, SkeletonPageSearch } from 'ds4-beta';
import VerticalSpace from './space';
import FormSection from './form-section';

const SectionLoading = () => {
  return (
    <FormSection>
      <SkeletonH1 />
      <VerticalSpace />
      <SkeletonPageSearch />
      <VerticalSpace />
      <SkeletonBody1 />
    </FormSection>
  );
};

export default SectionLoading;
