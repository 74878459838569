import { Box, theme } from 'ds4-beta';

const DividerLine = () => (
  <Box
    backgroundColor={theme.color.grey[100]}
    height='1px'
    width='100%'
    margin={{ bottom: 5 }} />
);

export default DividerLine;