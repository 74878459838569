import { isNull } from 'lodash';

export const getFormattedDate = (dateStr: string) => {
  if (!dateStr) return '-';
  const date = new Date(dateStr);

  const formattedDateString = date.toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  // the `toLocateDateString` adds the word 'at' unnecessarily. We remove this here.
  return formattedDateString.replace(' at ', ' ');
};

// Prep a string so that it outputs raw HTML instead of rendering the HTML
export const prepRawHTML = (htmlString: string) => {
  if (isNull(htmlString)) return '';
  let newString = htmlString.replace(/&/g, '&amp;');
  newString = newString.replace(/</g, '&lt;');
  newString = newString.replace(/>/g, '&gt;');
  return newString;
};
