import { EmptyPageProps, Link } from 'ds4-beta';
import { PAGE_URLS } from '../../routes';
import { REGISTRY } from '../../registry';
import { DICTIONARY } from '../../dictionary';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isEqual, isNil } from 'lodash';
import { DEFAULT_SUBSCRIPTION_FILTERS } from '../constants';
import { handleDevPortalClick } from '../../lib/utils';

const {
  SUBSCRIPTIONS: { PAGE_LABELS, BTN_LABELS },
  WEBHOOKS_HEADER,
  GENERAL: {
    SEARCH_RESULTS: { NO_RESULTS_HEADER, NO_RESULTS_DESCRIPTION },
  },
} = DICTIONARY;

const useEmptySubscriptionsTableProps = ({
  subscriptions,
  isFetchingData,
  filters,
}) => {
  const navigate = useNavigate();
  const [emptyTableProps, setEmptyTableProps] = useState<EmptyPageProps>(null);

  const handleCTAClick = () => {
    navigate(PAGE_URLS.SUBSCRIPTIONS.NEW);
  };

  useEffect(() => {
    if (isFetchingData) return;

    // If there are no subscriptions and no filters on, then that means there are no webhooks!
    if (
      isNil(subscriptions) ||
      (isEmpty(subscriptions) && isEqual(filters, DEFAULT_SUBSCRIPTION_FILTERS))
    ) {
      setEmptyTableProps({
        headerText: PAGE_LABELS.NO_SUBSCRIPTIONS,
        description: PAGE_LABELS.SETUP_SUBSCRIPTIONS,
        buttons: [
          {
            onClick: handleCTAClick,
            text: BTN_LABELS.ADD,
            dataTestid: REGISTRY.SUBSCRIPTIONS.EMPTY.ADD,
            variant: 'primary',
          },
        ],
        footerContent: (
          <>
            {WEBHOOKS_HEADER.MORE_INFO_HEADER}
            <Link
              mode='inline'
              label={PAGE_LABELS.DOCS_CTA}
              dataTestid={REGISTRY.SUBSCRIPTIONS.EMPTY.DOCS_CTA}
              onClick={handleDevPortalClick}
            />
          </>
        ),
        dataTestid: REGISTRY.SUBSCRIPTIONS.EMPTY.CONTENT,
      });
      return;
    }

    // If subscriptions are empty but there are filters on, then there are no search results
    if (isEmpty(subscriptions)) {
      setEmptyTableProps({
        headerText: NO_RESULTS_HEADER,
        description: NO_RESULTS_DESCRIPTION,
      });
      return;
    }

    setEmptyTableProps(null);
  }, [subscriptions, isFetchingData]);

  return { emptyTableProps };
};

export default useEmptySubscriptionsTableProps;
