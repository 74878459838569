import { Box, theme } from 'ds4-beta';
import VerticalSpace from './space';

const FormSection = ({ children }) => {
  return (
    <>
      <VerticalSpace />
      <Box
        padding={[4]}
        border={{
          color: theme.color.grey[200],
          radius: 1,
          width: 1,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default FormSection;
