import { SORT_TYPE } from '../constants';
import { DELIVERY_LOGS_DICTIONARY } from './dictionary';

const {
  COLUMN_NAMES: {
    STATUS_CODE,
    EVENT_DATE,
    SUBSCRIPTION_NAME,
    EVENT_TYPE,
    ENDPOINT,
    LOG_TYPE,
  },
} = DELIVERY_LOGS_DICTIONARY;

export const COLUMN_NAMES = [
  STATUS_CODE,
  EVENT_DATE,
  SUBSCRIPTION_NAME,
  ENDPOINT,
  LOG_TYPE,
  EVENT_TYPE,
  ' ', // this is for CTA section
];

export const EMPTY_TABLE_SORT_STATES = {
  STATUS_CODE: SORT_TYPE.NULL,
  EVENT_DATE: SORT_TYPE.NULL,
  SUBSCRIPTION_NAME: SORT_TYPE.NULL,
  ENDPOINT: SORT_TYPE.NULL,
  LOG_TYPE: SORT_TYPE.NULL,
  EVENT_TYPE: SORT_TYPE.NULL,
};

export const EMPTY_TABLE_DATE_FILTER_STATE = {
  value: '',
  dateRange: {
    from: undefined,
    to: undefined,
  },
};
export const EMPTY_TABLE_FILTER_STATES = {
  STATUS_CODE: [],
  SUBSCRIPTION_NAME: [],
  EVENT_TYPE: [],
  DATE: EMPTY_TABLE_DATE_FILTER_STATE,
};

export const LOG_FILTER_TYPES = Object.keys(EMPTY_TABLE_FILTER_STATES);
export const LOGS_SIMPLE_CHECKBOX_FILTERS = [
  'STATUS_CODE',
  'SUBSCRIPTION_NAME',
];

export const DATE_TIME_PICKER_TYPES = {
  DATE: 'date',
  TIME: 'time',
};
