import { Box, Button, Link, Tooltip, theme } from 'ds4-beta';
import { DICTIONARY } from '../../../dictionary';
import {
  CaptionContainer,
  FlexAlignContainer,
  SelectionCenterContainer,
  TableActionContainer,
} from './styles';
import { TableCTABtnProps } from '../../types';

const TableActionsSection = ({
  showTableActions,
  numItemsSelected,
  ctaButtons,
  onClear,
  tableCaptionProps,
}: {
  showTableActions: boolean;
  numItemsSelected: number;
  ctaButtons?: TableCTABtnProps[];
  onClear: () => void;
  tableCaptionProps: {
    start: string | number;
    end: string | number;
    totalRecords: string | number;
  };
}) => {
  const renderCTAButton = (btnProps: TableCTABtnProps) => {
    return btnProps.tooltip 
      ? <Tooltip text={btnProps.tooltip}><Button key={btnProps.dataTestid} {...btnProps} /></Tooltip> 
      : <Button key={btnProps.dataTestid} {...btnProps} />;
  }

  return (
    <Box padding={{ bottom: 2 }}>
      <TableActionContainer>
        <FlexAlignContainer>
          {showTableActions ? (
            <>
              <SelectionCenterContainer>
                <span
                  style={{
                    ...theme.typography.body1.regular,
                    paddingRight: theme.space[2],
                  }}
                >
                  {numItemsSelected}{' '}
                  {DICTIONARY.DELIVERY_LOGS.getItemsSelectedString(
                    numItemsSelected
                  )}
                </span>
                <Link
                  label={DICTIONARY.TABLE_ACTIONS.CLEAR_SELECTION}
                  mode='inline'
                  size='medium'
                  padding={false}
                  onClick={onClear}
                />
              </SelectionCenterContainer>
              <FlexAlignContainer>
                {ctaButtons.map(btnProps => renderCTAButton(btnProps))}
              </FlexAlignContainer>
            </>
          ) : (
            <Box height={theme.space[6]} />
          )}
        </FlexAlignContainer>
        {tableCaptionProps?.end !== 0 && (
          <div>
            <CaptionContainer>
              {DICTIONARY.DELIVERY_LOGS.getTableCaption(
                tableCaptionProps.start,
                tableCaptionProps.end,
                tableCaptionProps.totalRecords ?? '-'
              )}
            </CaptionContainer>
          </div>
        )}
      </TableActionContainer>
    </Box>
  );
};

export default TableActionsSection;
