import { useEffect, useState } from 'react';
import { Link, PageHeader } from 'ds4-beta';
import { useNavigate } from 'react-router-dom';
import { DICTIONARY, TAB_INDEXES } from '../../dictionary';
import { BTN_VARIANTS, EVENT_ADMIN_ROLE_PERMISSIONS } from '../../constants';
import { PAGE_URLS } from '../../routes';
import { REGISTRY } from '../../registry';
import { rbac } from '@teamfabric/copilot-utilities';
import { ReplayEventsModal } from '../../delivery-logs/organisms/';
import { handleDevPortalClick } from '../../lib/utils';
import useGetLogRetentionInDays from '../hooks/useGetLogRetentionInDays';

const HomeHeader = ({
  activeTabIndex,
  shouldShowPrimaryCTA,
}: {
  activeTabIndex: number;
  shouldShowPrimaryCTA: boolean;
}) => {
  const navigate = useNavigate();
  const { hasAccess: hasAdminAccess } = rbac.checkPermission({
    allowedPermissions: EVENT_ADMIN_ROLE_PERMISSIONS,
  });

  const handleSubscriptionsCTAClick = async () => {
    navigate(PAGE_URLS.SUBSCRIPTIONS.NEW);
  };

  const [h1Text, setH1Text] = useState('');
  const [primaryCTAText, setPrimaryCTAText] = useState('');
  const [primaryCTAOnClick, setPrimaryCTAOnClick] = useState(null);
  const [showReplayEventsModal, setShowReplayEventsModal] = useState(false);

  useEffect(() => {
    switch (activeTabIndex) {
      case TAB_INDEXES.SUBSCRIPTIONS: // "Subscriptions"
        setH1Text(DICTIONARY.WEBHOOKS_HEADER.TITLE_HEADER);
        setPrimaryCTAText(DICTIONARY.WEBHOOKS_HEADER.ADD_WEBHOOK_CTA);
        setPrimaryCTAOnClick(() => handleSubscriptionsCTAClick);
        break;
      case TAB_INDEXES.LOGS: // "Logs"
        setH1Text(DICTIONARY.LOGS_HEADER.TITLE_HEADER);
        setPrimaryCTAText(DICTIONARY.LOGS_HEADER.REPLAY_EVENTS_CTA);
        setPrimaryCTAOnClick(() => () => setShowReplayEventsModal(true));
        break;
      default:
        break;
    }
  }, [activeTabIndex]);

  const closeReplayEventsModal = () => {
    setShowReplayEventsModal(false);
  };

  const logRetentionInDays = useGetLogRetentionInDays();

  return (
    <>
      <PageHeader
        h1Text={h1Text}
        h2Text={
          activeTabIndex === TAB_INDEXES.SUBSCRIPTIONS ? (
            <>
              {DICTIONARY.WEBHOOKS_HEADER.MORE_INFO_HEADER}{' '}
              <Link
                mode='inline'
                label={DICTIONARY.WEBHOOKS_HEADER.DEVELOPER_PAGE_CTA}
                onClick={handleDevPortalClick}
                dataTestid={REGISTRY.COMMON.HEADER.MORE_INFO}
                padding={false}
              />
            </>
          ) : (
            <>
              {DICTIONARY.LOGS_HEADER.getMoreInfoHeader(logRetentionInDays)}
              {/* Compensates the link line-height for subscriptions */}
              <div style={{ marginBottom: '2px' }}></div>
            </>
          )
        }
        {...(hasAdminAccess &&
          shouldShowPrimaryCTA && {
            primaryButtons: [
              {
                text: primaryCTAText,
                variant: BTN_VARIANTS.PRIMARY,
                onClick: primaryCTAOnClick,
                dataTestid: REGISTRY.COMMON.HEADER.CTA,
              },
            ],
          })}
        dataTestid={REGISTRY.COMMON.HEADER.TITLE}
      />
      {hasAdminAccess && showReplayEventsModal && (
        <ReplayEventsModal
          confirmModalHandler={() => {
            setShowReplayEventsModal(false);
          }}
          closeModalHandler={closeReplayEventsModal}
        />
      )}
    </>
  );
};

export default HomeHeader;
