import { DICTIONARY } from '../../../dictionary';
import { validateUrl } from '../../../lib/utils';
import FormInput from './input';

const UrlInput = props => {
  return (
    <FormInput
      pattern={DICTIONARY.PATTERNS.URL}
      validateFn={validateUrl}
      {...props}
    />
  );
};

export default UrlInput;
