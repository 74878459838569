import { DateFilterValue, DateType, TagProps } from 'ds4-beta';
import { capitalize, find, groupBy, isNil, remove } from 'lodash';
import { DICTIONARY } from '../../dictionary';
import { EventMetadataResponse } from '../../common/types';
import { EventTreeNode } from '../../subscriptions/molecules/events/tabs.type';

const { DATE_FILTER_LABELS, FILTER_NAMES } = DICTIONARY;

export const makeReadableFilterName = (filterType: string) => {
  return capitalize(filterType.toLowerCase().split('_').join(' '));
};

export const getDatePrimaryLabel = (selectedDateOption: DateFilterValue) => {
  const filterNamePrefix = `${makeReadableFilterName(FILTER_NAMES.DATE)}: `;
  if (selectedDateOption.value === DateType.Today) {
    return `${filterNamePrefix}${DATE_FILTER_LABELS.TODAY}`;
  }

  if (selectedDateOption.value === DateType.CustomDates) {
    return `${filterNamePrefix}${selectedDateOption.dateRange.from.toLocaleDateString()} - ${selectedDateOption.dateRange.to.toLocaleDateString()}`;
  }

  return '';
};

export const getTagSelectionsForEventTypes = (
  eventTypeFilterSelections: EventMetadataResponse[],
  eventTypeCheckboxOptions: EventTreeNode[],
  setTagToRemove: (id: string) => void
): TagProps[] => {
  const result: TagProps[] = [];

  const checkboxSelectionsByProductGroup = groupBy(
    eventTypeFilterSelections,
    'product'
  );
  const selectedProducts = Object.keys(checkboxSelectionsByProductGroup);

  selectedProducts.forEach(productName => {
    const checkboxOptionGroup = find(
      eventTypeCheckboxOptions,
      (optionGroup: EventTreeNode) => optionGroup.id === productName
    );

    // If all children are selected, count this tag as one big event type category (by product)
    if (
      checkboxOptionGroup.selected &&
      checkboxOptionGroup.childrenCount ===
        checkboxOptionGroup.selectedChildrenCount
    ) {
      const tagId = `EVENT_TYPE--${checkboxOptionGroup.id}--all`;
      result.push({
        id: tagId,
        primaryLabel: `${FILTER_NAMES.EVENT}: ${checkboxOptionGroup.label}`,
        isRemovable: true,
        onRemove: () => setTagToRemove(tagId),
      });
    } else {
      checkboxOptionGroup.children.forEach((child: EventTreeNode) => {
        // If all children are selected, count this as one big event type category (by service)
        if (
          child.selected &&
          child.childrenCount === child.selectedChildrenCount
        ) {
          const tagId = `EVENT_TYPE--${checkboxOptionGroup.id}--${child.id}--all`;
          result.push({
            id: tagId,
            primaryLabel: `${FILTER_NAMES.EVENT}: ${checkboxOptionGroup.label} / ${child.id}`,
            isRemovable: true,
            onRemove: () => setTagToRemove(tagId),
          });
        } else if (child.selected) {
          // If only some of the children are selected, then write out those tags individually
          child.children.forEach((grandChild: EventTreeNode) => {
            if (grandChild.selected) {
              const tagId = `EVENT_TYPE--${checkboxOptionGroup.id}--${child.id}--${grandChild.label}`;
              result.push({
                id: tagId,
                primaryLabel: `${FILTER_NAMES.EVENT}: ${checkboxOptionGroup.label} / ${child.id} / ${grandChild.label}`,
                isRemovable: true,
                onRemove: () => setTagToRemove(tagId),
              });
            }
          });
        }
      });
    }
  });

  return result;
};

export const removeTagFromEventTypeFilterSelections = (
  tagToRemove: string,
  prevEventTypeFilterSelections: EventMetadataResponse[]
): EventMetadataResponse[] => {
  let newEventSelections: EventMetadataResponse[] = [
    ...prevEventTypeFilterSelections,
  ];

  // `tagToRemove` can look like so:
  // tagToRemove = "EVENT_TYPE--offers--all" OR
  // tagToRemove = "EVENT_TYPE--offers--Jobs--all" OR
  // tagToRemove = "EVENT_TYPE--offers--Jobs--offers:exported:completed"

  const [_filterType, product, service, specificEventType] =
    tagToRemove.split('--');
  if (service === 'all') {
    // That means that we should remove every filter with this product
    newEventSelections = remove(
      newEventSelections,
      (option: EventMetadataResponse) => option.product !== product
    );
  } else if (specificEventType === 'all') {
    // That means that we should remove every filter with this service
    newEventSelections = remove(
      newEventSelections,
      (option: EventMetadataResponse) => option.service !== service
    );
  } else if (!isNil(specificEventType)) {
    // That means that we should remove every filter with this specific event type
    newEventSelections = remove(
      newEventSelections,
      (option: EventMetadataResponse) => option.type !== specificEventType
    );
  }

  return newEventSelections;
};
