import { AxiosResponse } from 'axios';
import { API_PATHS, PAGINATION_DETAILS } from '../constants';
import { client } from '../lib/request';
import { SaveSubscriptionRequest, UpdateSubscriptionRequest } from './types';
import { isNull, join } from 'lodash';

let pagination = {
  limit: PAGINATION_DETAILS.PER_PAGE + 1, // Add one more item to toggle table pagination
  offset: 0,
};

const getSearchTermQueryParam = searchTerm => {
  if (!searchTerm) {
    return {};
  }

  return { searchTerm };
};

const getEventTypeQueryParams = eventTypes => {
  if (eventTypes?.length === 0) {
    return {};
  }

  return { type: join(eventTypes, ',') };
};

const getPaginationParams = (page, limit) => {
  const newPagination = { ...pagination };
  newPagination.offset = (page - 1) * PAGINATION_DETAILS.PER_PAGE;
  if (!isNull(limit)) {
    newPagination.limit = limit;
  }
  return newPagination;
};

const getSortingParams = sort => {
  if (!sort) return {};

  return {
    sort,
  };
};

const getSubscriptions = async ({
  page = 1,
  sort = null,
  searchTerm = null,
  eventTypes = [],
  limit = null,
}) => {
  const { data } = await client.get(API_PATHS.SUBSCRIPTIONS, {
    params: {
      ...getSearchTermQueryParam(searchTerm),
      ...getEventTypeQueryParams(eventTypes),
      ...getPaginationParams(page, limit),
      ...getSortingParams(sort),
    },
  });
  return data;
};

const getSubscription = async (id: string) => {
  const { data } = await client.get(`${API_PATHS.SUBSCRIPTIONS}/${id}`);
  return data;
};

const saveSubscription = async (subscription: SaveSubscriptionRequest) => {
  const { data } = await client.post(API_PATHS.SUBSCRIPTIONS, subscription);
  return data;
};

const updateSubscription = async (subscription: UpdateSubscriptionRequest) => {
  const { data } = await client.put(
    `${API_PATHS.SUBSCRIPTIONS}/${subscription.id}`,
    subscription
  );

  return data;
};

const deleteSubscription = async (id: string) => {
  const { data } = await client.delete(`${API_PATHS.SUBSCRIPTIONS}/${id}`);
  return data;
};

const testSubscription = async ({
  subscriptionId,
  type,
  product,
  version,
}): Promise<AxiosResponse> => {
  // We need the raw Axios response here to retrieve relevant information
  const data = await client.post(
    `${API_PATHS.SUBSCRIPTIONS}/${subscriptionId}/actions/test`,
    { type, product, version }
  );

  return data;
};

export const SubscriptionsAPI = {
  getSubscriptions,
  getSubscription,
  saveSubscription,
  updateSubscription,
  deleteSubscription,
  testSubscription,
};
