import { Input, InputCompProps } from 'ds4-beta';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFields } from './form.type';
import { DICTIONARY } from '../../../dictionary';
import { isFunction } from 'lodash';

export type FormInputType = FormFields & InputCompProps;

const FormInput = ({
  name,
  alias,
  required,
  inputProps,
  validateFn,
  maxLength,
  pattern,
  ...props
}: FormInputType) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      rules={{
        required: {
          value: required,
          message: DICTIONARY.ERROR_MESSAGES.REQUIRED(alias ?? name),
        },
        ...(maxLength
          ? {
              maxLength: {
                value: maxLength,
                message: DICTIONARY.ERROR_MESSAGES.MAX_LENGTH(maxLength),
              },
            }
          : {}),
        ...(isFunction(validateFn)
          ? {
              validate: value =>
                validateFn(value) ||
                DICTIONARY.ERROR_MESSAGES.PATTERN(alias || name, pattern),
            }
          : {}),
      }}
      name={name}
      render={({ field, fieldState }) => (
        <Input
          {...props}
          messageType={fieldState.invalid ? 'error' : 'normal'}
          message={fieldState.invalid && fieldState.error.message}
          inputProps={{
            ...field,
            ...inputProps,
          }}
          required={required}
        />
      )}
    />
  );
};

export default FormInput;
