import { FilterSidebarTreeChild } from 'ds4-beta';

export const transformSelectedCheckboxOptions = (
  optionName: string,
  options: FilterSidebarTreeChild[]
) => {
  const result: FilterSidebarTreeChild[] = [];
  const selectedFilterOptions: string[] = [];

  options.forEach(option => {
    if (option.label === optionName) {
      result.push({
        ...option,
        selected: !option.selected,
      });
    } else {
      result.push(option);
    }
  });
  const numOfSelectedOptions = result.reduce((acc, currentValue) => {
    if (currentValue.selected) {
      acc++;
      selectedFilterOptions.push(currentValue.label);
    }

    return acc;
  }, 0);

  return {
    checkboxStateResult: result,
    numOfSelectedOptions,
    selectedFilterOptions,
  };
};

export const resetCheckboxSelections = (
  options: FilterSidebarTreeChild[]
): FilterSidebarTreeChild[] => {
  const result: FilterSidebarTreeChild[] = [];

  options.forEach(option => {
    result.push({
      ...option,
      selected: false,
    });
  });

  return result;
};
