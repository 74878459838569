import { useState, useEffect } from 'react';
import { FilterSidebarTreeChild } from 'ds4-beta';
import { EventMetadataResponse } from '../../common/types';
import { SubscriptionsResponseData } from '../types';
import { STATUS_CODES_OPTIONS } from '../../constants';
import { parseEventsTree } from '../../subscriptions/molecules/events/event-tree.utils';

const useGetFilterData = ({
  eventsData,
  isFetchingEventsData,
  subscriptionsData,
  isFetchingSubscriptionsData,
}: {
  eventsData: EventMetadataResponse[];
  isFetchingEventsData: boolean;
  subscriptionsData: SubscriptionsResponseData[];
  isFetchingSubscriptionsData: boolean;
}) => {
  const [eventNestedFilterOptions, setNestedEventFilterOptions] = useState<
    FilterSidebarTreeChild[]
  >([]);
  const [statusFilterOptions, setStatusFilterOptions] = useState<
    FilterSidebarTreeChild[]
  >([]);
  const [subscriptionFilterOptions, setSubscriptionFilterOptions] = useState<
    FilterSidebarTreeChild[]
  >([]);

  useEffect(() => {
    // Set Event Filter options
    if (isFetchingEventsData || !eventsData) return;
    setNestedEventFilterOptions(parseEventsTree(eventsData, []));
  }, [isFetchingEventsData]);

  useEffect(() => {
    // Set Subscription Filter options
    if (isFetchingSubscriptionsData || !subscriptionsData) return;
    const subscriptionFilterCheckboxOptions: FilterSidebarTreeChild[] = [];
    subscriptionsData.forEach(({ name }) => {
      subscriptionFilterCheckboxOptions.push({
        label: name,
        expanded: false,
        id: name,
        children: [],
        selected: false,
      });
    });

    setSubscriptionFilterOptions(subscriptionFilterCheckboxOptions);
  }, [isFetchingSubscriptionsData]);

  useEffect(() => {
    // Set Status Filter options
    const statusFilterCheckboxOptions: FilterSidebarTreeChild[] = [];
    STATUS_CODES_OPTIONS.forEach(status => {
      statusFilterCheckboxOptions.push({
        label: status.toString(),
        expanded: false,
        id: status.toString(),
        children: [],
        selected: false,
      });
    });

    setStatusFilterOptions(statusFilterCheckboxOptions);
  }, []);

  return [
    eventNestedFilterOptions,
    statusFilterOptions,
    subscriptionFilterOptions,
  ];
};

export default useGetFilterData;
