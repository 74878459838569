import {
  Box,
  DateRangePicker,
  DropdownOptionProps,
  InlineBanner,
  Modal,
  TimeRangePicker,
  useToast,
} from 'ds4-beta';
import { DICTIONARY } from '../../../dictionary';
import {
  StyledDateTimePickerContainer,
  StyledDescriptionContainer,
} from './styles';
import { useEffect, useState } from 'react';
import { DATE_TIME_PICKER_TYPES } from '../../constants';
import { isNil } from 'lodash';
import { REGISTRY } from '../../../registry';
import SubscriptionDropdown from '../../molecules/subscriptionDropdown';
import { useGetEventLogs, useReplayEvents } from '../../queries';
import { AxiosError, HttpStatusCode } from 'axios';
import { DateTimePickerProps } from '../../../common/types';
import { getDateFilterBoundaryDates } from '../../../lib/utils';
import useGetLogRetentionInDays from '../../../common/hooks/useGetLogRetentionInDays';

const { REPLAY_EVENTS_MODAL, TOAST_MESSAGES } = DICTIONARY.DELIVERY_LOGS;
const {
  DELIVERY_LOGS: { DATE_TIME_PICKERS, REPLAY_EVENTS_MODAL_TEST_IDS },
} = REGISTRY;

const ReplayEventsModal = ({
  confirmModalHandler,
  closeModalHandler,
}: {
  confirmModalHandler: () => void;
  closeModalHandler: () => void;
}) => {
  const showToast = useToast();

  const [dateSelection, setDateSelection] = useState<DateTimePickerProps>({
    startDate: null,
    endDate: null,
  });
  const [timeSelection, setTimeSelection] = useState<DateTimePickerProps>({
    startTime: null,
    endTime: null,
  });
  const [primaryCTAEnabled, setPrimaryCTAEnabled] = useState(false);
  const [primaryCTALoading, setPrimaryCTALoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<DropdownOptionProps>(null);

  const logRetentionInDays = useGetLogRetentionInDays();
  const { today: todaysDate, xDaysAgoDate } =
    getDateFilterBoundaryDates(logRetentionInDays);

  const {
    save: replayEvents,
    isLoading: isSendingReplayEventsRequest,
    isSuccess: isReplayEventsSuccess,
  } = useReplayEvents();
  const { refetch: refetchEventLogs } = useGetEventLogs({
    logRetentionInDays,
  });

  const handleSelection = (type, start, end) => {
    if (type === DATE_TIME_PICKER_TYPES.DATE) {
      setDateSelection({
        startDate: start,
        endDate: end,
      });
    } else if (type === DATE_TIME_PICKER_TYPES.TIME) {
      setTimeSelection({
        startTime: start,
        endTime: end,
      });
    }
  };

  const showErrorToast = (e: AxiosError) => {
    console.error(
      `Error replaying events for subscription id ${selectedSubscription?.id}`
    );

    switch (e?.response.status) {
      case HttpStatusCode.Conflict:
        showToast({
          label: TOAST_MESSAGES.REPLAY_IN_PROGRESS,
          variant: 'alert',
          id: REPLAY_EVENTS_MODAL_TEST_IDS.FAILURE,
        });
        setPrimaryCTALoading(false);
        return;
      case HttpStatusCode.BadRequest:
        showToast({
          label: TOAST_MESSAGES.TIME_WINDOW_OLDER_THAN_A_WEEK,
          variant: 'error',
          id: REPLAY_EVENTS_MODAL_TEST_IDS.FAILURE,
        });
        setPrimaryCTALoading(false);
        return;
      default:
        break;
    }

    showToast({
      label: TOAST_MESSAGES.FAILED_TO_REPLAY,
      variant: 'error',
      id: REPLAY_EVENTS_MODAL_TEST_IDS.FAILURE,
    });
    setPrimaryCTALoading(false);
  };

  const handleReplayEvents = async () => {
    setPrimaryCTALoading(true);
    replayEvents({
      subscriptionId: selectedSubscription.id,
      dateSelection,
      timeSelection,
    }).catch((e: AxiosError) => {
      showErrorToast(e);
    });
  };

  useEffect(() => {
    if (isSendingReplayEventsRequest) {
      return;
    }
    if (isReplayEventsSuccess) {
      setPrimaryCTALoading(false);
      confirmModalHandler();

      showToast({
        label: TOAST_MESSAGES.QUEUED_TO_REPLAY,
        variant: 'default',
        isDismissable: false,
        id: REPLAY_EVENTS_MODAL_TEST_IDS.SUCCESS,
        actionButton: {
          text: TOAST_MESSAGES.REFRESH_PAGE,
          onClick: () => {
            refetchEventLogs();
          },
        },
      });
    }
  }, [isSendingReplayEventsRequest, isReplayEventsSuccess]);

  useEffect(() => {
    setPrimaryCTAEnabled(
      !isNil(dateSelection.startDate) &&
        !isNil(dateSelection.endDate) &&
        !isNil(timeSelection.startTime) &&
        !isNil(timeSelection.endTime) &&
        !isNil(selectedSubscription)
    );
  }, [dateSelection, timeSelection]);

  return (
    <Modal
      dataTestid={REPLAY_EVENTS_MODAL_TEST_IDS.MODAL}
      size='small'
      headerText={REPLAY_EVENTS_MODAL.TITLE}
      isVisible
      onClose={closeModalHandler}
      onBackdropClick={closeModalHandler}
      footerButtons={[
        {
          text: REPLAY_EVENTS_MODAL.CANCEL_BTN,
          variant: 'secondary',
          onClick: closeModalHandler,
        },
        {
          text: REPLAY_EVENTS_MODAL.CONFIRM_BTN,
          variant: 'primary',
          isLoading: primaryCTALoading,
          isDisabled: !primaryCTAEnabled,
          onClick: () => {
            handleReplayEvents();
          },
        },
      ]}
    >
      <InlineBanner label={REPLAY_EVENTS_MODAL.INFO_BANNER} />
      <StyledDescriptionContainer>
        {REPLAY_EVENTS_MODAL.SUBSCRIPTION_NAME_DESCRIPTION}
      </StyledDescriptionContainer>
      <Box padding={{ top: 4 }}>
        <SubscriptionDropdown
          value={selectedSubscription}
          label={REPLAY_EVENTS_MODAL.SUBSCRIPTION_NAME}
          onChange={setSelectedSubscription}
          dataTestid={REPLAY_EVENTS_MODAL_TEST_IDS.SUBSCRIPTION_NAME_DROPDOWN}
        />
      </Box>
      <StyledDescriptionContainer>
        {REPLAY_EVENTS_MODAL.DATE_TIME_DESCRIPTION}
      </StyledDescriptionContainer>
      <StyledDateTimePickerContainer>
        <span>{REPLAY_EVENTS_MODAL.SELECT_DATES}</span>
        <DateRangePicker
          dataTestid={DATE_TIME_PICKERS.DATE_PICKER}
          minDate={xDaysAgoDate}
          maxDate={todaysDate}
          onSelect={(start, end) =>
            handleSelection(DATE_TIME_PICKER_TYPES.DATE, start, end)
          }
        />
      </StyledDateTimePickerContainer>
      <StyledDateTimePickerContainer>
        <span>{REPLAY_EVENTS_MODAL.SELECT_TIME}</span>
        <TimeRangePicker
          dataTestid={DATE_TIME_PICKERS.TIME_PICKER}
          onSelect={(start, end) =>
            handleSelection(DATE_TIME_PICKER_TYPES.TIME, start, end)
          }
        />
      </StyledDateTimePickerContainer>
    </Modal>
  );
};

export default ReplayEventsModal;
