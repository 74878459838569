import styled from 'styled-components';
import { theme } from 'ds4-beta';

export const StyledDateTimePickerContainer = styled.div`
  padding-top: ${theme.space[4]};
  span {
    ${theme.typography.fieldLabel}
  }
`;

export const StyledDescriptionContainer = styled.div`
  padding-top: ${theme.space[4]};
  ${theme.typography.body1.medium}
`;
