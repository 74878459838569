import { theme } from 'ds4-beta';
import styled from 'styled-components';

export const StyledEventCheckBoxes = styled.div`
  ${theme.typography.body1.regular}
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: ${theme.space[5]};
  &:not(:last-child) {
    margin-bottom: ${theme.space[4]};
  }

  span:nth-child(2) {
    ${theme.typography.body1.regular}
    color: ${theme.color.grey[600]};
  }
`;

export const StyledHighlightedCheckbox = styled.div<{ isHighlighted: boolean }>`
  ${({ isHighlighted }) =>
    isHighlighted
      ? `
    label span {
      background-color: ${theme.color.yellow[100]};
    }
  `
      : ''}
`;
