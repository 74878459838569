import { EmptyPage } from 'ds4-beta';
import { PageType } from '../types';
import { DICTIONARY } from '../../dictionary';
const {
  GENERAL: { NOT_ACCESSIBLE },
} = DICTIONARY;

const PageNotAccessible = (_: PageType) => {
  return (
    <EmptyPage
      headerText={NOT_ACCESSIBLE.HEADER_TEXT}
      description={NOT_ACCESSIBLE.DESCRIPTION}
    />
  );
};

export default PageNotAccessible;
