import { useState } from 'react';
const useManageSideDrawer = ({ eventLogsData }) => {
  const [selectedLogForSideDrawer, setSelectedLogForSideDrawer] =
    useState(null);

  const handleOpenDrawer = (id: string) => {
    const selectedLog = eventLogsData.find(eventLog => eventLog.id === id);
    setSelectedLogForSideDrawer(selectedLog);
  };

  const handleCloseDrawer = () => {
    setSelectedLogForSideDrawer(null);
  };

  return { selectedLogForSideDrawer, handleOpenDrawer, handleCloseDrawer };
};

export default useManageSideDrawer;
