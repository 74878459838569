import { TABLE_COL_WIDTH } from '../constants';
import { formatInteger } from '../lib/utils';
export const DELIVERY_LOGS_DICTIONARY = {
  RESEND_EVENTS: 'Resend events',
  COLUMN_NAMES: {
    STATUS_CODE: 'Status code',
    EVENT_DATE: 'Event date',
    SUBSCRIPTION_NAME: 'Subscription name',
    ENDPOINT: 'Endpoint',
    LOG_TYPE: 'Log type',
    EVENT_TYPE: 'Event type',
    SUBSCRIPTION_ID: 'Subscription Id',
    EVENT_ID: 'Event Id',
  },
  TOAST_MESSAGES: {
    QUEUED_TO_REPLAY:
      'Events queued to replay. Please refresh page to see status',
    QUEUED_TO_RESEND:
      'Event queued to resend. Please refresh page to see status',
    getQueuedToResend: (num: number) =>
      `${num} Events queued to resend. Please refresh page to see status`,
    FAILED_TO_RESEND: 'Event failed to resend. Please try again',
    FAILED_TO_REPLAY: 'Events failed to replay. Please try again',
    REFRESH_PAGE: 'Refresh',
    REPLAY_IN_PROGRESS:
      'Replay request is already in progress. Please refresh page to see status.',
    TIME_WINDOW_OLDER_THAN_A_WEEK:
      'Time window requested is older than a week. Please try again with a more recent time range.',
  },
  DEFAULT_LOG_TYPE_VALUE: 'LIVE',
  TEST_LOG_TYPE_VALUE: 'TEST',
  RESEND_EVENTS_MODAL: {
    SINGLE_EVENT_TITLE: 'Are you sure you want to resend this event?',
    getMultipleEventsTitle: (len: number) =>
      `Are you sure you want to resend ${len} events?`,
    DESCRIPTION:
      'Resending events can impact your data and downstream processes.',
    CANCEL_BTN: 'Cancel',
    CONFIRM_BTN: 'Yes, resend event',
  },
  REPLAY_EVENTS_MODAL: {
    TITLE: 'Replay events',
    INFO_BANNER:
      'Doing this will replay all of the events for that subscription',
    DATE_TIME_DESCRIPTION: 'Select the dates and times to replay your events',
    SUBSCRIPTION_NAME_DESCRIPTION:
      "Select the subscription whose events you'd like to replay",
    SELECT_DATES: 'Select dates',
    SELECT_TIME: 'Select time',
    SUBSCRIPTION_NAME: 'Subscription name',
    CANCEL_BTN: 'Cancel',
    CONFIRM_BTN: 'Replay events',
  },
  TOOLTIP_LABELS: {
    OPEN_DRAWER: 'Event details',
    RESEND: 'Resend event',
  },
  NETWORK_ERROR_TEXT: {
    HEADER: 'Network error',
    DESCRIPTION:
      'There is an error connecting to our service. Please reach out to your customer service representative.',
  },
  NO_LOGS_EXIST_TEXT: {
    HEADER: 'No logs available',
    DESCRIPTION:
      'No webhooks have been triggered. Once they do, the logs for each event will appear here.',
  },
  DRAWER_LABELS: {
    TITLE: 'Event information',
    HEADER_BUTTONS: {
      RESEND: 'Resend',
      CLOSE: 'Close',
    },
    GRID: {
      STATUS_CODE: 'Status code',
      EVENT: 'Event',
      SUBSCRIPTION_NAME: 'Subscription name',
      ENDPOINT: 'URL Endpoint',
      ATTEMPTS: 'Attempts',
      EVENT_VERSION: 'Event version',
      TRIGGERED_AT: 'Triggered at',
      DELIVERED_AT: 'Delivered at',
    },
    REQUEST_DETAILS: 'Request details',
    RESPONSE_DETAILS: 'Response details',
  },
  LIST: {
    COLUMNS: [
      { title: 'Status code', width: TABLE_COL_WIDTH.SM, sortable: true },
      { title: 'Event date', width: TABLE_COL_WIDTH.MD, sortable: true },
      { title: 'Subscription name', width: TABLE_COL_WIDTH.MD, sortable: true },
      { title: 'Endpoint', width: TABLE_COL_WIDTH.LG, sortable: true },
      { title: 'Log type', width: TABLE_COL_WIDTH.SM, sortable: true },
      { title: 'Event type', width: TABLE_COL_WIDTH.LG, sortable: true },
      { title: ' ', width: TABLE_COL_WIDTH.XS },
    ],
  },
  WARNINGS: {
    RESEND_DELETED_SUBSCRIPTION_LOG: 'Events assigned to a deleted subscription cannot be resent.',
    BULK_RESEND_DELETED_SUBSCRIPTION_LOG: 'Events assigned to a deleted subscription cannot be resent.',
    RESEND_TEST_EVENT: 'Resend is not allowed for test events.',
    BULK_RESEND_TEST_EVENT: 'One or more selected events are test events and cannot be resent.'
  },
  LOGS_DELIVERY_STATUS: {
    SUCCESS: 'success',
    FAILED: 'failed',
  },
  OMS_LOG_DELIVERY_STATUS: {
    PENDING: 'PENDING',
  },
  getItemsSelectedString: numItemsSelected =>
    `item${numItemsSelected > 1 ? 's' : ''} selected`,
  getTableCaption: (start, end, totalRecords) =>
    `Showing ${start}-${end} of ${formatInteger(totalRecords)} log${
      totalRecords > 1 ? 's' : ''
    }`,
};
