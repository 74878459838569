import { useEffect, useState } from "react"
import { useGetSubscriptions } from "../../subscriptions/queries";
import { PickAndOptional } from "../../lib/utils";
import { EventLogResponseData } from "../types";
import { DICTIONARY } from "../../dictionary";
import { keyBy } from "lodash";

type PartialDeliveryLogType = PickAndOptional<EventLogResponseData, 'id' | 'type' | 'subscriptionId'>;

export const useAllowResend = (selectedLogs: PartialDeliveryLogType[]) => {
  const [hasTestDeliveryLog, setHasTestDeliveryLog] = useState(false);
  const [hasDeletedSubscription, setHasDeletedSubscription] = useState(false);
  const [validSubscriptionsMap, setValidSubscriptionsMap] = useState(null);

  const { data: subscriptions } = useGetSubscriptions({});

  useEffect(() => {
    if (!subscriptions) { return; }

    setValidSubscriptionsMap(keyBy(subscriptions, 'id'));

  }, [subscriptions]);

  useEffect(() => {
    if (!selectedLogs || !validSubscriptionsMap) { return; }

    selectedLogs.every(selectedLog => {
      // Validate test log
      if (selectedLog.type === DICTIONARY.DELIVERY_LOGS.TEST_LOG_TYPE_VALUE) {
        setHasTestDeliveryLog(true);
        return false;
      }

      setHasTestDeliveryLog(false);
      
      // Validate deleted subscription
      if (!validSubscriptionsMap[selectedLog.subscriptionId]) {
        setHasDeletedSubscription(true);
        return false;
      }

      setHasDeletedSubscription(false);
      return true;
    });
  }, [validSubscriptionsMap, selectedLogs]);

  return {
    hasTestDeliveryLog,
    hasDeletedSubscription,
    validSubscriptionsMap,
  }
}