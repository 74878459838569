import { Dropdown, theme } from 'ds4-beta';
import { DICTIONARY } from '../../dictionary';
import { useEffect, useState } from 'react';
import { EventMetadataResponse } from '../../common/types';
import { REGISTRY } from '../../registry';
import { VerticalSpace } from '../../common/atoms';
import { getSubscriptionEvent } from '../../lib/utils';

const SelectTestEvent = ({ events, onSelect }) => {
  const [eventOptions, setEventOptions] = useState([]);

  useEffect(() => {
    // When there is only one event, pre-select it
    if (events.length === 1) {
      onSelect(events[0]);
    }

    setEventOptions(
      events.map((event: EventMetadataResponse) => ({
        id: event.type,
        name: event.type,
      }))
    );
  }, [events]);

  const handleChange = option => {
    onSelect(getSubscriptionEvent(events, option.id));
  };

  return (
    <>
      {events.length <= 1 && (
        <>
          <div style={theme.typography.fieldLabel}>
            {DICTIONARY.SUBSCRIPTIONS.TEST.EVENTS}
          </div>
          <VerticalSpace size={1} />
          <div style={theme.typography.body1.regular}>{events[0].type}</div>
          <VerticalSpace size={2} />
        </>
      )}
      {events.length > 1 && (
        <Dropdown
          variant='large'
          isRequired={true}
          placeholder={DICTIONARY.SUBSCRIPTIONS.TEST.EVENTS_PLACEHOLDER}
          label={DICTIONARY.SUBSCRIPTIONS.TEST.EVENTS}
          options={eventOptions}
          onChange={handleChange}
          dataTestid={REGISTRY.SUBSCRIPTIONS.TEST.EVENTS}
        />
      )}
    </>
  );
};

export default SelectTestEvent;
