import { InputCompProps, Textarea } from 'ds4-beta';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFields } from './form.type';
import { DICTIONARY } from '../../../dictionary';

export type FormInputType = FormFields & InputCompProps;

const FormTextarea = ({
  name,
  required,
  inputProps,
  ...props
}: FormInputType) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        required: {
          value: required,
          message: DICTIONARY.ERROR_MESSAGES.REQUIRED(name),
        },
      }}
      name={name}
      render={({ field }) => (
        <Textarea
          {...props}
          inputProps={{
            ...inputProps,
            ...field,
          }}
        />
      )}
    />
  );
};

export default FormTextarea;
