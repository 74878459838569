import { Box, Tooltip, InteractiveIcon, theme } from 'ds4-beta';

const TableCTAIcon = ({
  tooltipText,
  iconName,
  onClickHandler,
  dataTestid,
  size = 16,
}: {
  tooltipText: string;
  iconName: string;
  onClickHandler: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dataTestid?: string;
  size?: number;
}) => (
  <Box
    padding={{
      right: 2,
    }}
  >
    <div data-testid={dataTestid}>
      <Tooltip text={tooltipText} showArrow placement='top'>
        <InteractiveIcon
          onClick={onClickHandler}
          iconName={iconName}
          color={theme.color.grey[1000]}
          size={size}
        />
      </Tooltip>
    </div>
  </Box>
);

export default TableCTAIcon;
