import { EventMetadataResponse } from '../common/types';
import { SORT_TYPE } from '../constants';
import { DateFilterValue, FilterSidebarTreeChild } from 'ds4-beta';
import { EventTreeNode } from '../subscriptions/molecules/events/tabs.type';

export enum LogsFilterEnum {
  STATUS_CODE = 'STATUS_CODE',
  SUBSCRIPTION_NAME = 'SUBSCRIPTION_NAME',
  EVENT_TYPE = 'EVENT_TYPE',
  DATE = 'DATE',
}

export type LogsFilterOptions = {
  [LogsFilterEnum.STATUS_CODE]?: string[];
  [LogsFilterEnum.SUBSCRIPTION_NAME]?: string[];
  [LogsFilterEnum.EVENT_TYPE]?: EventMetadataResponse[];
  [LogsFilterEnum.DATE]?: DateFilterValue;
};

export type LogsFilterCheckboxOptions = {
  [LogsFilterEnum.EVENT_TYPE]: EventTreeNode[];
  [LogsFilterEnum.STATUS_CODE]: FilterSidebarTreeChild[];
  [LogsFilterEnum.SUBSCRIPTION_NAME]: FilterSidebarTreeChild[];
};

export type EventLogsQueryParams = {
  offset?: number;
  sortOptions?: {
    EVENT_DATE: SORT_TYPE;
    SUBSCRIPTION_NAME: SORT_TYPE;
    ENDPOINT: SORT_TYPE;
    LOG_TYPE: SORT_TYPE;
    EVENT_TYPE: SORT_TYPE;
    STATUS_CODE: SORT_TYPE;
  };
  searchTerm?: string;
  filterSelections?: LogsFilterOptions;
  logRetentionInDays?: number;
};

export type EventLogResponseData = {
  data: any;
  status: number;
  eventType: string;
  endpoint: string;
  eventId: string;
  id: string;
  logs: LogDetailsResponseData[];
  triggeredAt: string;
  version: string;
  type: string;
  subscriptionName: string;
  subscriptionId: string;
  deliveredAt: string;
};

export type LogDetailsResponseData = {
  id: string;
  createdAt: string;
  deliveryStatus?: string;
  message: string;
  status: number;
}

export type SubscriptionsResponseData = {
  createdAt: string;
  deliveryType: string;
  events: EventMetadataResponse[];
  id: string;
  name: string;
  targetUrl: string;
  updatedAt: string;
};

export type ResendEventsRequest = {
  subscriptionId: string;
  eventIds: string[];
};

export enum ExtendedDateType {
  DefaultDate = "defaultDate"
}
