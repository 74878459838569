import { useQuery } from 'react-query';
import { EventMetadataAPI } from './requests';
import { FEATURE_FLAGS, REGISTRY } from '../registry';
import { useFlag } from '@unleash/proxy-client-react';

export const useGetEventTypes = () => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [REGISTRY.EVENTS.QUERY_KEY],
    queryFn: () => EventMetadataAPI.getEvents(),
    placeholderData: [],
  });

  return {
    data: data,
    isFetching,
    isLoading,
  };
};

export const useGetEventByType = event => {
  const shouldAppendVersion = useFlag(
    FEATURE_FLAGS.APPEND_VERSION_ON_GET_EVENT
  );

  const { data, isFetching } = useQuery({
    queryKey: [REGISTRY.EVENTS.QUERY_KEY, event?.type, event?.version],
    queryFn: () =>
      EventMetadataAPI.getEventByType(
        `${event.type}${
          shouldAppendVersion && event?.version ? `-${event?.version}` : ''
        }`
      ),
    enabled: !!event,
  });

  return {
    data,
    isFetching,
  };
};
