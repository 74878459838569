import { useParams } from 'react-router-dom';
import SubscriptionDetailsPage from './details';
import { BASE_BREADCRUMBS, PAGE_URLS } from '../../routes';
import { useGetSubscription } from '../queries';

const EditSubscriptionPage = () => {
  const { id } = useParams();

  const {
    data: subscription,
    isFetching,
    refetch: refetchSubscription,
  } = useGetSubscription(id);

  // setTimeout can be removed once COP-388 is fixed
  setTimeout(() => {
    if (!subscription) return;

    window.updateBreadcrumb([
      ...BASE_BREADCRUMBS,
      {
        label: subscription.name,
        url: PAGE_URLS.SUBSCRIPTIONS.EDIT(subscription.id),
        disabled: true,
      },
    ]);
  }, 0);

  return (
    <SubscriptionDetailsPage
      subscription={subscription}
      isFetching={isFetching}
      refetchSubscriptionHandler={refetchSubscription}
    />
  );
};

export default EditSubscriptionPage;
