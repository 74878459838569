import { useEffect, useState } from 'react';
import {
  Box,
  DateFilter,
  DateFilterOption,
  DateFilterValue,
  DateType,
  Filter,
  Link,
} from 'ds4-beta';
import { isNil } from 'lodash';
import { DateFilterResetAllContainer } from './styles';
import { DICTIONARY } from '../../../dictionary';
import { DELIVERY_LOGS_REGISTRY } from '../../../delivery-logs/registry';
import { getDateFilterBoundaryDates } from '../../../lib/utils';
import useGetLogRetentionInDays from '../../hooks/useGetLogRetentionInDays';
import { ExtendedDateType } from '../../../delivery-logs/types';

const {
  GENERAL: {
    BUTTONS: { RESET_ALL },
  },
  DATE_FILTER_LABELS: { TODAY, CUSTOM_DATE, getLastXDaysString },
} = DICTIONARY;
const {
  DATE_TIME_PICKERS: { RADIO_OPTIONS },
} = DELIVERY_LOGS_REGISTRY;

const GenericDateFilter = ({
  dateValue,
  dataTestid,
  label,
  onFilterChange,
  onFilterReset,
}: {
  dateValue: DateFilterValue | null;
  dataTestid: string;
  label: string;
  onFilterChange: (dateOption: any) => void;
  onFilterReset: () => void;
}) => {
  const [dateFilterOptions, setDateFilterOptions] = useState<
    DateFilterOption[]
  >([]);

  const [showFilterCount, setShowFilterCount] = useState<boolean>(false);

  const logRetentionInDays = useGetLogRetentionInDays();
  const { today, xDaysAgoDate } =
    getDateFilterBoundaryDates(logRetentionInDays);

  useEffect(() => {
    if (
      isNil(dateValue) ||
      isNil(dateValue?.dateRange) ||
      isNil(dateValue?.dateRange?.from) ||
      isNil(dateValue?.dateRange?.to) ||
      dateValue?.value === ExtendedDateType.DefaultDate
    ) {
      setShowFilterCount(false);
      return;
    }
    setShowFilterCount(true);
  }, [dateValue]);

  useEffect(() => {
    setDateFilterOptions([
      {
        id: RADIO_OPTIONS.TODAY,
        dataTestid: RADIO_OPTIONS.TODAY,
        label: TODAY,
        value: DateType.Today,
        type: 'Radio',
      },
      {
        id: RADIO_OPTIONS.CUSTOM_DATE,
        dataTestid: RADIO_OPTIONS.CUSTOM_DATE,
        label: CUSTOM_DATE,
        value: DateType.CustomDates,
        type: 'DateRangePicker',
        dateRangeProps: {
          minDate: xDaysAgoDate,
          maxDate: today,
        },
      },
    ]);
  }, []);

  const renderDateFilter = () => {
    const shouldShowResetAllCTA =
      !isNil(dateValue) &&
      !isNil(dateValue.value) &&
      dateValue.value.length > 0;
    return (
      <Box padding={[3]}>
        <DateFilter
          defaultValue={dateValue}
          name={dataTestid}
          onChange={onFilterChange}
          options={dateFilterOptions}
        />
        <Box padding={{ top: 3 }}>
          {shouldShowResetAllCTA && (
            <DateFilterResetAllContainer>
              <Link
                label={RESET_ALL}
                onClick={onFilterReset}
                mode='standalone'
                variant='primary'
              />
            </DateFilterResetAllContainer>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Filter
      {...(showFilterCount && {
        filterCount: '1',
      })}
      dataTestid={dataTestid}
      variant='primary'
      label={label}
      renderResults={renderDateFilter()}
    />
  );
};

export default GenericDateFilter;
