import { useState } from 'react';
import { EMPTY_TABLE_SORT_STATES } from '../constants';
import { SORT_TYPE } from '../../constants';
import { DELIVERY_LOGS_DICTIONARY } from '../dictionary';

const {
  COLUMN_NAMES: {
    STATUS_CODE,
    EVENT_DATE,
    SUBSCRIPTION_NAME,
    EVENT_TYPE,
    ENDPOINT,
    LOG_TYPE,
  },
} = DELIVERY_LOGS_DICTIONARY;

const useManageSortOptions = () => {
  const [sortOptions, setSortOptions] = useState(EMPTY_TABLE_SORT_STATES);

  const handleSort = (columnName: string, sortOrder: SORT_TYPE) => {
    // Reset Sort Options
    const newSortOptions = { ...EMPTY_TABLE_SORT_STATES };

    // Then update current selected sort
    switch (columnName) {
      case STATUS_CODE:
        newSortOptions.STATUS_CODE = sortOrder;
        break;
      case EVENT_DATE:
        newSortOptions.EVENT_DATE = sortOrder;
        break;
      case SUBSCRIPTION_NAME:
        newSortOptions.SUBSCRIPTION_NAME = sortOrder;
        break;
      case ENDPOINT:
        newSortOptions.ENDPOINT = sortOrder;
        break;
      case LOG_TYPE:
        newSortOptions.LOG_TYPE = sortOrder;
        break;
      case EVENT_TYPE:
        newSortOptions.EVENT_TYPE = sortOrder;
        break;
      default:
        return; // Do not update if invalid sort
    }
    setSortOptions(newSortOptions);
  };

  return { sortOptions, handleSort };
};

export default useManageSortOptions;
