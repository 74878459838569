import { theme } from 'ds4-beta';
import styled from 'styled-components';
import { REGISTRY } from '../../../registry';

export const SnippetContainer = styled.div<{
  status: boolean;
  stretch: boolean;
}>`
  ${theme.typography.code};
  border-radius: 0 0 ${theme.space[1]} ${theme.space[1]};
  color: ${theme.color.grey[0]};
  ${({ stretch }) => (!stretch ? 'max-height: 300px;' : '')}
  overflow-x: auto;
  overflow-y: auto;
  padding: ${theme.space[3]};
  background-color: ${theme.color.grey[1000]};

  pre {
    ${theme.typography.code};
  }
`;

export const StyledStatusBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space[4]};
  background-color: ${theme.color.grey[900]};
  border-top-left-radius: ${theme.space[1]};
  border-top-right-radius: ${theme.space[1]};

  #${REGISTRY.COMMON.SNIPPET.COPY_BTN}:hover {
    svg > g > path {
      stroke: ${theme.color.grey[1000]};
    }
    defs > clipPath > rect {
      fill: ${theme.color.grey[1000]};
    }
  }
`;
