import { useState, useEffect } from 'react';
import { Box, useToast } from 'ds4-beta';
import {
  EVENT_ADMIN_ROLE_PERMISSIONS,
  FAILED_STATUS_CODES_OPTIONS,
  PAGINATION_DETAILS,
  SORT_TYPE,
  SUBSCRIPTION_NAMES_LIMIT,
  SUCCESS_STATUS_CODES_OPTIONS,
} from '../../constants';
import { PageType } from '../../common/types';
import { HomePageLayout } from '../../common/layouts';
import { useGetEventLogs, useResendEvents } from '../queries';
import { useGetEventTypes } from '../../common/queries';
import { useGetSubscriptions } from '../../subscriptions/queries';
import {
  DeliveryLogsDrawer,
  DeliveryLogsTable,
  ResendEventsModal,
  DeliveryLogsFilterSection,
} from '../organisms';
import { Searchbar } from '../molecules';
import { rbac } from '@teamfabric/copilot-utilities';
import { EMPTY_TABLE_FILTER_STATES } from '../constants';
import useGetFilterData from '../hooks/useGetFilterData';
import useSetEmptyLogsTableProps from '../hooks/useSetEmptyLogsTableProps';
import { DELIVERY_LOGS_REGISTRY } from '../registry';
import { ResendEventsRequest } from '../types';
import { DICTIONARY } from '../../dictionary';
import { isEmpty, isEqual } from 'lodash';
import useManageFilterSelections from '../hooks/useManageFilterSelections';
import { SelectedFilterTags } from '../../common/molecules';
import useManageSortOptions from '../hooks/useManageSortOptions';
import useManageSideDrawer from '../hooks/useManageSideDrawer';
import useGetLogRetentionInDays from '../../common/hooks/useGetLogRetentionInDays';
import { useSearchParams } from 'react-router-dom';

const { TOAST_MESSAGES } = DICTIONARY.DELIVERY_LOGS;
const { TOAST_TEST_IDS } = DELIVERY_LOGS_REGISTRY;

const DeliveryLogsHome = (_: PageType) => {
  const showToast = useToast();
  const [totalRecords, setTotalRecords] = useState(0);
  const [eventLogsOffset, setEventLogsOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [eventsToResend, setEventsToResend] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const { hasAccess: hasAdminAccess } = rbac.checkPermission({
    allowedPermissions: EVENT_ADMIN_ROLE_PERMISSIONS,
  });

  const { sortOptions, handleSort } = useManageSortOptions();

  const onPageChange = (newPageNumber: number) => {
    setActivePage(newPageNumber);
    setEventLogsOffset((newPageNumber - 1) * PAGINATION_DETAILS.PER_PAGE);
  };

  const { save: resendEvents } = useResendEvents();

  const logRetentionInDays = useGetLogRetentionInDays();

  const { data: eventsData, isFetching: isFetchingEventsData } =
    useGetEventTypes();

  const { data: subscriptionsData, isFetching: isFetchingSubscriptionsData } =
    useGetSubscriptions({ limit: SUBSCRIPTION_NAMES_LIMIT });

  const resendEventsHandler = (input: ResendEventsRequest[]) => {
    setEventsToResend(input);
  };

  const resendSpecifiedEvents = async () => {
    try {
      await resendEvents(eventsToResend);
      showToast({
        label:
          eventsToResend.length > 1
            ? TOAST_MESSAGES.getQueuedToResend(eventsToResend.length)
            : TOAST_MESSAGES.QUEUED_TO_RESEND,
        variant: 'default',
        isDismissable: false,
        id: TOAST_TEST_IDS.RESEND_SUCCESS,
        actionButton: {
          text: TOAST_MESSAGES.REFRESH_PAGE,
          onClick: () => {
            refetchDeliveryLogsData();
          },
        },
      });
    } catch (e) {
      console.error('Error resending events:', e);
      showToast({
        label: TOAST_MESSAGES.FAILED_TO_RESEND,
        variant: 'error',
        id: TOAST_TEST_IDS.RESEND_FAILURE,
      });
    }
    setEventsToResend([]);
  };

  const [
    eventNestedFilterOptions,
    statusFilterOptions,
    subscriptionFilterOptions,
  ] = useGetFilterData({
    eventsData,
    isFetchingEventsData,
    subscriptionsData,
    isFetchingSubscriptionsData,
  });

  const {
    dateValue,
    filterSelections,
    filterCheckboxOptions,
    tagSelections,
    handleClearFilters,
    handleFilterOptionSelect,
    handleDateChange,
  } = useManageFilterSelections(
    eventNestedFilterOptions,
    statusFilterOptions,
    subscriptionFilterOptions,
    eventsData
  );

  useEffect(() => {
    // reset the active page and offset if any of the filtering/sorting/search options change
    setActivePage(1);
    setEventLogsOffset(0);

    // reset total records
    setTotalRecords(eventLogsQuery?.count);
  }, [filterSelections, sortOptions, searchTerm]);

  const {
    data: eventLogsData,
    query: eventLogsQuery,
    isFetching: isFetchingEventLogs,
    isError: isFetchingEventLogsError,
    error: eventLogsFetchError,
    refetch: refetchDeliveryLogsData,
  } = useGetEventLogs({
    offset: eventLogsOffset,
    sortOptions,
    searchTerm,
    filterSelections,
    logRetentionInDays,
  });

  const { selectedLogForSideDrawer, handleOpenDrawer, handleCloseDrawer } =
    useManageSideDrawer({ eventLogsData });

  const { emptyTableProps } = useSetEmptyLogsTableProps({
    isFetchingEventLogs,
    eventLogsData,
    searchTerm,
    sortOptions,
    filterSelections,
    isFetchingEventLogsError,
    setTotalRecords,
    eventLogsQuery,
    eventLogsFetchError,
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const status = searchParams.get('status');

    // Should wait until status options are loaded as well (otherwise it will break the filter)
    if (!status || statusFilterOptions.length == 0) { return; }

    // Status filter
    handleFilterOptionSelect({
      filterName: DICTIONARY.FILTER_NAMES.STATUS_CODE, 
      selectedOptions: status === DICTIONARY.DELIVERY_LOGS.LOGS_DELIVERY_STATUS.SUCCESS 
        ? SUCCESS_STATUS_CODES_OPTIONS 
        : FAILED_STATUS_CODES_OPTIONS
    });
  }, [searchParams, statusFilterOptions])

  return (
    <HomePageLayout
      shouldShowPrimaryCTA={!isFetchingEventLogs && !isEmpty(eventLogsData)}
    >
      <Box
        padding={{
          bottom: 3,
        }}
      >
        <Searchbar handleSearch={setSearchTerm} />
      </Box>
      <Box
        flex={{
          justifyContent: 'left',
        }}
        gap={1}
      >
        <DeliveryLogsFilterSection
          dateValue={dateValue}
          filterCheckboxOptions={filterCheckboxOptions}
          handleFilterOptionSelect={handleFilterOptionSelect}
          handleDateChange={handleDateChange}
          showResetFiltersCTA={
            !isEqual(filterSelections, EMPTY_TABLE_FILTER_STATES)
          }
          handleClearFilters={handleClearFilters}
          filterSelections={filterSelections}
        />
      </Box>
      {!isEmpty(tagSelections) && (
        <Box
          padding={{
            top: 3,
          }}
        >
          <SelectedFilterTags tagList={tagSelections} />
        </Box>
      )}
      <Box
        padding={{
          top: 4,
        }}
      >
        <DeliveryLogsTable
          eventLogsOffset={eventLogsOffset}
          onPageChange={onPageChange}
          isFetching={isFetchingEventLogs}
          data={eventLogsData}
          hasAdminAccess={hasAdminAccess}
          activePage={activePage}
          totalRecords={totalRecords}
          emptyTableProps={emptyTableProps}
          handleOpenDrawer={handleOpenDrawer}
          resendEventsHandler={resendEventsHandler}
          onAscendingSort={columnName =>
            handleSort(columnName, SORT_TYPE.ASCENDING)
          }
          onDescendingSort={columnName =>
            handleSort(columnName, SORT_TYPE.DESCENDING)
          }
        />
      </Box>
      {selectedLogForSideDrawer && (
        <DeliveryLogsDrawer
          hasAdminAccess={hasAdminAccess}
          selectedLogData={selectedLogForSideDrawer}
          handleCloseDrawer={handleCloseDrawer}
          resendEventHandler={resendEventsHandler}
        />
      )}
      {eventsToResend && (
        <ResendEventsModal
          numOfEvents={eventsToResend.length}
          onConfirm={resendSpecifiedEvents}
          onModalClose={() => setEventsToResend([])}
        />
      )}
    </HomePageLayout>
  );
};

export default DeliveryLogsHome;
