import styled from "styled-components";
import { split } from 'lodash';
import { theme } from "ds4-beta";

export const LogTimeWrapper = styled.div`
  div.log-date {
    ${theme.typography.body1.regular};
  }
  div.log-time {
    ${theme.typography.body2.regular};
    color: ${theme.color.grey[700]};
  }
`;

const getDateString = (date: Date) => (
  date.toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
);

const getTimeString = (date: Date) => (
  date.toLocaleTimeString(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  })
);

const LogDateTime = ({ date }: { date: string }) => {
  // Temporary fix until definitive date handling in BE is taken care of (does not collide with solution)
  // The standard date format for fabric should be Z, this change converts non-Z date formats to Z (to get the localized date time).
  if (!date?.endsWith('Z')) {
    date = split(date, '.')[0] + "Z";
  }

  const jsDate = new Date(date);

  const datePart = getDateString(jsDate);
  const timePart = getTimeString(jsDate);
  return (
    <LogTimeWrapper>
      <div className='log-date'>{datePart}</div>
      <div className='log-time'>{timePart}</div>
    </LogTimeWrapper>
  );
}

export default LogDateTime;