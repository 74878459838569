import { Badge } from 'ds4-beta';
import { useEffect, useState } from 'react';

const StatusBadge = ({
  status = ''
}: {
  status: string;
}) => {
  const [statusType, setStatusType] = useState<'success' | 'error' | 'alert'>('alert');
  useEffect(() => {
    if (status.startsWith('4') || status.startsWith('5')) {
      setStatusType('error');
    } else if (status.startsWith('2')) {
      setStatusType('success');
    } else {
      setStatusType('alert');
    }
  }, [status]);
  return (
    <Badge
      label={status}
      asset='bullet'
      status={statusType}
      variant='secondary' />
  );
};

export default StatusBadge;