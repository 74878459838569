import { useState, useEffect } from 'react';
import { Box, Tab, TabItem } from 'ds4-beta';
import { useNavigate } from 'react-router-dom';
import { indexOf } from 'lodash';
import { HomeHeader } from '../atoms';
import { DICTIONARY } from '../../dictionary';
import {
  BASE_BREADCRUMBS,
  PAGE_URLS,
  getCurrentRelativePath,
} from '../../routes';
import { REGISTRY } from '../../registry';
import { LayoutType } from '../types';

const HomePageLayout = ({
  children,
  shouldShowPrimaryCTA,
}: LayoutType & { shouldShowPrimaryCTA: boolean }) => {
  setTimeout(() => {
    window.updateBreadcrumb([...BASE_BREADCRUMBS]);
  }, 0);

  const navigate = useNavigate();

  const TAB_NAVIGATION_URLS = [
    PAGE_URLS.SUBSCRIPTIONS.HOME,
    PAGE_URLS.LOGS.HOME(),
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    setActiveTabIndex(indexOf(TAB_NAVIGATION_URLS, getCurrentRelativePath()));
  }, []);

  const handleTabChange = (index: number) => {
    navigate(TAB_NAVIGATION_URLS[index]);
  };

  return (
    <Box padding={[6]}>
      <HomeHeader
        shouldShowPrimaryCTA={shouldShowPrimaryCTA}
        activeTabIndex={activeTabIndex}
      />
      <Box padding={[2]} />
      <Tab
        customActiveIndex={activeTabIndex}
        tabChangeHandler={handleTabChange}
      >
        <TabItem
          label={DICTIONARY.TAB_OPTIONS.SUBSCRIPTIONS}
          dataTestid={REGISTRY.COMMON.NAVIGATION.SUBSCRIPTION}
        >
          <></>
        </TabItem>
        <TabItem
          label={DICTIONARY.TAB_OPTIONS.LOGS}
          dataTestid={REGISTRY.COMMON.NAVIGATION.LOGS}
        >
          <></>
        </TabItem>
      </Tab>
      <Box padding={[3]} />
      {children}
    </Box>
  );
};

export default HomePageLayout;
