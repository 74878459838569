import { useEffect, useState } from 'react';
import { PAGE_URLS } from '../routes';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'ds4-beta';
import { REGISTRY } from '../registry';
import { DICTIONARY } from '../dictionary';
import { ConfirmActionModal } from './molecules';
import { ICONS } from '../constants';
import { useDeleteSubscription } from './queries';

export type ConfirmModalHookType = {
  onConfirm: () => void;
};

export const useConfirmDeleteSubscription = (props?: ConfirmModalHookType) => {
  const navigate = useNavigate();
  const showToast = useToast();

  const [isDeleting, setIsDeleting] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);

  const { unsubscribe, isDeleteSuccess, isDeleteError, deleteError } =
    useDeleteSubscription();

  useEffect(() => {
    if (!isDeleteSuccess) return;

    setIsDeleting(false);
    navigate(PAGE_URLS.SUBSCRIPTIONS.HOME);

    showToast({
      id: REGISTRY.SUBSCRIPTIONS.FORM.AFTER_DELETE.DELETED,
      label: DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.AFTER_DELETE.DELETED(
        subscriptionToDelete?.name
      ),
    });

    setSubscriptionToDelete(null);
    props?.onConfirm && props.onConfirm();
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (!isDeleteError || !deleteError) return;

    showToast({
      id: REGISTRY.SUBSCRIPTIONS.FORM.AFTER_DELETE.ERROR,
      label: deleteError.message,
      variant: 'error',
    });
  }, [isDeleteError, deleteError]);

  const handleDeleteAction = () => {
    unsubscribe(subscriptionToDelete.id);
  };

  const handleCancelDelete = () => {
    setSubscriptionToDelete(null);
    setIsDeleting(false);
  };

  const showConfirmDeleteModal = subscription => {
    setSubscriptionToDelete(subscription);
    setIsDeleting(true);
  };

  const renderConfirmDeleteModal = () => {
    return (
      <ConfirmActionModal
        isVisible={isDeleting}
        onConfirm={handleDeleteAction}
        onCancel={handleCancelDelete}
        header={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DELETE_MODAL.HEADER}
        description={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DELETE_MODAL.SUBTITLE}
        icon={ICONS.TRASH}
        confirmBtnLabel={
          DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DELETE_MODAL.CANCEL_BTN
        }
        cancelBtnLabel={
          DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DELETE_MODAL.CANCEL
        }
        dataTestId={REGISTRY.SUBSCRIPTIONS.FORM.DELETE_MODAL}
      />
    );
  };

  return {
    showConfirmDeleteModal,
    renderConfirmDeleteModal,
  };
};

// Although this is only used in a single place, moved here for consistency
export const useConfirmCancelling = (props?: ConfirmModalHookType) => {
  const showToast = useToast();
  const navigate = useNavigate();

  const [isCancelling, setIsCancelling] = useState(false);

  const handleCancelAction = (shouldShowToast = false) => {
    setIsCancelling(false);

    navigate(PAGE_URLS.SUBSCRIPTIONS.HOME);

    // Only show toast if form changed
    if (shouldShowToast) {
      showToast({
        id: REGISTRY.SUBSCRIPTIONS.FORM.AFTER_CANCEL,
        label: DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.CANCEL_MODAL.AFTER_CANCEL,
      });
    }

    props?.onConfirm && props?.onConfirm();
  };

  const showConfirmCancelModal = () => {
    setIsCancelling(true);
  };

  const renderConfirmCancelModal = () => {
    return (
      <ConfirmActionModal
        isVisible={isCancelling}
        onConfirm={() => handleCancelAction(true)}
        onCancel={() => setIsCancelling(false)}
        header={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.CANCEL_MODAL.HEADER}
        description={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.CANCEL_MODAL.SUBTITLE}
        confirmBtnLabel={
          DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.CANCEL_MODAL.CANCEL_BTN
        }
        cancelBtnLabel={
          DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.CANCEL_MODAL.KEEP_EDITING
        }
        dataTestId={REGISTRY.SUBSCRIPTIONS.FORM.CANCEL_MODAL}
      />
    );
  };

  return {
    cancel: handleCancelAction,
    showConfirmCancelModal,
    renderConfirmCancelModal,
  };
};
