import { useNavigate, useParams } from 'react-router-dom';
import { useGetSubscription, useTestSubscription } from '../queries';
import { Box, Button, PageHeader, SkeletonCode, theme } from 'ds4-beta';
import { DICTIONARY } from '../../dictionary';
import { BASE_BREADCRUMBS, PAGE_URLS } from '../../routes';
import { REGISTRY } from '../../registry';
import { useState } from 'react';
import {
  FormSection,
  FormSectionSubtitle,
  FormSectionTitle,
  VerticalSpace,
} from '../../common/atoms';
import TestResponseSnippet from '../molecules/snippet';
import { SelectTestEvent } from '../molecules';

const TestSubscriptionPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: subscription, isFetching } = useGetSubscription(id);

  const [testStarted, setTestStarted] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { test, duration, isTesting, testError, testResponse } =
    useTestSubscription();

  const handleCancel = () => {
    navigate(PAGE_URLS.SUBSCRIPTIONS.HOME);
  };

  // setTimeout can be removed once COP-388 is fixed
  setTimeout(() => {
    if (!subscription) return;

    window.updateBreadcrumb([
      ...BASE_BREADCRUMBS,
      {
        label: subscription.name,
        url: PAGE_URLS.SUBSCRIPTIONS.EDIT(subscription.id),
      },
      {
        label: DICTIONARY.SUBSCRIPTIONS.TEST.TITLE,
        url: PAGE_URLS.SUBSCRIPTIONS.TEST(subscription.id),
        disabled: true,
      },
    ]);
  }, 0);

  const handleSelectEvent = selected => {
    setSelectedEvent(selected);
  };

  const handleTestSubscription = () => {
    setTestStarted(true);
    test({
      subscriptionId: subscription.id,
      ...selectedEvent,
    });
  };

  return (
    <>
      {subscription && !isFetching && (
        <Box padding={[6]}>
          <PageHeader
            h1Text={DICTIONARY.SUBSCRIPTIONS.TEST.TITLE}
            h2Text={DICTIONARY.SUBSCRIPTIONS.TEST.DESCRIPTION}
            dataTestid={REGISTRY.SUBSCRIPTIONS.TEST.HEADER}
            primaryButtons={[
              {
                onClick: handleCancel,
                text: DICTIONARY.GENERAL.BUTTONS.CANCEL,
                variant: 'secondary',
                dataTestid: REGISTRY.SUBSCRIPTIONS.FORM.CANCEL_BTN,
              },
            ]}
          />
          <FormSection>
            <FormSectionTitle
              label={DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.TITLE}
            />
            <VerticalSpace />
            <div style={{ ...theme.typography.fieldLabel }}>
              {DICTIONARY.SUBSCRIPTIONS.FORM_LABELS.DETAILS.ENDPOINT}
            </div>
            <VerticalSpace size={1} />
            <div style={{ ...theme.typography.body1.regular }}>
              {subscription.targetUrl}
            </div>
            <VerticalSpace />
            <SelectTestEvent
              events={subscription.events}
              onSelect={handleSelectEvent}
            />
            <VerticalSpace />
            <Button
              variant='primary'
              isDisabled={!selectedEvent}
              text={DICTIONARY.SUBSCRIPTIONS.TEST.RUN_BTN}
              onClick={handleTestSubscription}
              dataTestid={REGISTRY.SUBSCRIPTIONS.TEST.RUN_BTN}
            />
          </FormSection>
          <VerticalSpace />
          {testStarted && isTesting && <SkeletonCode width='100%' />}
          {testStarted && !isTesting && (
            <FormSection>
              <FormSectionTitle
                label={DICTIONARY.SUBSCRIPTIONS.TEST.RESPONSE}
              />
              <FormSectionSubtitle
                label={`Executed in ${(duration / 1000).toFixed(
                  2
                )} seconds. Event ${testError ? 'failed.' : 'successful.'}`}
              />
              <TestResponseSnippet response={testResponse} error={testError} />
            </FormSection>
          )}
        </Box>
      )}
    </>
  );
};

export default TestSubscriptionPage;
