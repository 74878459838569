import { DateFilterValue, DateType } from 'ds4-beta';
import { isNil } from 'lodash';

export const transformStartEndTime = (dateFilterValue: DateFilterValue) => {
  // We need to process the event in this way so that if the start and end dates are the same,
  // we are still looking between 12:00am until 11:59pm
  // If we do not do this transformation, the start and end times will both equal 12:00am and the backend wil return 0 results

  const { value, dateRange } = dateFilterValue;
  if (
    value === DateType.CustomDates &&
    !isNil(dateRange) &&
    !isNil(dateRange.to) &&
    !isNil(dateRange.from)
  ) {
    let modifiedFrom = new Date(dateRange.from.valueOf());
    modifiedFrom.setHours(0, 0, 0, 0);
    let modifiedTo = new Date(dateRange.to.valueOf());
    modifiedTo.setHours(23, 59, 59, 59);

    return {
      from: modifiedFrom,
      to: modifiedTo,
    };
  }

  return {
    from: dateRange?.from || undefined,
    to: dateRange?.to || undefined,
  };
};
