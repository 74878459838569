export const DELIVERY_LOGS_REGISTRY = {
  QUERY_KEY: 'event-logs',
  FILTER_DATA_TEST_IDS: {
    DATE: 'date-filter-test-id',
    EVENT: 'event-filter-test-id',
    STATUS_CODE: 'status-code-filter-test-id',
    SUBSCRIPTION_NAME: 'subscription-name-filter-test-id',
  },
  TABLE_CTA_DATA_TEST_IDS: {
    RESEND: 'resend-cta-test-id',
    SHOW_DETAILS: 'show-details-test-id',
    RESEND_BULK: 'resend-events-bulk-test-id',
  },
  TOAST_TEST_IDS: {
    RESEND_SUCCESS: 'resend-events-success-toast',
    RESEND_FAILURE: 'resend-events-failure-toast',
  },
  SEARCH: {
    SEARCH_ELEMENT_TEST_ID: 'delivery-logs-search-test-id',
  },
  DATE_TIME_PICKERS: {
    DATE_PICKER: 'date-picker-test-id',
    TIME_PICKER: 'time-picker-test-id',
    RADIO_OPTIONS: {
      TODAY: 'today-radio-option',
      LAST_X_DAYS: 'last-x-days-radio-option',
      CUSTOM_DATE: 'custom-date-radio-option',
    },
  },
  REPLAY_EVENTS_MODAL_TEST_IDS: {
    MODAL: 'replay-events-modal-test-id',
    SUBSCRIPTION_NAME_DROPDOWN: 'subscription-name-dropdown-test-id',
    SUCCESS: 'replay-events-success-toast',
    FAILURE: 'replay-events-failure-toast',
  },
};
