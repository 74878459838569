import { SubscriptionsResponseData } from './../delivery-logs/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SubscriptionsAPI } from './requests';
import { REGISTRY } from '../registry';
import { AxiosError, AxiosResponse } from 'axios';
import { PAGINATION_DETAILS } from '../constants';
import { SubscriptionFilterOptions, SortOptions } from './types';
import { getSortOrderString } from '../lib/utils';
import { EventMetadataResponse } from '../common/types';

export const useGetSubscriptions = ({
  page = 1,
  filters = null,
  sortOptions = null,
  limit = null,
  searchTerm = null,
}: {
  page?: number;
  filters?: SubscriptionFilterOptions;
  sortOptions?: SortOptions;
  limit?: number;
  searchTerm?: string;
}) => {
  const sort = getSortOrderString(sortOptions?.key, sortOptions?.direction);
  const eventTypes = filters?.type
    ? filters.type.map(selection => selection.type)
    : [];

  const { data, refetch, isFetching, isLoading } = useQuery({
    queryKey: [
      REGISTRY.SUBSCRIPTIONS.QUERY_KEY,
      page,
      sortOptions?.key,
      sortOptions?.direction,
      searchTerm,
      eventTypes,
    ],
    queryFn: () =>
      SubscriptionsAPI.getSubscriptions({
        page,
        searchTerm,
        eventTypes,
        sort,
        limit,
      }),
    keepPreviousData: true,
    enabled: true,
  });

  // no need to await but prefetch data for the next page (if not on last page)
  const queryClient = useQueryClient();
  if (data?.query.count - data?.query.offset > PAGINATION_DETAILS.PER_PAGE) {
    queryClient.prefetchQuery({
      queryKey: [
        REGISTRY.SUBSCRIPTIONS.QUERY_KEY,
        page + 1,
        sortOptions?.key,
        sortOptions?.direction,
      ],
      queryFn: () =>
        SubscriptionsAPI.getSubscriptions({ page: page + 1, sort }),
    });
  }

  return {
    data: data?.data,
    count: data?.query.count,
    refetch,
    isFetching,
    isLoading,
  };
};

export const useGetSubscription = (id: string) => {
  if (!id) {
    return { data: null, isFetching: false };
  }

  const { data, refetch, isFetching } = useQuery({
    queryKey: [REGISTRY.SUBSCRIPTIONS.QUERY_KEY, id],
    queryFn: () => SubscriptionsAPI.getSubscription(id),
    enabled: true,
  });

  return { data, refetch, isFetching };
};

export const useSaveSubscription = () => {
  const queryClient = useQueryClient();

  const { mutate, data, error, isLoading, isSuccess, isError } = useMutation({
    mutationFn: saveSubscription,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: [REGISTRY.SUBSCRIPTIONS.QUERY_KEY, id],
        refetchInactive: true,
      });
    },
  });

  let parsedError;
  if (isError && error) {
    parsedError = (error as AxiosError).response.data;
  }

  return {
    save: mutate,
    data,
    saveError: parsedError,
    isSaving: isLoading,
    isSaveSuccess: isSuccess,
    isSaveError: isError,
  };
};

export const useDeleteSubscription = () => {
  const queryClient = useQueryClient();

  const { mutate, error, isLoading, isSuccess, isError } = useMutation({
    mutationFn: SubscriptionsAPI.deleteSubscription,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: [REGISTRY.SUBSCRIPTIONS.QUERY_KEYS.SUBSCRIPTIONS, id],
      });
    },
  });

  let parsedError;
  if (isError && error) {
    parsedError = (error as AxiosError).response.data;
  }

  // Using unsubscribe verb (here only) as delete is a protected keyword
  return {
    unsubscribe: mutate,
    deleteError: parsedError,
    isDeleting: isLoading,
    isDeleteSuccess: isSuccess,
    isDeleteError: isError,
  };
};

export const useTestSubscription = () => {
  const { mutate, data, error, isLoading, isSuccess, isError } = useMutation<
    AxiosResponse,
    AxiosError,
    any
  >({
    mutationFn: SubscriptionsAPI.testSubscription,
  });

  return {
    test: mutate,
    duration: data?.headers.duration ?? error?.response.headers.duration,
    testResponse: data?.data,
    testError: error?.response,
    isTesting: isLoading,
    isTestSuccess: isSuccess,
    isTestError: isError,
  };
};

const saveSubscription = async subscription => {
  // Remove description from payload to decrease size of payload
  subscription.events?.forEach((event: EventMetadataResponse) => {
    delete(event.description);
    delete(event.product);
    delete(event.productAlias);
    delete(event.service);
  });

  // Remove auth details completely (INT-752) when api key value was not provided
  if (!subscription.authorizationDetails?.config?.apiKeyValue) {
    delete(subscription.authorizationDetails);  
  } else {
    // For now, explicitly add API_KEY type (with INT-752) change, we stopped receiving authDetails, and thus field is overwritten
    // If they are being provided, include type which is required by the API
    subscription.authorizationDetails = { ...subscription.authorizationDetails, type: REGISTRY.SUBSCRIPTIONS.FORM.AUTH.TYPE.API_KEY }; 
  }

  if (subscription.id) {
    return SubscriptionsAPI.updateSubscription(subscription);
  }

  return SubscriptionsAPI.saveSubscription(subscription);
};
