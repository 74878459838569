import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { ReactQueryDevtools } from 'react-query/devtools';
import SubscriptionsHome from './subscriptions/pages';
import DeliveryLogsHome from './delivery-logs/pages';
import { PageNotFound, PageNotAccessible } from './common/pages';
import { FEATURE_FLAGS } from './registry';
import NewSubscriptionPage from './subscriptions/pages/new';
import EditSubscriptionPage from './subscriptions/pages/edit';
import TestSubscriptionPage from './subscriptions/pages/test-subscription';

const BASE_URL_PATH = '/settings/developer-tools/webhooks';

const PAGE_URLS = {
  COPILOT: {
    get HOME() {
      return '/';
    },
  },
  SUBSCRIPTIONS: {
    get HOME() {
      return BASE_URL_PATH + '/subscriptions';
    },
    get NEW() {
      return BASE_URL_PATH + '/subscriptions/new';
    },
    EDIT(id?: string) {
      return BASE_URL_PATH + `/subscriptions/${id ?? ':id'}`;
    },
    TEST(id?: string) {
      return BASE_URL_PATH + `/subscriptions/${id ?? ':id'}/test`;
    },
  },
  LOGS: {
    HOME(status?: string) {
      return BASE_URL_PATH + `/logs${status ? `?status=${status}` : ''}`;
    },
    get DETAILS() {
      return BASE_URL_PATH + '/logs/:id';
    },
  },
  SETTINGS: {
    get HOME() {
      return '/settings';
    },
    get DEVELOPER_TOOLS() {
      return '/settings/developer-tools';
    },
  },
};

const BASE_BREADCRUMBS = [
  {
    label: 'Home',
    url: PAGE_URLS.COPILOT.HOME,
  },
  {
    label: 'Settings',
    url: PAGE_URLS.SETTINGS.HOME,
  },
  {
    label: 'Developer Tools',
    url: PAGE_URLS.SETTINGS.DEVELOPER_TOOLS,
  },
  {
    label: 'Webhooks',
    url: PAGE_URLS.SUBSCRIPTIONS.HOME,
  },
];

const getCurrentRelativePath = () => {
  // Remove trailing "/" (if present) to meet with our path standards
  let browserPath = window.location.pathname;
  browserPath = browserPath.endsWith('/')
    ? browserPath.slice(0, -1)
    : browserPath;
  return browserPath;
};

const Redirect = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, []);

  return <></>;
};

const WebhooksRoutes = () => {
  const { flagsReady } = useFlagsStatus();
  const showDevTools = useFlag(FEATURE_FLAGS.SHOW_DEV_TOOLS);
  const showWebhooksCTA = useFlag(FEATURE_FLAGS.SHOW_WEBHOOKS_CTA);

  if (!flagsReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <main className='app_wrapper' id='app_root'>
        <Routes>
          {showWebhooksCTA && (
            <>
              <Route
                element={<Redirect to={PAGE_URLS.SUBSCRIPTIONS.HOME} />}
                path='/'
              />
              <Route
                element={<Redirect to={PAGE_URLS.SUBSCRIPTIONS.HOME} />}
                path={BASE_URL_PATH}
              />
              <Route
                element={<SubscriptionsHome />}
                path={PAGE_URLS.SUBSCRIPTIONS.HOME}
              />
              <Route
                element={<NewSubscriptionPage />}
                path={PAGE_URLS.SUBSCRIPTIONS.NEW}
              />

              <Route
                element={<EditSubscriptionPage />}
                path={PAGE_URLS.SUBSCRIPTIONS.EDIT()}
              />
              <Route
                element={<TestSubscriptionPage />}
                path={PAGE_URLS.SUBSCRIPTIONS.TEST()}
              />

              <Route
                element={<DeliveryLogsHome />}
                path={PAGE_URLS.LOGS.HOME()}
              />
              <Route element={<PageNotFound />} path='*' />
            </>
          )}
          {!showWebhooksCTA && (
            <Route element={<PageNotAccessible />} path='*' />
          )}
        </Routes>
        {showDevTools && <ReactQueryDevtools initialIsOpen={true} />}
      </main>
    </BrowserRouter>
  );
};

export { WebhooksRoutes, getCurrentRelativePath, PAGE_URLS, BASE_BREADCRUMBS };
