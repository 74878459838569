import { useState, useEffect } from 'react';
import { Box, ButtonProps, Drawer, GridRow, Tag } from 'ds4-beta';
import { DELIVERY_LOGS_DICTIONARY } from '../../dictionary';
import { DrawerCell, StyledSectionTitle } from './styles';
import StatusBadge from '../../molecules/statusBadge';
import { EventLogResponseData, LogDetailsResponseData, ResendEventsRequest } from '../../types';
import { getFormattedDate, prepRawHTML } from './utils';
import DividerLine from '../../atoms/dividerLine';
import { CodeSnippet } from '../../../common/molecules';
import { VerticalSpace } from '../../../common/atoms';
import { useAllowResend } from '../../hooks/useAllowResend';
import { DICTIONARY } from '../../../dictionary';

const { DRAWER_LABELS, TEST_LOG_TYPE_VALUE } = DELIVERY_LOGS_DICTIONARY;

const DeliveryLogsDrawer = ({
  hasAdminAccess,
  selectedLogData,
  handleCloseDrawer,
  resendEventHandler,
}: {
  hasAdminAccess: boolean;
  selectedLogData: EventLogResponseData;
  handleCloseDrawer: () => void;
  resendEventHandler: (input: ResendEventsRequest[]) => void;
}) => {
  const {
    status,
    eventType,
    subscriptionName,
    logs,
    version,
    triggeredAt,
    deliveredAt,
    endpoint,
    data,
    subscriptionId,
    eventId,
  } = selectedLogData;

  const [headerButtons, setHeaderButtons] = useState<ButtonProps[]>([]);
  const [numOfAttempts, setNumOfAttempts] = useState<number>(0)

  const { hasTestDeliveryLog, hasDeletedSubscription } = useAllowResend([selectedLogData])

  useEffect(() => {
    const newHeaderButtons: ButtonProps[] = [
      {
        variant: 'primary',
        text: DRAWER_LABELS.HEADER_BUTTONS.CLOSE,
        onClick: handleCloseDrawer,
      },
    ];

    if (hasAdminAccess && !hasTestDeliveryLog && !hasDeletedSubscription) {
      newHeaderButtons.push({
        variant: 'secondary',
        text: DRAWER_LABELS.HEADER_BUTTONS.RESEND,
        icon: 'Refresh',
        onClick: () => {
          resendEventHandler([
            {
              subscriptionId,
              eventIds: [eventId],
            },
          ]);
        },
      });
    }

    setHeaderButtons(newHeaderButtons);
  }, [hasAdminAccess, hasDeletedSubscription, hasTestDeliveryLog]);

  useEffect(() => {
    setNumOfAttempts(logs.filter(
      (log: LogDetailsResponseData) => log.deliveryStatus !== DICTIONARY.DELIVERY_LOGS.OMS_LOG_DELIVERY_STATUS.PENDING ? log : null).length)
  }, [logs])

  return (
    <Drawer title={DRAWER_LABELS.TITLE} isVisible headerButtons={headerButtons}>
      <DividerLine />
      <GridRow padding={false}>
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.STATUS_CODE}
          customRender={<StatusBadge status={status.toString()} />}
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.EVENT}
          customRender={
            <Tag id={`${eventType}-drawer-tag`} primaryLabel={eventType} />
          }
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.SUBSCRIPTION_NAME}
          staticContent={subscriptionName}
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.ENDPOINT}
          staticContent={endpoint}
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.ATTEMPTS}
          staticContent={numOfAttempts.toString()}
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.EVENT_VERSION}
          staticContent={version}
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.TRIGGERED_AT}
          staticContent={getFormattedDate(triggeredAt)}
        />
        <DrawerCell
          fieldLabel={DRAWER_LABELS.GRID.DELIVERED_AT}
          staticContent={getFormattedDate(deliveredAt)}
        />
      </GridRow>
      <VerticalSpace size={3} />
      <StyledSectionTitle>
        {DRAWER_LABELS.REQUEST_DETAILS}
        <Box margin={{ top: 4 }}>
          <CodeSnippet content={JSON.stringify(data, null, 2)} />
        </Box>
      </StyledSectionTitle>
      <StyledSectionTitle>
        {DRAWER_LABELS.RESPONSE_DETAILS}
        <CodeSnippet
          content={`Status: ${logs[0].status}<br/>Message:<pre>${prepRawHTML(
            logs[0].message
          )}</pre>`}
        />
      </StyledSectionTitle>
    </Drawer>
  );
};

export default DeliveryLogsDrawer;
