import { useMutation, useQuery } from 'react-query';
import { EventLogsAPI } from './requests';
import { EventLogsQueryParams } from './types';
import { DELIVERY_LOGS_REGISTRY } from './registry';
import {
  EMPTY_TABLE_FILTER_STATES,
  EMPTY_TABLE_SORT_STATES,
} from './constants';
import { DEFAULT_LOG_RETENTION_IN_DAYS } from '../constants';

export const useGetEventLogs = ({
  offset = 0,
  sortOptions = EMPTY_TABLE_SORT_STATES,
  searchTerm = '',
  filterSelections = EMPTY_TABLE_FILTER_STATES,
  logRetentionInDays = DEFAULT_LOG_RETENTION_IN_DAYS,
}: EventLogsQueryParams) => {
  const { refetch, data, isFetching, isError, error } = useQuery({
    queryKey: [
      DELIVERY_LOGS_REGISTRY.QUERY_KEY,
      offset,
      sortOptions,
      searchTerm,
      filterSelections,
    ],
    queryFn: () =>
      EventLogsAPI.getEventLogs({
        offset,
        sortOptions,
        searchTerm,
        filterSelections,
        logRetentionInDays,
      }),
    placeholderData: {
      data: [],
      query: {},
    },
  });
  return {
    isError,
    refetch,
    data: data?.data ?? [],
    query: data?.query ?? {},
    isFetching,
    error,
  };
};

export const useResendEvents = () => {
  const { mutateAsync } = useMutation({
    mutationFn: EventLogsAPI.resendEvents,
  });

  return {
    save: mutateAsync,
  };
};

export const useReplayEvents = () => {
  const { mutateAsync, isLoading, isSuccess } = useMutation({
    mutationFn: EventLogsAPI.replayEvents,
  });

  return {
    save: mutateAsync,
    isLoading,
    isSuccess,
  };
};
